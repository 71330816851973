import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Capteur} from "../types/capteur";
import {Organisation} from "../types/organisation";

export const capteurModaleSlice = createSlice({
    name: "capteurModale",
    initialState:null as {capteur:Capteur, organisation:Organisation}|null,
    reducers: {
        setCapteurModale: (state, action:PayloadAction<{capteur:Capteur, organisation:Organisation}|null>) => {
            state = action.payload;
            return state
        },
        unsetCapteurModale: (state, action:PayloadAction<void>) => {
            state = null;
            return state
        }
    }
})

export const {setCapteurModale, unsetCapteurModale} = capteurModaleSlice.actions;