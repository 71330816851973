import {afficherDateTime, DateTZ} from "./GestionDates";
import {Mesure} from "../types/mesure";
import {Cellule} from "../types/cellule";
import {Carte_mesure} from "../components/carte_mesure/carte_mesure";
import {BarChart, ChartReferenceLineProps, LineChart} from "@mantine/charts";
import {Paper, Text} from "@mantine/core";
import React from "react";
import {Condition} from "../types/condition";
import {Tableau_helper, typeDonnee} from "../components/tableau/Tableau_helper";
import {Statut_cellule} from "../types/statut_cellule";

export type excursion = {
    cellule:string
    anomalie: string,
    debut: string,
    fin: string | null,
    duree: number
    min: number,
    max: number,
    mesures: number[],

}

export class Graphiques {

    cellule: Cellule

    nbe_mesures: number | null
    mesure_min: Mesure | null
    mesure_max: Mesure | null
    mesure_moy: number
    mesure_med: Mesure | null
    mesure_ecart_type: number | null
    mesures: Mesure[] | null
    mesures_triees: Mesure[] | null
    excursions: excursion[]
    anomalie_min: string | undefined
    anomalie_max: string | undefined
    anomalie_CS: Condition | undefined
    cellules_ids: number[]
    cellules_affiche: { id: string, nom: string }[]

    constructor(cellule: Cellule, mesures_data: Mesure[], _cellules_affiche?: { id: string, nom: string }[]) {
        this.cellule = cellule;
        this.nbe_mesures = null;
        this.mesure_min = null;
        this.mesure_max = null;
        this.mesure_moy = 0;
        this.mesure_med = null;
        this.mesure_ecart_type = null;
        this.mesures_triees = null;
        this.mesures = null;
        this.excursions = [];
        this.cellules_ids = [];
        this.cellules_affiche = _cellules_affiche ?? [];

        this.anomalie_min = this.cellule.conditions.find(c => c.slug === 0)?.valeur_a_comparer;
        this.anomalie_max = this.cellule.conditions.find(c => c.slug === 1)?.valeur_a_comparer;

        this.anomalie_CS = this.cellule.conditions.find(c => [2, 3].includes(c.slug));


        this.mesures = JSON.parse(JSON.stringify(mesures_data));
        this.mesures = this.mesures?.filter(m => !isNaN(Number(m.mesure))) ?? null
        if (this.mesures) {
            let mesure_data_copy: Mesure[] = JSON.parse(JSON.stringify(mesures_data))
            this.mesures_triees = mesure_data_copy.sort((a: Mesure, b: Mesure) => {
                if (Number(a.mesure) < Number(b.mesure)) return -1;
                if (Number(a.mesure) > Number(b.mesure)) return 1;
                return 0;
            }).filter(m => !isNaN(Number(m.mesure)))

            this.mesures.map((mesure: Mesure, index) => {

                let _mesure = Number(mesure.mesure);

                this.mesure_moy += _mesure;

                if (_mesure > Number(this.anomalie_max) || _mesure < Number(this.anomalie_min) || _mesure === Number(this.anomalie_CS?.valeur_a_comparer)) {
                    //Mesure en excursion
                    if (this.excursions.length === 0 || this.excursions[this.excursions.length - 1].fin !== null) {
                        //On créer une excursion
                        let nom_exc = _mesure > Number(this.anomalie_max) ? "MAX" : "MIN"
                        if (this.anomalie_CS) nom_exc = this.anomalie_CS.nom
                        this.excursions.push({
                            cellule: this.cellules_affiche?.find(ca => ca.id == mesure.cellule_id + "")?.nom ?? "",
                            anomalie: nom_exc,
                            debut: mesure.enregistrement,
                            fin: null,
                            duree: 0,
                            max: _mesure,
                            min: _mesure,
                            mesures: [_mesure]
                        })
                    } else {
                        // Une excursion est en cours
                        this.excursions[this.excursions.length - 1].mesures.push(_mesure)
                        if (this.excursions[this.excursions.length - 1].min > _mesure) this.excursions[this.excursions.length - 1].min = _mesure
                        if (this.excursions[this.excursions.length - 1].max < _mesure) this.excursions[this.excursions.length - 1].max = _mesure

                    }

                    // @ts-ignore
                    if (index === this.mesures.length - 1) {
                        //C'est la dernière mesure qu'on a.
                        this.excursions[this.excursions.length - 1].fin = mesure.enregistrement
                        this.excursions[this.excursions.length - 1].duree = Math.round((new DateTZ(this.excursions[this.excursions.length - 1].fin).getTime() - new DateTZ(this.excursions[this.excursions.length - 1].debut).getTime()) / 1000 / 60);
                    }
                } else {
                    //Mesure n'est pas en excursion
                    if (this.excursions.length > 0 && this.excursions[this.excursions.length - 1].fin === null && this.mesures) {
                        //Une excursion est active, on l'arrete
                        this.excursions[this.excursions.length - 1].fin = this.mesures[index - 1].enregistrement
                        this.excursions[this.excursions.length - 1].duree = Math.round((new DateTZ(this.excursions[this.excursions.length - 1].fin).getTime() - new DateTZ(this.excursions[this.excursions.length - 1].debut).getTime()) / 1000 / 60);
                    }
                }


            })

            this.excursions = this.excursions.sort((a, b) => {
                if (a.duree > b.duree) return -1
                return 1
            })

            this.mesure_moy = Math.round(this.mesure_moy / this.mesures.length * 100) / 100;

            this.mesure_med = this.mesures_triees[Math.round((this.mesures_triees.length - 1) / 2)];
            this.mesure_ecart_type = 0;
            this.mesures.map((mesure: Mesure) => {
                // @ts-ignore
                this.mesure_ecart_type += Math.pow(Number(mesure.mesure) - this.mesure_moy, 2);
            })
            this.mesure_ecart_type = Math.round(Math.sqrt(this.mesure_ecart_type / this.mesures.length) * 100) / 100;

            this.nbe_mesures = this.mesures_triees.length;
            this.mesure_min = this.mesures_triees[0];
            this.mesure_max = this.mesures_triees[this.mesures_triees.length - 1];

            this.mesures.map(m => {
                if (!this.cellules_ids.includes(m.cellule_id)) this.cellules_ids.push(m.cellule_id)
            })

        } else {
            console.log("Une erreur est survenue durant la récupération des mesures pour le graphique.")
        }


    }

    calc_centile(centile: number) {
        if (!this.mesures_triees) return <p>Erreur.</p>;
        let mes = this.mesures_triees[Math.round((this.mesures_triees.length - 1) / 100 * centile)];
        return (
            <Carte_mesure
                titre={centile + "e centile"}
                texte={mes ? mes.mesure : "Aucune"}
                sonde={this.cellule?.sonde_type}
                handleClick={() => {
                    alert("Le " + centile + "e centile indique la mesure réelle qui est à " + centile + "% des mesures triées. " +
                        "Autrement dit, " + centile + "% des mesures sont en dessous de " + mes.mesure + this.cellule?.sonde_type?.affichage_suffix + " et " + (100 - centile) + "% sont au dessus.");
                }}
                key={centile + "e"}
                conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
            />
        )
    }

    cartesUneLigne(ajouterExcursions?:boolean) {

        let duree_totale = 0;
        this.excursions.map(e => duree_totale += (e.duree < 9 ? 10 : e.duree))

        let duree_moyenne = Math.round(duree_totale / this.excursions.length)

        return (
            <>
                <Carte_mesure
                    titre={"Nombre de mesures"}
                    texte={this.nbe_mesures ? this.nbe_mesures + '' : "Aucune"}
                    sonde={null}
                    handleClick={() => {
                        alert("Nombre de mesures sur la période");
                    }}
                    key={"nbe"}
                    conditions={undefined}
                />
                <Carte_mesure
                    titre={"Minimum"}
                    texte={this.mesure_min ? this.mesure_min.mesure : "Aucune"}
                    sonde={this.cellule?.sonde_type}
                    handleClick={() => {
                        // if (mesure_min) navigateToMesure(mesure_min)
                    }}
                    key={"min"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={"Maximum"}
                    texte={this.mesure_max ? this.mesure_max.mesure : "Aucune"}
                    sonde={this.cellule?.sonde_type}
                    handleClick={() => {
                        // if (mesure_max) navigateToMesure(mesure_max)
                    }}
                    key={"max"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={"Moyenne"}
                    texte={!isNaN(this.mesure_moy) ? this.mesure_moy + "" : "Aucune"}
                    sonde={!isNaN(this.mesure_moy) ? this.cellule?.sonde_type : null}
                    handleClick={() => {
                        alert("La moyenne est calculé et ne représente donc pas une mesure réelle.");
                    }}
                    key={"moy"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={"Ecart type"}
                    texte={!isNaN(this.mesure_ecart_type ?? NaN) ? this.mesure_ecart_type + "" : "Aucune"}
                    sonde={!isNaN(this.mesure_ecart_type ?? NaN) ? this.cellule?.sonde_type : null}
                    handleClick={() => {
                        alert("L'écart-type mesure à quel point les valeurs d'un ensemble de données sont éloignées de la moyenne : plus il est grand, plus les données sont dispersées ; " +
                            "plus il est petit, plus elles sont proches les unes des autres.\n\n" +
                            "Dis autrement, plus la valeur est basse, plus la " + this.cellule?.sonde_type?.affichage_nom_mesure.toLowerCase() + " est restée stable et proche de la moyenne sur la période. ");
                    }}
                    key={"ecart_type"}
                    conditions={undefined}
                />
                <Carte_mesure
                    titre={"MKT"}
                    texte={this.calculateMKT(this.mesures?.map(m => Number(m.mesure)) ?? []) + ""}
                    sonde={this.cellule?.sonde_type}
                    handleClick={() => {
                        alert("La température cinétique moyenne (MKT) est une façon simplifiée d'exprimer l'effet global des variations de température pendant le stockage ou le transport de denrées périssables. " +
                            "\n\n" +
                            "Techniquement parlant, MKT est une expression de la contrainte thermique cumulative subie par un produit à des températures variables pendant le stockage et la distribution. En d'autres termes, MKT est une seule température calculée qui est analogue à l'effet des variations de température sur une période de temps.\n" +
                            "MKT n'est pas une moyenne pondérée simple. Le calcul de MKT donne les températures les plus élevées d'un plus grand poids lors du calcul de la moyenne que ne le ferait une moyenne numérique simple ou une moyenne arithmétique. Cette pondération est déterminée par une transformation- géométrique du logarithme naturel de la température absolue.\n" +
                            // "\n" +
                            // "La Conférence internationale sur l'harmonisation (ICH) Lignes directrices de tests de stabilité définissent MKT comme «une température dérivée unique qui, si elle est maintenue sur une période définie, donnerait le même défi thermique pour un produit pharmaceutique qui aurait été connu sur une plage de deux supérieur et des températures plus basses pour une période équivalente définie ».\n" +
                            "\n" +
                            "En utilisant cette pondération inégale profil des  températures les plus élevées dans une série de températures, MKT prend en considération le taux accéléré de la dégradation thermique des matériaux à ces températures plus élevées.\n" +
                            "\n" +
                            "Par conséquent, MKT prévoit l'effet non linéaire de la température.");
                    }}
                    key={"mkt"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={"Médiane"}
                    texte={this.mesure_med ? this.mesure_med.mesure : "Aucune"}
                    sonde={this.cellule?.sonde_type}
                    handleClick={() => {
                        alert("La valeur médiane indique la mesure réelle qui est au milieu des mesures triées. " +
                            "Autrement dit, 50% des mesures sont en dessous de " + this.mesure_med?.mesure + this.cellule?.sonde_type?.affichage_suffix + " et 50% des autres sont au dessus.");
                    }}
                    key={"med"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                    // className={"only_mobile_flex"}
                />
                {[5, 95].map(centille => this.calc_centile(centille))}

                {ajouterExcursions && (
                    <>
                        <Carte_mesure titre={"Nombre d'excursions"} texte={this.excursions.length + ""}
                                      sonde={undefined} handleClick={() => {
                        }}/>
                        <Carte_mesure titre={"Durée totale excursions"}
                                      texte={this.excursions.length === 0 ? "0 minutes" : (() => {
                                          let duree_totale = 0;
                                          this.excursions.map(e => duree_totale += (e.duree < 9 ? 10 : e.duree))


                                          return (duree_totale / 60 >= 1 ? Math.floor(duree_totale / 60) + " hrs " : "")
                                              + (duree_totale == 0 ? "< 10 minutes" : duree_totale % 60 + " minutes");


                                      })()} sonde={undefined} handleClick={() => {
                        }}/>
                        <Carte_mesure titre={"Durée moyenne excursions"}
                                      texte={this.excursions.length === 0 ? "0 minutes" : (() => {



                                          let a = duree_moyenne / 60 >= 1 ? Math.floor(duree_moyenne / 60) + " hrs " : ""
                                          a += duree_moyenne == 0 ? "< 10" : duree_moyenne % 60 + " minutes"

                                          return a;


                                      })()} sonde={undefined} handleClick={() => {
                        }}/>
                    </>
                )}
            </>
        )
    }

    cartesDeuxLigne() {
        return (
            <>
                <Carte_mesure
                    titre={"Nombre de mesures"}
                    texte={this.nbe_mesures ? this.nbe_mesures + '' : "Aucune"}
                    sonde={null}
                    handleClick={() => {
                        alert("Nombre de mesures sur la période");
                    }}
                    key={"nbe"}
                    conditions={undefined}
                />
                <Carte_mesure
                    titre={"Minimum"}
                    texte={this.mesure_min ? this.mesure_min.mesure : "Aucune"}
                    sonde={this.cellule?.sonde_type}
                    handleClick={() => {
                        // if (mesure_min) navigateToMesure(mesure_min)
                    }}
                    key={"min"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={"Maximum"}
                    texte={this.mesure_max ? this.mesure_max.mesure : "Aucune"}
                    sonde={this.cellule?.sonde_type}
                    handleClick={() => {
                        // if (mesure_max) navigateToMesure(mesure_max)
                    }}
                    key={"max"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={"Moyenne"}
                    texte={!isNaN(this.mesure_moy) ? this.mesure_moy + "" : "Aucune"}
                    sonde={!isNaN(this.mesure_moy) ? this.cellule?.sonde_type : null}
                    handleClick={() => {
                        alert("La moyenne est calculé et ne représente donc pas une mesure réelle.");
                    }}
                    key={"moy"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={"Ecart type"}
                    texte={!isNaN(this.mesure_ecart_type ?? NaN) ? this.mesure_ecart_type + "" : "Aucune"}
                    sonde={!isNaN(this.mesure_ecart_type ?? NaN) ? this.cellule?.sonde_type : null}
                    handleClick={() => {
                        alert("L'écart-type mesure à quel point les valeurs d'un ensemble de données sont éloignées de la moyenne : plus il est grand, plus les données sont dispersées ; " +
                            "plus il est petit, plus elles sont proches les unes des autres.\n\n" +
                            "Dis autrement, plus la valeur est basse, plus la " + this.cellule?.sonde_type?.affichage_nom_mesure.toLowerCase() + " est restée stable et proche de la moyenne sur la période. ");
                    }}
                    key={"ecart_type"}
                    conditions={undefined}
                />
                {/*<Carte_mesure*/}
                {/*    titre={"Médiane"}*/}
                {/*    texte={this.mesure_med ? this.mesure_med.mesure : "Aucune"}*/}
                {/*    sonde={this.cellule?.sonde_type}*/}
                {/*    handleClick={() => {*/}
                {/*        alert("La valeur médiane indique la mesure réelle qui est au milieu des mesures triées. " +*/}
                {/*            "Autrement dit, 50% des mesures sont en dessous de " + this.mesure_med?.mesure + this.cellule?.sonde_type?.affichage_suffix + " et 50% des autres sont au dessus.");*/}
                {/*    }}*/}
                {/*    key={"med"}*/}
                {/*    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}*/}
                {/*/>*/}
                <Carte_mesure
                    titre={"MKT"}
                    texte={this.calculateMKT(this.mesures?.map(m => Number(m.mesure)) ?? []) + ""}
                    sonde={this.cellule?.sonde_type}
                    handleClick={() => {
                        alert("La température cinétique moyenne (MKT) est une façon simplifiée d'exprimer l'effet global des variations de température pendant le stockage ou le transport de denrées périssables. " +
                            "\n\n" +
                            "Techniquement parlant, MKT est une expression de la contrainte thermique cumulative subie par un produit à des températures variables pendant le stockage et la distribution. En d'autres termes, MKT est une seule température calculée qui est analogue à l'effet des variations de température sur une période de temps.\n" +
                            "MKT n'est pas une moyenne pondérée simple. Le calcul de MKT donne les températures les plus élevées d'un plus grand poids lors du calcul de la moyenne que ne le ferait une moyenne numérique simple ou une moyenne arithmétique. Cette pondération est déterminée par une transformation- géométrique du logarithme naturel de la température absolue.\n" +
                            // "\n" +
                            // "La Conférence internationale sur l'harmonisation (ICH) Lignes directrices de tests de stabilité définissent MKT comme «une température dérivée unique qui, si elle est maintenue sur une période définie, donnerait le même défi thermique pour un produit pharmaceutique qui aurait été connu sur une plage de deux supérieur et des températures plus basses pour une période équivalente définie ».\n" +
                            "\n" +
                            "En utilisant cette pondération inégale profil des  températures les plus élevées dans une série de températures, MKT prend en considération le taux accéléré de la dégradation thermique des matériaux à ces températures plus élevées.\n" +
                            "\n" +
                            "Par conséquent, MKT prévoit l'effet non linéaire de la température.");
                    }}
                    key={"mkt"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                {[5, 10, 25].map(centille => this.calc_centile(centille))}
                {[75, 90, 95].map(centille => this.calc_centile(centille))}

            </>
        )
    }

    cartesLigneCS(ajouterExcursions?:boolean) {
        if (!this.anomalie_CS) return <>Erreur1.</>
        if (!this.mesures) return <>Erreur2.</>
        let faux = 0;
        for (let i = 0; i < this.mesures.length; i++) {
            if (this.mesures[i].mesure == "0") faux++;
        }

        return (
            <>
                <Carte_mesure
                    titre={"Nombre de mesures"}
                    texte={this.nbe_mesures ? this.nbe_mesures + '' : "Aucune"}
                    sonde={null}
                    handleClick={() => {
                        alert("Nombre de mesures sur la période");
                    }}
                    key={"nbe"}
                    conditions={undefined}
                />
                <Carte_mesure
                    titre={this.cellule.sonde_type?.affichage_faux ?? "0"}
                    texte={faux + " fois"}
                    sonde={undefined}
                    handleClick={() => {
                        // alert("La valeur médiane indique la mesure réelle qui est au milieu des mesures triées. " +
                        //     "Autrement dit, 50% des mesures sont en dessous de " + this.mesure_med?.mesure + this.cellule?.sonde_type?.affichage_suffix + " et 50% des autres sont au dessus.");
                    }}
                    key={"med"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={this.cellule.sonde_type?.affichage_faux ?? "0"}
                    texte={Math.round(faux * 100 / this.mesures.length) + "%"}
                    sonde={undefined}
                    handleClick={() => {
                        // alert("La valeur médiane indique la mesure réelle qui est au milieu des mesures triées. " +
                        //     "Autrement dit, 50% des mesures sont en dessous de " + this.mesure_med?.mesure + this.cellule?.sonde_type?.affichage_suffix + " et 50% des autres sont au dessus.");
                    }}
                    key={"med"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={this.cellule.sonde_type?.affichage_vrai ?? "1"}
                    texte={(this.mesures.length - faux) + " fois"}
                    sonde={undefined}
                    handleClick={() => {
                        // alert("La valeur médiane indique la mesure réelle qui est au milieu des mesures triées. " +
                        //     "Autrement dit, 50% des mesures sont en dessous de " + this.mesure_med?.mesure + this.cellule?.sonde_type?.affichage_suffix + " et 50% des autres sont au dessus.");
                    }}
                    key={"med"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                <Carte_mesure
                    titre={this.cellule.sonde_type?.affichage_vrai ?? "1"}
                    texte={Math.round((this.mesures.length - faux) * 100 / this.mesures.length) + "%"}
                    sonde={undefined}
                    handleClick={() => {
                        // alert("La valeur médiane indique la mesure réelle qui est au milieu des mesures triées. " +
                        //     "Autrement dit, 50% des mesures sont en dessous de " + this.mesure_med?.mesure + this.cellule?.sonde_type?.affichage_suffix + " et 50% des autres sont au dessus.");
                    }}
                    key={"med"}
                    conditions={this.cellule?.statut_actuel?.statut.can_use_alertes ? this.cellule?.conditions : []}
                />
                {ajouterExcursions && (
                    <>
                        <Carte_mesure titre={"Nombre d'excursions"} texte={this.excursions.length + ""}
                                      sonde={undefined} handleClick={() => {
                        }}/>
                        <Carte_mesure titre={"Durée totale excursions"}
                                      texte={this.excursions.length === 0 ? "0 minutes" : (() => {
                                          let duree_totale = 0;
                                          this.excursions.map(e => duree_totale += (e.duree < 9 ? 10 : e.duree))


                                          let a = duree_totale / 60 >= 1 ? Math.floor(duree_totale / 60) + " hrs " : ""
                                          a += duree_totale == 0 ? "< 10 minutes" : duree_totale % 60 + " minutes"

                                          return a;


                                      })()} sonde={undefined} handleClick={() => {
                        }}/>
                    </>
                )}

            </>
        )
    }

    lineGraph(hide_titre?: boolean) {
        if (!this.mesures) return <p>Erreur 1</p>
        if (!this.mesure_min) return <p>Erreur 2</p>
        if (!this.mesure_max) return <p>Erreur 3</p>
        let ecart_type = this.mesure_ecart_type;
        if (!ecart_type) {
            ecart_type = 2;
        }
        let mesures_tableau_line: Mesure[] = [];
        let trous_detecte = false;

        let resolution_max_graph = 10000;
        let saut_mesures = Math.ceil(this.mesures.length / resolution_max_graph)

        mesures_tableau_line.push(this.mesures[0])
        for (let i = 1; i < this.mesures.length; i = i + saut_mesures) {
            let heure = new DateTZ(this.mesures[i - 1].enregistrement)
            heure.setMinutes(heure.getMinutes() + 20)
            while (heure.getTime() < new DateTZ(this.mesures[i].enregistrement).getTime()) {
                trous_detecte = true;
                mesures_tableau_line.push(
                    {
                        cellule_id: this.cellule.id,
                        // @ts-ignore
                        mesure: null,
                        enregistrement: heure.toISOString(),
                        timestamp: 0,
                        statut: null
                    })
                heure.setMinutes(heure.getMinutes() + 20 * saut_mesures)
            }

            mesures_tableau_line.push(this.mesures[i])
        }



        let references_lines: ChartReferenceLineProps[] | undefined = undefined;
        if (this.cellules_ids.length === 1) {
            references_lines = [
                {
                    y: this.anomalie_max ?? undefined,
                    label: 'Max ' + this.anomalie_max + this.cellule.sonde_type?.affichage_suffix,
                    color: 'red.6'
                },
                {
                    y: this.anomalie_min ?? undefined,
                    label: 'Min ' + this.anomalie_min + this.cellule.sonde_type?.affichage_suffix,
                    color: 'red.6'
                },
                {
                    y: this.anomalie_CS?.slug === 2 ? 0 : undefined,
                    label: this.anomalie_CS?.nom,
                    color: 'red.6'
                },
                {
                    y: this.anomalie_CS?.slug === 2 ? 1 : undefined,
                    label: this.cellule.sonde_type?.affichage_vrai,
                    color: 'gray.6'
                },
                {
                    y: this.anomalie_CS?.slug === 3 ? 1 : undefined,
                    label: this.anomalie_CS?.nom,
                    color: 'red.6'
                },
                {
                    y: this.anomalie_CS?.slug === 3 ? 0 : undefined,
                    label: this.cellule.sonde_type?.affichage_faux,
                    color: 'gray.6'
                },
                // {
                //     y: this.anomalie_CS ? Math.abs(Number(this.anomalie_CS.valeur_a_comparer) -1) : undefined,
                //     label: this.cellule.sonde_type.,
                //     color: 'gray.6'
                // },
            ]
        }

        let gradient: { offset: number, color: string }[] | undefined = undefined;
        if (this.anomalie_max && this.mesures_triees) {
            let offset = ((Number(this.anomalie_max) - Number(this.mesures_triees[this.mesures_triees.length - 1].mesure)) / (Number(this.mesures_triees[0].mesure) - Number(this.mesures_triees[this.mesures_triees.length - 1].mesure))) * 100;
            // alert(offset)
            if (offset >= 0) {
                gradient = [
                    {offset: 0, color: 'red.6'},
                    {offset: offset, color: 'red.6'},
                    {offset: offset + 0.01, color: 'hsl(210deg 84% 66%)'},
                ]
            }
        }
        if (this.anomalie_min && this.mesures_triees) {
            //percentage = ((value - MIN) / (MAX - MIN)) * 100
            let offset = ((Number(this.anomalie_min) - Number(this.mesures_triees[this.mesures_triees.length - 1].mesure)) / (Number(this.mesures_triees[0].mesure) - Number(this.mesures_triees[this.mesures_triees.length - 1].mesure))) * 100;
            if (offset <= 100) {
                // alert("MIN : " + offset)
                if (!gradient) gradient = [];
                gradient = gradient.concat([
                    {offset: offset - 0.01, color: 'hsl(210deg 84% 66%)'},
                    {offset: offset, color: 'red.6'},
                    {offset: 100, color: 'red.6'},
                ]);
            }

        }

        return (
            <>
                {!hide_titre && <h1><br/>{this.cellule?.sonde_type?.affichage_nom_mesure} sur la période : </h1>}

                <LineChart
                    h={this.anomalie_CS? 100 : 300}
                    data={
                        mesures_tableau_line.map(mesure => {
                            let data = {
                                date: afficherDateTime(mesure.enregistrement),
                                color: "green.6"
                                // temp: mesure.mesure ?? undefined,
                                // Min: cellule?.conditions.find(c => c.slug === 0)?.valeur_a_comparer ?? null,
                                // Max: cellule?.conditions.find(c => c.slug === 1)?.valeur_a_comparer ?? null
                            }
                            // @ts-ignore
                            data[mesure.cellule_id] = mesure.mesure ?? undefined
                            return data;
                        })
                    }
                    yAxisProps={{
                        domain: this.anomalie_CS ? [
                            0, 1
                        ] : [
                            Math.floor(Number(this.mesure_min.mesure) - Math.abs(ecart_type / 2)),
                            Math.ceil(Number(this.mesure_max.mesure) + Math.abs(ecart_type / 2))
                        ]
                    }}
                    dataKey="date"
                    valueFormatter={value => {
                        if (this.anomalie_CS && this.anomalie_CS.slug === 2) {
                            if (value == 0) return this.cellule.sonde_type?.affichage_faux ?? value + ""
                            else return this.cellule.sonde_type?.affichage_vrai ?? value + ""
                        }
                        if (this.anomalie_CS && this.anomalie_CS.slug === 3) {
                            if (value != 0) return this.cellule.sonde_type?.affichage_vrai ?? value + ""
                            else return this.cellule.sonde_type?.affichage_faux ?? value + ""
                        }
                        return value + (this.cellule?.sonde_type?.affichage_suffix ?? "")
                    }}
                    w={"100%"}
                    gradientStops={gradient}
                    referenceLines={references_lines}

                    type={this.cellules_ids.length === 1 && gradient ? "gradient" : undefined}
                    series={
                        this.cellules_ids.map((c, index) => {
                            return {
                                name: c + "",
                                color: this.cellules_ids.length > 1 ? "hsl(" + (210 - 80 * index) + "deg 84% 66%)" : undefined,
                                label: this.cellules_ids.length === 1 ? this.cellule?.sonde_type?.affichage_nom_mesure : this.cellules_affiche?.find(ca => ca.id == c + "")?.nom
                            }
                        })
                    }
                    curveType={this.anomalie_CS ? "stepAfter" : "monotone"}
                    withDots={false}
                    connectNulls={this.cellules_ids.length > 1}
                    withLegend={this.cellules_ids.length > 1}
                    withYAxis={this.anomalie_CS === undefined}
                    gridAxis={this.anomalie_CS? "none" : undefined}
                />
                {trous_detecte && (
                    <div className={"en-ligne justify_center"} style={{width: "100%"}}>
                        <p className={"inline-tag"}>Attentions: des données manquantes ont été détecté. Il semblerai
                            qu'il y ai un trou dans les données.</p>
                    </div>
                )}
            </>
        )
    }

    barGraph() {

        if (!this.mesures_triees) return <p>Erreur</p>
        if (!this.mesure_min) return <p>Erreur</p>
        if (!this.mesure_max) return <p>Erreur</p>

        if (this.cellules_ids.length > 1) return <></>


        let mesures_tableau_bar: { temp: number, compt: number, centile: number }[] = [];
        this.mesures_triees.map((m, index) => {
            let existe = 0
            // @ts-ignore
            let centile = Math.round((index) * 100 / this.mesures_triees.length * 100) / 100
            //On récupère le compte et on retire du tableau la mesure :
            mesures_tableau_bar = mesures_tableau_bar.filter(t => {
                if (t.temp === Math.round(Number(m.mesure) * 10) / 10) {
                    existe = t.compt;
                    centile = (t.centile + centile) / 2;
                    return false
                }
                return true;
            })
            //On ajoute au tableau :
            mesures_tableau_bar.push({
                temp: Math.round(Number(m.mesure) * 10) / 10,
                compt: existe + 1,
                centile: centile,
            })
        })
        for (let i = Number(this.mesure_min.mesure); i < Number(this.mesure_max.mesure); i = Math.round((i + 0.1) * 10) / 10) {
            let existe = 0
            //On récupère le compte et on retire du tableau la mesure :
            if (!mesures_tableau_bar.find(t => Number(t.temp) === i)) {
                //On ajoute au tableau :
                mesures_tableau_bar.push({
                    temp: i,
                    compt: 0,
                    centile: 0,
                })
            }
        }
        mesures_tableau_bar = mesures_tableau_bar.sort((a, b) => {
            if (Number(a.temp) < Number(b.temp)) return -1;
            if (Number(a.temp) > Number(b.temp)) return 1;
            return 0;
        })

        return (
            <>

                <h1><br/>Répartitions des mesures : </h1>
                <BarChart
                    h={300}
                    withYAxis={false}
                    gridAxis="none"
                    data={
                        mesures_tableau_bar.map((m, index) => {
                            return {
                                temp: m.temp + (this.cellule?.sonde_type?.affichage_suffix ?? ""),
                                compt: m.compt,
                                // compt: m.compt + ", " + Math.ceil(index * 100 / mesures_tableau_bar.length) + "e centile",
                                centile: m.centile,
                                color: (this.cellules_ids.length === 1 && (Number(m.temp) < Number(this.cellule?.conditions.find(c => c.slug === 0)?.valeur_a_comparer ?? -999999)
                                    || Number(m.temp) > Number(this.cellule?.conditions.find(c => c.slug === 1)?.valeur_a_comparer ?? 999999))) ? "red.6" : "blue.6"
                            }
                        })
                    }
                    dataKey="temp"
                    w={"100%"}
                    barProps={{radius: 10}}
                    referenceLines={[5, 25, 50, 75, 95].map(i => ({
                        x: Math.round(Number(
                            //@ts-ignore
                            this.mesures_triees[
                                //@ts-ignore
                                Math.max(0, Math.round((this.mesures_triees.length) / 100 * i) - 1)
                                ].mesure
                        ) * 10) / 10 + "°C",
                        label: i === 50 ? "Médiane" : i + 'e',
                        color: 'gray.6',
                        labelPosition: 'insideTopRight',
                    }))}
                    tooltipProps={{
                        // @ts-ignore
                        content: ({label, payload}: {
                            label: string;
                            payload: Record<string, any>[] | undefined;
                        }) => {
                            if (!payload) return null;

                            return (
                                <Paper px="md" py="sm" withBorder shadow="md" radius="md">
                                    <Text fw={500}>
                                        {label}
                                    </Text>
                                    {payload.length > 0 && (
                                        <>
                                            <Text fw={500} mb={5} fz="sm">
                                                {payload[0].payload.centile > 0 && (Math.round(payload[0].payload.centile * 10) / 10) + "% des mesures sont en dessous"}
                                            </Text>


                                            {payload.map((item: any) => (
                                                <Text key={item.name} c={item.color} fz="sm">
                                                    {item.value === 0 ? "Aucune mesure" : "Nombre de mesures: " + item.value}
                                                    {/*{item.value === 0 && "Aucune mesure"}*/}
                                                </Text>
                                            ))}
                                        </>
                                    )}
                                </Paper>
                            );
                        },
                    }}
                    valueFormatter={value => value < 0 ? (value * 10000) + "" : value + ""}
                    series={[
                        // { name: 'max', color: 'red.6' },
                        // { name: 'temp', color: 'blue.6' },
                        {name: 'compt', color: 'blue.6', label: "Nombre de mesures"},
                        // { name: 'centile', color: 'dsfsdf', label:"Centile" },
                    ]}
                />
            </>
        )
    }

    barGraphExcursions() {

        if (!this.excursions) return <p>Erreur</p>

        let anomalies:string[] = [];
        this.excursions.map(e => !anomalies.includes(e.anomalie) && anomalies.push(e.anomalie));

        return anomalies.map(anomalie => {

            let mesures_tableau_bar: { duree: number, compt: number }[] = [];
            this.excursions.filter(e => e.anomalie === anomalie).map((m, index) => {
                let existe = 0
                // @ts-ignore
                //On récupère le compte et on retire du tableau la mesure :
                mesures_tableau_bar = mesures_tableau_bar.filter(t => {
                    if (t.duree === Math.round(Number(m.duree))) {
                        existe = t.compt;
                        return false
                    }
                    return true;
                })
                //On ajoute au tableau :
                mesures_tableau_bar.push({
                    duree: Math.round(Number(m.duree)),
                    compt: existe + 1
                })
            })

            mesures_tableau_bar = mesures_tableau_bar.sort((a, b) => {
                if (Number(a.duree) < Number(b.duree)) return -1;
                if (Number(a.duree) > Number(b.duree)) return 1;
                return 0;
            })

            return (
                <>

                    <h1><br/>Répartitions de l'excursion {anomalie} : </h1>
                    <BarChart
                        h={300}
                        withYAxis={false}
                        gridAxis="none"
                        data={
                            mesures_tableau_bar.map((m, index) => {
                                return {
                                    temp: (m.duree / 60 >= 1 ? Math.floor(m.duree / 60) + " hrs " : "")
                                        + (m.duree == 0 ? "< 10 minutes" : m.duree % 60 + " minutes"),
                                    compt: m.compt,
                                    // compt: m.compt + ", " + Math.ceil(index * 100 / mesures_tableau_bar.length) + "e centile",
                                    centile: Math.round(m.compt * 100 / this.excursions.filter(e => e.anomalie === anomalie).length),
                                    color: "blue.6"
                                }
                            })
                        }
                        dataKey="temp"
                        w={"100%"}
                        barProps={{radius: 10}}
                        tooltipProps={{
                            // @ts-ignore
                            content: ({label, payload}: {
                                label: string;
                                payload: Record<string, any>[] | undefined;
                            }) => {
                                if (!payload) return null;

                                return (
                                    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
                                        <Text fw={500}>
                                            {label}
                                        </Text>
                                        {payload.length > 0 && (
                                            <>
                                                <Text fw={500} mb={5} fz="sm">
                                                    {payload[0].payload.centile > 0 && (Math.round(payload[0].payload.centile * 10) / 10) + "%"}
                                                </Text>


                                                {payload.map((item: any) => (
                                                    <Text key={item.name} c={item.color} fz="sm">
                                                        {item.value === 0 ? "Aucune excursion" : "Nombre d'excursions: " + item.value}
                                                        {/*{item.value === 0 && "Aucune mesure"}*/}
                                                    </Text>
                                                ))}
                                            </>
                                        )}
                                    </Paper>
                                );
                            },
                        }}
                        valueFormatter={value => value < 0 ? (value * 10000) + "" : value + ""}
                        series={[
                            // { name: 'max', color: 'red.6' },
                            // { name: 'temp', color: 'blue.6' },
                            {name: 'compt', color: 'blue.6', label: "Nombre d'excursions"},
                            // { name: 'centile', color: 'dsfsdf', label:"Centile" },
                        ]}
                    />
                </>
            )
        })

    }

    excursionsCartes() {


        let duree_totale = 0;
        this.excursions.map(e => duree_totale += (e.duree < 9 ? 10 : e.duree))

        let duree_moyenne = Math.round(duree_totale / this.excursions.length)

        return (
            <>

                <div className={"excursions"} style={{width: "100%"}}>
                    {this.excursions.length === 0 ? (
                        <p>Aucune excursion sur la période. </p>
                    ) : (
                        <>
                            <div className={"mesures mesures_3"}>

                                <Carte_mesure titre={"Nombre d'excursions"} texte={this.excursions.length + ""}
                                              sonde={undefined} handleClick={() => {
                                }}/>
                                <Carte_mesure titre={"Durée totale excursions"}
                                              texte={this.excursions.length === 0 ? "0 minutes" : (() => {
                                                  return (duree_totale / 60 >= 1 ? Math.floor(duree_totale / 60) + " hrs " : "")
                                                      + (duree_totale == 0 ? "< 10" : duree_totale % 60 + " minutes");


                                              })()} sonde={undefined} handleClick={() => {
                                }}/>
                                <Carte_mesure titre={"Durée moyenne excursions"}
                                              texte={this.excursions.length === 0 ? "0 minutes" : (() => {



                                                  let a = duree_moyenne / 60 >= 1 ? Math.floor(duree_moyenne / 60) + " hrs " : ""
                                                  a += duree_moyenne == 0 ? "< 10" : duree_moyenne % 60 + " minutes"

                                                  return a;


                                              })()} sonde={undefined} handleClick={() => {
                                }}/>
                            </div>
                        </>
                    )}

                </div>
            </>
        )
    }

    excursionsTable() {

        let excursions_triees: excursion[] = JSON.parse(JSON.stringify(this.excursions))
        // excursions_triees = excursions_triees

        let type_donnees:typeDonnee[] = [];

        if (this.cellules_ids.length > 1){
            type_donnees = type_donnees.concat([
                {key: "cellule", nom: "Cellule", noedit: true},
            ]);
        }

        type_donnees = type_donnees.concat([
            {key: "anomalie", nom: "Anomalie", noedit: true},
            {key: "debut", nom: "Heure de début", type: "date", noedit: true},
            {key: "fin", nom: "Heure de fin", type: "date", noedit: true},
            {key: "duree", nom: "Durée", type:"number", noedit: true, wrapper: (texte:any) => (
                    <>
                        {texte / 60 >= 1 ? Math.floor(texte / 60) + " hrs " : ""}
                        {texte == 0 ? "< 10" : texte % 60} minutes
                    </>
                )},
        ]);
        if (!this.anomalie_CS){
            type_donnees = type_donnees.concat([
                {key: "min", nom: "Min", type:"number", noedit: true, suffix: this.cellule.sonde_type?.affichage_suffix},
                {key: "max", nom: "Max", type:"number", noedit: true, suffix: this.cellule.sonde_type?.affichage_suffix},
                {key: "mesures", nom: "Moyenne", noedit: true, key_tab: "moy",
                    edit_display_value: (value:any) => this.calculateMoyenne(value)+"",
                    suffix: this.cellule.sonde_type?.affichage_suffix},
                {key: "mesures", nom: "MKT", noedit: true, key_tab: "MKT",
                    edit_display_value: (value:any) => this.calculateMKT(value)+"",
                    suffix: this.cellule.sonde_type?.affichage_suffix},
            ])
        }

        return (
            <>
                <div className={"excursions"} style={{width: "100%"}}>
                    {this.excursions.length === 0 ? (
                        <p>Aucune excursion sur la période. </p>
                    ) : (
                        <>
                            {this.excursions.length > 0 && (
                                <>
                                    <Tableau_helper typeDonnee={type_donnees}

                                                    donnees={excursions_triees}

                                    />
                                </>
                            )}
                        </>
                    )}

                </div>
            </>
        )
    }

    calculateMKT(temperatures: number[]): number {
        const Ea = 83144; // Constante d'énergie d'activation (en joules/mole)
        const R = 8.314; // Constante des gaz parfaits en J/mol·K

        // Convertir les températures en kelvins
        const kelvinTemps = temperatures.map(temp => temp + 273.15);

        // Calcul de la MKT
        const expSum = kelvinTemps.reduce((sum, T) => sum + Math.exp((-Ea) / (R * T)), 0);
        const avgExp = expSum / kelvinTemps.length;

        const MKT = (-Ea) / (R * Math.log(avgExp));

        // Convertir le résultat en degrés Celsius
        return Math.round((MKT - 273.15) * 100) / 100;
    }

    calculateMoyenne(temperatures: number[]): number {
        let moy = temperatures[0];

        for (let i = 1; i < temperatures.length - 1; i++) {
            moy = (moy + temperatures[i]) / 2
        }

        // Convertir le résultat en degrés Celsius
        return Math.round(moy * 100) / 100;
    }
}
