import React from "react";
import {IconeBatteryExclamation} from "../solid_react/battery-exclamation";
import {IconeBatteryEmpty} from "../solid_react/battery-empty";
import {IconeBatteryLow} from "../solid_react/battery-low";
import {IconeBatteryQuarter} from "../solid_react/battery-quarter";
import {IconeBatteryHalf} from "../solid_react/battery-half";
import {IconeBatteryThreeQuarters} from "../solid_react/battery-three-quarters";
import {IconeBatteryFull} from "../solid_react/battery-full";

export function Icone_batterie({niveau_batterie}: { niveau_batterie?: number|null }) {

    let bat_couleur = "grey";
    let bat_icone = "battery-exclamation";
    let icone = <IconeBatteryExclamation className={"grey"} />

    if (!niveau_batterie) {
        return (
            <></>
        )
    }

    if (niveau_batterie <= 5) {
        bat_couleur = "red";
        bat_icone = "battery-empty"
        icone = <IconeBatteryEmpty className={"red"} />
    } else if (niveau_batterie <= 15) {
        bat_couleur = "orange";
        bat_icone = "battery-low"
        icone = <IconeBatteryLow className={"orange"} />
    } else if (niveau_batterie <= 25) {
        bat_couleur = "orange";
        bat_icone = "battery-quarter"
        icone = <IconeBatteryQuarter className={"orange"} />
    } else if (niveau_batterie <= 50) {
        bat_couleur = "green";
        bat_icone = "battery-half"
        icone = <IconeBatteryHalf className={"green"} />
    } else if (niveau_batterie <= 75) {
        bat_couleur = "green";
        bat_icone = "battery-three-quarters"
        icone = <IconeBatteryThreeQuarters className={"green"} />
    } else if (niveau_batterie > 75) {
        bat_couleur = "green";
        bat_icone = "battery-full"
        icone = <IconeBatteryFull className={"green"} />
    }
    return (
        <>{icone}</>

        // <Icone className={bat_couleur} nom={bat_icone}/>
    )
}