import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Avatar, Checkbox, Group, Modal, NumberInput, Select, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Organisation} from "../../../types/organisation";
import {Condition, horaire_maintenance} from "../../../types/condition";
import {
    Modale_horaire_maintenance
} from "../../../components/Modales/Modale_horaire_maintenance/Modale_horaire_maintenance";
import {IconePenToSquare} from "../../../components/icone/solid_react/pen-to-square";

export function Modale_edit_anomalies_en_masse({organisation, conditionsAModifier, refreshParent}:{organisation:Organisation, conditionsAModifier:Condition[], refreshParent:()=>void}) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [id, setId] = useState(-999);

    const [nom, setNom] = useState("");
    const [nomChecked, setNomChecked] = useState(false);

    // const [type, setType] = useState("");
    // const [typeChecked, setTypeChecked] = useState(false);

    const [valeur, setValeur] = useState<string>("");
    const [valeurChecked, setValeurChecked] = useState(false);

    const [duree, setDuree] = useState<string | number>("");
    const [dureeChecked, setDureeChecked] = useState(false);

    const [horaires_maintenance, setHoraires_maintenance] = useState<horaire_maintenance[]>([]);
    const [horaires_maintenanceChecked, setHoraires_maintenanceChecked] = useState(false);


    const [hide_modale, set_hide_modale] = useState(false);

    const form_ref = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        if (conditionsAModifier.length > 0)
        {
            if (conditionsAModifier[0].id !== id)
            {
                setId(conditionsAModifier[0].id)
                setNom(conditionsAModifier[0].nom)
                setValeur(conditionsAModifier[0].valeur_a_comparer)
                setDuree(conditionsAModifier[0].delais)
                setHoraires_maintenance(conditionsAModifier[0].horaires_maintenance)
            }
        }

    }, [conditionsAModifier])

    // if (forcer) handlers.open();

    function handleEnregistrer() {
        if (!form_ref.current?.checkValidity()){
            form_ref.current?.reportValidity();
            return;
        }
        if (organisation.zones === undefined || organisation.zones?.length === 0) return;
        ajax.put("/organisations/" + organisation.zones[0].organisation_id + "/conditions", {
            "ids": conditionsAModifier.map(c => c.id),
            "nom": nomChecked ? nom : null,
            "valeur": valeurChecked ? valeur : null,
            "delais": dureeChecked? duree : null,
            "horaires": horaires_maintenanceChecked ? horaires_maintenance : null,
        }).then(response => {
            refreshParent();
            handlers.close();
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })
        set_btn_loading(true);
    }

    let type_conditions = "rien"
    conditionsAModifier.map(c => {
        if (type_conditions === "rien")
        {
            if ([0, 1, 9, 11].includes(c.slug))
            {
                type_conditions = "number"
            }
            if ([2, 3].includes(c.slug))
            {
                type_conditions = "bool"
            }
            if ([8, 10].includes(c.slug))
            {
                type_conditions = "deco"
            }
        }
        else{
            if ([0, 1].includes(c.slug) && type_conditions !== "number")
            {
                type_conditions = "multi"
            }
            if ([2, 3].includes(c.slug) && type_conditions !== "bool")
            {
                type_conditions = "multi"
            }
            if ([8, 9].includes(c.slug) && type_conditions !== "deco")
            {
                type_conditions = "multi"
            }
        }
    })

    return (
        <>
            <button onClick={handlers.open} disabled={conditionsAModifier.length < 1} id={"btn_modifier_anomalies"}><IconePenToSquare/> Modifier les anomalies sélectionnées</button>


            <Modal opened={opened} onClose={handlers.close} title="Modifier les anomalies" className={hide_modale ? "modal_hide" : ""}>
                <p>Vous allez modifier les anomalies suivantes : </p>
                <span>
                    {conditionsAModifier.map(c => <p className={"inline-tag"}>- "{c.nom}" de la cellule "{c.helper_cellule?.nom}"</p>)}
                </span>
                <form className={"form"} ref={form_ref} onSubmit={event => event.preventDefault()}>

                    <div style={{marginTop: "1rem"}}>
                        <Checkbox
                            label={"Nom de la condition"}
                            checked={nomChecked}
                            onChange={e => setNomChecked(e.target.checked)}
                        />
                        <TextInput
                            value={nom}
                            disabled={!nomChecked}
                            onChange={event => setNom(event.target.value)}
                        />
                    </div>


                    {type_conditions === "number" && (
                        <div>
                            <Checkbox
                                label={"Valeur limite"}
                                checked={valeurChecked}
                                onChange={e => setValeurChecked(e.target.checked)}
                            />
                            <NumberInput
                                value={parseInt(valeur)}
                                disabled={!valeurChecked}
                                onChange={e => setValeur(e + "")}
                            />
                        </div>
                    )}


                    <div>
                        <Checkbox
                            label={"Durée en minutes avant le déclenchement"}
                            checked={dureeChecked}
                            onChange={e => setDureeChecked(e.target.checked)}
                        />
                        <NumberInput
                            description={"Durée en minutes pendant lesquelles les mesures doivent être en anomalie pour déclencher les alertes"}
                            value={duree}
                            disabled={!dureeChecked}
                            onChange={setDuree}
                            min={conditionsAModifier.filter(c => c.slug === 8 || c.slug === 10).length > 0 ? 12 : 0}
                            step={15}
                        />
                    </div>


                    <div>
                        <Checkbox
                            label={"Horaires de maintenance"}
                            checked={horaires_maintenanceChecked}
                            onChange={e => setHoraires_maintenanceChecked(e.target.checked)}
                        />
                        <Modale_horaire_maintenance horaires={horaires_maintenance}
                                                    setHoraires={setHoraires_maintenance}
                                                    setFlou={set_hide_modale}
                                                    disabled={!horaires_maintenanceChecked}
                        />
                    </div>


                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} icone={"check"}>Appliquer les modifications</Loading_button>
                </form>

            </Modal>
        </>

    )
}