import {Navbar} from "../../../components/navbar/navbar";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import React, {useEffect, useRef, useState} from "react";
import './Mesures.scss'
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ajax from "../../../services/AxiosInterceptor";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {Cellule} from "../../../types/cellule";
import {DateInput, DatePicker, DateTimePicker, DateValue} from '@mantine/dates';
import {Last_mesure, Mesure, Mesure_infos} from "../../../types/mesure";
import {Carte_mesure} from "../../../components/carte_mesure/carte_mesure";
import {Code, Grid, Menu, Modal, NativeSelect, Paper, Tabs, Tooltip, Text} from "@mantine/core";
import {
    afficherDate,
    afficherDateTime,
    afficherDateTimeSecondes, afficherDateTimeSecondesUTC,
    afficherHeure,
    dateTimePickerValueWithoutTimezone,
    DateTZ
} from "../../../services/GestionDates";
import {Modale_configurer_cellule} from "./Modales/modale_configurer_cellule";
import {Modale_configurer_conditions} from "./Modales/modale_configurer_conditions";
import {Modale_alertes} from "./Modales/modale_alertes";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {User} from "../../../types/user";
import {clear} from "@testing-library/user-event/dist/clear";
import env_config from "../../../env_config";
import 'dayjs/locale/fr';
import {Loading_button} from "../../../components/loading_button/loading_button";
import {notifications} from "@mantine/notifications";
import Sidebar from "../../../components/sidebar/Sidebar";
import {
    mesureCelluleAffichage,
    setAffichageMesureCelluleState,
    setMobile_sidebar_icon
} from "../../../store/interfaceSlice";
import {addCellule} from "../../../store/cellulesSlice";
import {AxiosResponse} from "axios";
import {Organisation} from "../../../types/organisation";
import {Historique_statut_modale} from "./Modales/historique_statut_modale";
import {estDesactivee} from "../../Admin/Orga_admin/Orga_admin";
import {permissionIsGranted} from "../../../services/permissionsService";
import {Modale_configurer_conditions_rapides} from "./Modales/modale_configurer_conditions_rapides";
import {IconeArrowLeft} from "../../../components/icone/solid_react/arrow-left";
import {IconeArrowRight} from "../../../components/icone/solid_react/arrow-right";
import {IconeDownload} from "../../../components/icone/solid_react/download";
import {IconeCircleInfo} from "../../../components/icone/solid_react/circle-info";
import {IconeGear} from "../../../components/icone/solid_react/gear";
import {IconeListCheck} from "../../../components/icone/solid_react/list-check";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeTrashCan} from "../../../components/icone/solid_react/trash-can";
import {IconeList} from "../../../components/icone/solid_react/list";
import {IconeChartLine} from "../../../components/icone/solid_react/chart-line";
import {IconeFileCsv} from "../../../components/icone/solid_react/file-csv";
import {IconeFilePdf} from "../../../components/icone/solid_react/file-pdf";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS, ORIGIN} from 'react-joyride';
import {IconeHexagon} from "../../../components/icone/solid_react/hexagon";
import {IconeTemperatureThreeQuarters} from "../../../components/icone/solid_react/temperature-three-quarters";
import {IconeLambda} from "../../../components/icone/solid_react/lambda";
import {IconeFlask} from "../../../components/icone/solid_react/flask";
import {IconeRectangleVerticalHistory} from "../../../components/icone/solid_react/rectangle-vertical-history";
import {IconeArrowRotateLeft} from "../../../components/icone/solid_react/arrow-rotate-left";
import {IconeArrowsUpDownLeftRight} from "../../../components/icone/solid_react/arrows-up-down-left-right";
import {IconeArrowUpRight} from "../../../components/icone/solid_react/arrow-up-right";
import {IconeCaretDown} from "../../../components/icone/solid_react/caret-down";
import {Zone} from "../../../types/zone";
import {BarChart, BarChartSeries, LineChart} from "@mantine/charts";
import '@mantine/charts/styles.css';
import {Modale_graphique_export} from "./Modales/modale_graphique_export";
import {Graphiques} from "../../../services/Graphiques";
import {setPasApasEtat} from "../../../store/pasApasSlice";
import {IconeBrain} from "../../../components/icone/solid_react/brain";
import {IconeChartWaterfall} from "../../../components/icone/solid_react/chart-waterfall";
import {Modale_analyses} from "./Modales/modale_analyses";
import {Modale_appliquer_code_promo} from "./Modales/modale_appliquer_code_promo";

export function Mesures() {
    //const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("pen-to-square"))

    let cellule_id = useParams().celluleId;
    const [dateHeureSearch, setDateHeureSearch] = useState<DateValue>();
    const [cellule, setCellule] = useState<Cellule | null>(null);
    const [mesures, setMesures] = useState((
        <Loading_div/>
    ));

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const heure = queryParams.get('dt'); //dateheure


    // const dataFetchedRef = useRef(false);

    const [loading_delete_btn, setLoadingDeleteBtn] = useState(false);
    const navigate = useNavigate();

    const user = useSelector<RootState, User>(state => state.user)

    const [infosSupp, setInfosSupp] = useState<Mesure_infos | null>(null);

    const organisation = useSelector<RootState, Organisation[]>(state => state.organisation.list).filter(o => o.id === cellule?.organisation.id)[0];

    let scrollToRefLast = useRef<HTMLDivElement>(null);
    let scrollToRefSelection = useRef<HTMLDivElement>(null);

    const [userAbougerLeTime, setUserAbougerLeTime] = useState(false);

    const [forcerModaleAlerte, setForcerModaleAlerte] = useState(false);
    const [modaleAlerteIsOpen, setModaleAlerteIsOpen] = useState(false);

    const [tourIntro, setTourIntro] = useState<any>([]);
    const [tourStepIndex, setTourStepIndex] = useState<number>(0);
    const [tourRun, setTourRun] = useState<boolean>(true);

    function changerAffichage(affichage: string) {
        dispatch(setAffichageMesureCelluleState({
            cellule_id: Number(cellule_id),
            affichage: affichage
        }))
    }

    const affichagesDispos = [
        {
            "texte": "4 min précédentes",
            "calc": 4 * 60 * 1000,
            "affichage": "toutes",
            "searchMinutes": true
        },
        {
            "texte": "Heure précédente",
            "calc": 60 * 60 * 1000,
            "affichage": "toutes",
            "searchMinutes": true
        },
        {
            "texte": "4 heures précédentes",
            "calc": 4 * 60 * 60 * 1000,
            "affichage": "toutes",
            "searchMinutes": true
        },
        {
            "texte": "12 heures précédentes",
            "calc": 12 * 60 * 60 * 1000,
            "affichage": "toutes",
            "searchMinutes": true
        },
        {
            "texte": "Jour, vue résumée",
            "calc": 24 * 60 * 60 * 1000,
            "affichage": "resume",
            "searchMinutes": false
        },
        {
            "texte": "Jour, vue complète",
            "calc": 24 * 60 * 60 * 1000,
            "affichage": "toutes",
            "searchMinutes": false
        },
        {
            "texte": "7 jours, vue résumée",
            "calc": 7 * 24 * 60 * 60 * 1000,
            "affichage": "resume",
            "searchMinutes": false
        },
        {
            "texte": "7 jours, vue complète",
            "calc": 7 * 24 * 60 * 60 * 1000,
            "affichage": "toutes",
            "searchMinutes": false
        }
    ]

    let affichageLocalStorage = useSelector<RootState, mesureCelluleAffichage[]>(state => state.interface.mesureCelluleAffichage).filter(m => m.cellule_id + "" === cellule_id)[0];
    const affichageSelection = (affichageLocalStorage && affichagesDispos.filter(a => a.texte === affichageLocalStorage.affichage).length > 0) ? affichageLocalStorage.affichage : "4 heures précédentes";
    useEffect(() => {
            queryCellule();

        },
        [cellule_id]
    );

    function queryCellule()
    {
        if (cellule) setCellule(null);
        // if (dataFetchedRef.current) return;
        // dataFetchedRef.current = true;
        ajax.get('/cellules/' + cellule_id)
            .then(response => {
                let cellu: Cellule = response.data.cellule;
                if (cellu.zone.nom === "base.config.appareils") {

                    if (cellu.nom.includes("capteur.")){
                        navigate("/capteur/" + cellu.nom.replace("capteur.", ""));
                    }
                    else if (cellu.nom.includes("hub.")){
                        navigate("/hubs/?uuid=" + cellu.nom.replace("hub.", ""));
                    }
                    else{
                        alert("Cette cellule n'a pas vocation à être consultée, elle fait partie du fonctionnement interne du système. Vous allez être renvoyé à l'accueil.")
                        navigate("/");
                    }
                } else {
                    setCellule(cellu);
                    dispatch(setPasApasEtat("page_cellule_loaded"))
                }
            }).catch(error => {
            console.log(error);
        });
    }


    useEffect(() => {
        if (!cellule) return;
        let datesearch = new DateTZ();
        if (cellule.last_mesures) {
            datesearch = new DateTZ(cellule.last_mesures.enregistrement)
            console.log("LAST " + cellule?.last_mesures.enregistrement)
        }
        if (heure) {
            datesearch = new DateTZ(heure)
        }
        datesearch.setMinutes(0);
        datesearch.setSeconds(0);
        datesearch.setMilliseconds(0);
        datesearch.addHours(1);
        setDateHeureSearch(datesearch);


        dispatch(addCellule(cellule));

    }, [cellule])

    useEffect(() => {
        setMesures(<Loading_div/>);

        let refresh_rate = env_config.refresh_rate;
        if (cellule?.statut_actuel && !cellule.last_mesures)
        {
            // alert("Mode installation activé");
            refresh_rate = env_config.refresh_rate_mode_installation;
        }

        queryMesures();
        const interval_clear = setInterval(() => {
            queryMesures();
        }, refresh_rate);

        return () => {
            clearInterval(interval_clear);
        }
    }, [dateHeureSearch, affichageSelection])

    useEffect(() => {
            //scroll to la selection
            setTimeout(() => {
                if (scrollToRefSelection.current) {
                    scrollToRefSelection.current.scrollIntoView({
                        behavior: 'smooth', // Optional: Add smooth scrolling effect
                        block: 'center',     // Optional: Scroll to the top of the element
                        inline: "center"
                    });
                } else {
                    scrollToLast();
                }
            }, 700)


        }, [mesures]
    );


    function scrollToLast() {


        if (!userAbougerLeTime) {
            if (scrollToRefLast.current && !scrollToRefSelection.current) {
                scrollToRefLast.current.scrollIntoView({
                    behavior: 'smooth', // Optional: Add smooth scrolling effect
                    block: 'start',     // Optional: Scroll to the top of the element
                    // inline: "start"
                });
            }
        }
    }

    function navigateToMesure(mesure: Mesure) {
        changerAffichage("Jour, vue complète");
        setDateHeureSearch(new DateTZ(mesure.enregistrement));
        navigate("/cellule/" + cellule?.id + "?dt=" + mesure.enregistrement);
        setUserAbougerLeTime(true);
    }


    function queryMesures() {
        if (!dateHeureSearch) return;

        let maintenant = new DateTZ();
        console.log(maintenant.getHours() + ":" + maintenant.getMinutes() + " | Refresh des mesures...")

        let debut = new DateTZ(dateHeureSearch.getTime()); // -4 heures

        let fin = new DateTZ(dateHeureSearch.getTime());


        affichagesDispos.map(affichage => {
            if (affichage.texte === affichageSelection) {
                let debut_tmp = new DateTZ(dateHeureSearch.getTime());
                let fin_tmp = new DateTZ(dateHeureSearch.getTime());
                if (!affichage.searchMinutes) {
                    debut = new DateTZ(debut_tmp.setHours(0, 0, 0) - affichage.calc + (3600 * 24 * 1000));
                    fin = new DateTZ(fin_tmp.setHours(0, 0, 0) + (3600 * 24 * 1000));
                } else debut = new DateTZ(dateHeureSearch.getTime() - affichage.calc);
            }
        })


        ajax.get('/cellules/' + cellule_id + '/mesures' + "?debut=" + debut.toISOString() + "&fin=" + fin.toISOString())
            .then(response => {
                let last_mesure: Last_mesure = response.data.last_mesure;
                if (last_mesure === null) {
                    setMesures(<div className={"notice"} style={{margin: "4rem auto"}}>
                        Cette cellule n'a pas encore reçu de mesure.
                    </div>)
                } else {
                    if (response.data.mesures && response.data.mesures.length > 0) {
                        let affichage = affichagesDispos.filter(affichage => affichage.texte === affichageSelection)[0];
                        if (affichage) {
                            if (affichage.affichage === "toutes") {
                                setMesures((response.data.mesures.map((mesure: Mesure, index: number) => {
                                    let _heure = new DateTZ(mesure.enregistrement);
                                    if (!cellule) {
                                        return;
                                    }
                                    let dateSelected = new DateTZ(heure ? heure : "2000-01-01 00:00:00")
                                    return <Carte_mesure
                                        titre={afficherHeure(_heure.getHours(), _heure.getMinutes(), _heure.getSeconds())}
                                        texte={mesure.mesure}
                                        sonde={cellule.sonde_type}
                                        handleClick={() => {
                                            getInformationsSupplementaire(mesure)
                                        }}
                                        key={mesure.enregistrement}
                                        date_mesure={mesure.enregistrement}
                                        conditions={cellule.statut_promotionnel?.statut.can_use_alertes ? cellule.conditions : []}
                                        etalonnages={cellule.etalonnages}
                                        selected={_heure.getTime() <= dateSelected.getTime()
                                            && _heure.getTime() > dateSelected.getTime() - 20000} //20sec de marge entre la mesure et la detection
                                        ref={(_heure.getTime() <= dateSelected.getTime()
                                            && _heure.getTime() > dateSelected.getTime() - 20000)
                                            ? scrollToRefSelection
                                            : (index === response.data.mesures.length - 1 ? scrollToRefLast : null)}
                                    />
                                })))
                            }


                            if (affichage.affichage === "resume" && cellule) {
                                let graph = new Graphiques(cellule, response.data.mesures)

                                if (!graph.mesures || (graph.mesures && graph.mesures.length === 0)) {
                                    setMesures(<p className={"inline-tag"}>Aucune mesure disponible pour analyse.</p>)
                                }
                                else{
                                    setMesures(
                                        <>
                                            {cellule.sonde_type?.type_de_donnee === "bool" ? graph.cartesLigneCS() : graph.cartesDeuxLigne()}
                                            {graph.lineGraph()}
                                            {cellule.sonde_type?.type_de_donnee !== "bool" && graph.barGraph()}
                                            {cellule.conditions.find(c => c.slug < 5) && (
                                                <>
                                                    <h1><br/>Excursions : </h1>
                                                    {graph.excursionsCartes()}
                                                    {graph.excursionsTable()}
                                                    {graph.barGraphExcursions()}
                                                </>
                                            )}
                                        </>
                                    )
                                }
                            }
                        }


                    } else { //aucune mesure

                        if (last_mesure && response.data.mesures === null)
                        {
                            let mesure:Mesure = {
                                cellule_id: Number(cellule_id),
                                enregistrement: last_mesure.enregistrement,
                                mesure: last_mesure.mesure,
                                statut: null,
                                timestamp: 0
                            }
                            let _heure = new DateTZ(mesure.enregistrement);
                            if (!cellule) {
                                return;
                            }
                            setMesures(
                                <>
                                    <div className={"notice grey"} style={{margin: "1rem auto"}}>
                                        L'abonnement choisi ne vous permet de ne voir que la dernière mesure :
                                    </div>
                                    <p style={{margin: "auto", width: '100%'}}>Dernière mesure reçue le {afficherDateTime(_heure)} : </p>
                                    <Carte_mesure
                                        titre={afficherHeure(_heure.getHours(), _heure.getMinutes(), _heure.getSeconds())}
                                        texte={mesure.mesure}
                                        sonde={cellule.sonde_type}
                                        handleClick={() => {
                                            getInformationsSupplementaire(mesure)
                                        }}
                                        key={mesure.enregistrement}
                                        date_mesure={mesure.enregistrement}
                                        conditions={cellule.statut_promotionnel?.statut.can_use_alertes ? cellule.conditions : []}
                                        etalonnages={cellule.etalonnages}
                                    />
                                </>
                            )
                        }
                        else{
                            setMesures(<div className={"notice"} style={{margin: "4rem auto"}}>Aucune mesure pour cette
                                période.</div>)
                        }


                    }

                    if (!userAbougerLeTime) {
                        let last_rec = new DateTZ(last_mesure.enregistrement);
                        let _date_search_p1h = new DateTZ(dateHeureSearch.getTime());
                        // alert(_date_search_p1h.getHours())
                        if (last_rec.getTime() < _date_search_p1h.setMinutes(_date_search_p1h.getMinutes() + 60)
                            && last_rec.getTime() > dateHeureSearch.getTime()
                        ) {
                            // _date_search_p1h.setMinutes(dateHeureSearch.getMinutes() + 60)
                            setDateHeureSearch(_date_search_p1h);
                        }
                    }

                }

            }).catch(error => {
            console.log(error);
        });
    }

    function deplacerDansZone(zone: Zone) {

        if (cellule) {
            ajax.put("/cellules/" + cellule.id, {
                "zone_id": zone.id
            }).then(response => {
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Cellule déplacée",
                    message: 'Dans la zone ' + zone.nom,
                    icon: <IconeCheck/>,
                });
                let _cell: Cellule = {...cellule}
                _cell.zone = zone;
                setCellule(_cell);

            }).catch(error => {
            })
        } else alert("Une erreur est survenue.")
    }


    const [bouton_tour_creer_cellule_loading, setBouton_tour_creer_cellule_loading] = useState(false);

    // Utilisé uniquement pour le tour d'intro.
    function handleAddCellule(zone_id: number) {
        setBouton_tour_creer_cellule_loading(true);
        ajax.post('/zones/' + zone_id + "/cellules", {
            "nom": "Nouvelle cellule"
        })
            .then(response => {
                setBouton_tour_creer_cellule_loading(false);

                navigate("/cellule/" + response.data.cellule.id + "?forcerconfig=true");
                // navigate("/");
                // setTimeout(() => {
                // }, 500)

            })
            .catch(error => {
                console.log(error);
            });

    }

    useEffect(() => {
        let tour: any = [
            {
                target: '.sidebar',
                content:
                    <div style={{display: "flex", flexFlow: "column nowrap", gap: "0.5rem"}}>
                        <h3>Vous avez fini la configuration d'une cellule!</h3>
                        <p>C'est ici que vous trouverez toutes les mesures que votre appareil va prendre.</p>
                        <p>Un bouton vers cette page est apparu sur l'écran d'accueil.</p>
                        <p style={{fontWeight: "bold"}}>Voici un tour rapide (moins de 1 minute) pour vous présenter
                            les fonctionnalités de cette page.</p>
                        <button onClick={() => setTourStepIndex(1)}>Démarrer le tour <IconeArrowRight/></button>
                        {cellule && (
                            <Loading_button is_loading={bouton_tour_creer_cellule_loading}
                                            onClick={() => handleAddCellule(cellule.zone.id)}>Je veux d'abord configurer
                                une autre cellule <IconeArrowRotateLeft/></Loading_button>
                        )}
                    </div>,
                placement: 'center',
                spotlightClicks: false,
                hideFooter: true
            },
            {
                target: window.innerWidth <= 1200 ? ".mantine-Burger-root" : 'nav',
                content:
                    <>
                        <p>Retrouvez les différentes pages disponibles depuis le menu de navigation </p>
                    </>,
                placement: "right",
                // disableScrolling: true
                spotlightClicks: false,
            },
            {
                target: '.content > h2',
                content:
                    <>
                        <p>Un lien rapide pour revenir sur la page de votre organisation</p>
                        <p style={{fontWeight: "bold"}}>Cliquez ici pour configurer d'autres appareils ou cellules</p>
                    </>,
                placement: "bottom",
                // disableScrolling: true
                spotlightClicks: true,
            },
            {
                target: '.mesures_nav',
                content: "Ici, sélectionnez la date de vos prises de mesures ainsi que votre périodicité.",
                spotlightClicks: true,
            },
        ];
        if (window.innerWidth <= 1200) {
            tour = [...tour, {
                target: '.mobile_trigger_notifications',
                content: "Appuyez sur ce bouton pour voir toutes les options disponibles sur les pages sur lesquelles vous vous trouvez. Appuyez dessus pour continuer le tour.",
                disableScrolling: true,
                hideFooter: true,
                placement: 'bottom',
                spotlightClicks: true,
            }, {
                target: 'body',
                content: "Ce menu est présent sur la plupart des pages, offrant des options vis à vis du contenu de la page.",
                disableScrolling: true,
                placement: 'center',
            },
                {
                    target: '.sidebar',
                    content: "Ici, dans la page cellule, vous vous trouverez les actions disponibles pour la cellule et les mesures.",
                    placement: window.innerWidth <= 1200 ? "center" : "left",
                    spotlightClicks: true,
                    // disableScrolling: true
                },]
        } else {
            tour = [...tour,
                {
                    target: '.sidebar',
                    content: "Ici, vous vous trouverez les actions disponibles pour la cellule et les mesures.",
                    placement: window.innerWidth <= 1200 ? "center" : "left",
                    spotlightClicks: true,
                    // disableScrolling: true
                },
            ]
        }


        setTourIntro(tour)
    }, [cellule]);


    const interfaceMobileSidebarShow = useSelector<RootState, boolean>(state => state.interface.mobile_sidebar_show);
    useEffect(() => {
        if (interfaceMobileSidebarShow && tourStepIndex === 4) {
            setTimeout(() => {
                setTourStepIndex(5);
            }, 700)

        }
    }, [interfaceMobileSidebarShow]);
    const handleJoyrideCallback = (data: CallBackProps) => {
        const {action, index, origin, status, type} = data;

        if (action === ACTIONS.CLOSE) {
            // do something
            setTourRun(false);
        }

        // @ts-ignore
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour

            setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

        } else { // @ts-ignore
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                // You need to set our running state to false, so we can restart if we click start again.
                setTourRun(false);
            }
        }

        // console.groupCollapsed(type);
        // console.log(data); //eslint-disable-line no-console
        // console.groupEnd();
    };


    if (!cellule) {
        return (
            <div className={"app"}>
                <Navbar/>
                <div className={"content page_mesure"}>
                    <p>&nbsp;</p>
                    <Loading_div customTexte={"Chargement cellule " + cellule_id + "..."}/>
                </div>

                <Sidebar>
                    <Bandeau_user/>
                    <Loading_div/>
                </Sidebar>
            </div>
        )
    }

    function getInformationsSupplementaire(mesure: Mesure) {
        setInfosSupp({
            id: 0, reception: "", message: null, mesure: mesure
        })
        ajax.get("/mesures/" + cellule_id + "/" + mesure.timestamp).then((response) => {
            let _infos: Mesure_infos = response.data.infos;
            _infos.mesure = mesure;
            setInfosSupp(_infos)
        }).catch(e => {
            setInfosSupp(null)
        })
    }

    function base64ToHex(base64String: string) {
        // Convert base64 to binary string
        const binaryString = atob(base64String);

        // Convert binary to hex
        let hexString = '';
        for (let i = 0; i < binaryString.length; i++) {
            const hex = binaryString.charCodeAt(i).toString(16);
            hexString += (hex.length === 2 ? hex : '0' + hex);
        }

        return hexString.toUpperCase();
    }


    let affichage_actuel = affichagesDispos.filter(affichage => affichage.texte === affichageSelection)[0];

    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content page_mesure"}>
                <h2>
                    <Link to={"/organisation/" + cellule.organisation.id}>
                        <strong>{cellule.organisation.nom}</strong> </Link>
                    <p> / </p>
                    <Link to={"/organisation/" + cellule.organisation.id + "?zone=" + cellule.zone.id}>
                        {cellule.zone.nom}</Link>
                </h2>
                <h1>{cellule.nom}</h1>
                <div className={"mesures_nav"}>
                    <div className={"options hide_on_tv"}>

                    </div>
                    <div className={"options"}>
                        <button onClick={() => {

                            setUserAbougerLeTime(true);
                            affichagesDispos.map(affichage => {
                                if (!dateHeureSearch) {
                                    alert("Date et heure non renseignées.");
                                    return "";
                                }
                                if (affichage.texte === affichageSelection) {
                                    setDateHeureSearch(new DateTZ(dateHeureSearch.getTime() - affichage.calc))
                                }
                                return "";
                            })
                        }
                        }>
                            <IconeArrowLeft/> <p className={"hide_on_tv"}> Précedent</p>
                        </button>
                        {affichage_actuel.searchMinutes
                            ? (
                                <DateTimePicker
                                    placeholder="Jour et heure"
                                    variant="unstyled"
                                    locale="fr"
                                    value={dateTimePickerValueWithoutTimezone(dateHeureSearch)}
                                    onChange={e => {
                                        setUserAbougerLeTime(true);
                                        if (user.timezone !== null) {
                                            let new_date = new DateTZ(e ? e.getTime() - (e.getTimezoneOffset() * 60000) : "");
                                            setDateHeureSearch(new_date)
                                        } else {
                                            setDateHeureSearch(e)
                                        }
                                    }}
                                    popoverProps={{
                                        position: "bottom"
                                    }
                                    }
                                    className={"match_height"}
                                />
                            ) : (
                                <DateInput
                                    locale="fr"
                                    valueFormat="DD/MM/YYYY"
                                    value={dateHeureSearch}
                                    onChange={e => {
                                        setUserAbougerLeTime(true);
                                        setDateHeureSearch(e)
                                    }
                                    }
                                    popoverProps={{
                                        position: "bottom"
                                    }
                                    }
                                    className={"match_height"}
                                />
                            )}

                        <button onClick={() => {

                            setUserAbougerLeTime(true);
                            affichagesDispos.map(affichage => {
                                if (!dateHeureSearch) {
                                    alert("Date et heure non renseignées.");
                                    return "";
                                }
                                if (affichage.texte === affichageSelection) {
                                    setDateHeureSearch(new DateTZ(dateHeureSearch.getTime() + affichage.calc))
                                }
                                return "";
                            })
                        }
                        }>
                            <p className={"hide_on_tv"}>Suivant</p> <IconeArrowRight/>
                        </button>
                    </div>
                    <div className={"options"}>
                        <NativeSelect value={"" + affichageSelection}
                                      onChange={(event) => {
                                          setUserAbougerLeTime(true);
                                          changerAffichage(
                                              event.currentTarget.value.replace("", "")
                                          )
                                      }}
                                      variant="unstyled"
                                      data={affichagesDispos.map(affichage => {
                                          return "" + affichage.texte
                                      })}
                                      className={"match_height select_margin_right"}
                        />
                    </div>
                </div>


                <div className={"mesures_historique" + (affichage_actuel.affichage === "resume" ? " vue-resume" : "")}>
                    {mesures}
                </div>

                <Modal opened={infosSupp !== null} onClose={() => setInfosSupp(null)}
                       title="Informations supplémentaires">
                    {infosSupp && (
                        <>
                            {infosSupp.id > 0 || infosSupp.id === null ? (
                                <>
                                    {infosSupp.id > 0 && (
                                        <p className={"inline-tag"}>Message #{infosSupp.id}</p>

                                    )}
                                    <Grid>
                                        <Grid.Col span={"auto"}><p>l'enregistreur indique avoir pris cette mesure à
                                            : </p>
                                        </Grid.Col>
                                        <Grid.Col span={"content"}>
                                            <Tooltip
                                                label={afficherDateTimeSecondesUTC(infosSupp.mesure.enregistrement) + " UTC"}
                                                position={"bottom"}>
                                                <p>
                                                    {afficherDateTimeSecondes(new DateTZ(infosSupp.mesure.enregistrement))}
                                                </p>
                                            </Tooltip>
                                        </Grid.Col>
                                    </Grid>
                                    {infosSupp.id > 0 && (
                                        <Grid>
                                            <Grid.Col span={"auto"}><p>Message reçu par le système le : </p></Grid.Col>
                                            <Grid.Col span={"content"}>
                                                <Tooltip
                                                    label={afficherDateTimeSecondesUTC(infosSupp.reception) + " UTC"}
                                                    position={"bottom"}>
                                                    <p>{afficherDateTimeSecondes(new DateTZ(infosSupp.reception))}</p>
                                                </Tooltip>
                                            </Grid.Col>
                                        </Grid>
                                    )}

                                    {cellule.etalonnages.filter(etal => (
                                        new DateTZ(infosSupp?.mesure.enregistrement) >= new DateTZ(etal.debut)
                                        && new DateTZ(infosSupp?.mesure.enregistrement) < new DateTZ(etal.fin ? etal.fin : "2099-01-01T14:46:38Z")
                                    )).map(etal => (
                                        <>
                                            <Grid>
                                                <Grid.Col span={"auto"}><p>Etalonnage appliqué sur cette mesure : </p>
                                                </Grid.Col>
                                                <Grid.Col span={"content"}>
                                                    <p>{etal.coef_c === 0 ? "Aucun" : (etal.coef_b === 0 ? "1 point (" + (etal.coef_c > 0 ? "+" : "") + etal.coef_c + cellule?.sonde_type?.affichage_suffix+")" : (etal.coef_a === 0 ? "Linéaire" : "Polynomial")) }</p>
                                                </Grid.Col>
                                            </Grid>
                                            {etal.commentaire !== "" && (
                                                <Grid>
                                                    <Grid.Col span={"auto"}><p>Commentaire d'étalonnage : </p>
                                                    </Grid.Col>
                                                    <Grid.Col span={"content"}><p>"{etal.commentaire}"</p></Grid.Col>
                                                </Grid>
                                            )}
                                            {etal.token && (
                                                <Grid>
                                                    <Grid.Col span={"auto"}><p></p></Grid.Col>
                                                    <Grid.Col span={"content"}>
                                                        <button className={"sec"} onClick={() => {
                                                            window.open("https://api.freshtime.fr/app/dl_fichier/" + etal.token);
                                                        }}><IconeDownload/>Rapport d'étalonnage
                                                        </button>
                                                    </Grid.Col>
                                                </Grid>
                                            )}
                                            {etal.debut && (
                                                <Grid>
                                                    <Grid.Col span={"auto"}>
                                                        <p></p>
                                                    </Grid.Col>
                                                    {etal.fin ? (
                                                        <Grid.Col span={"content"}><p>Etalonnage actif
                                                            du {afficherDateTime(etal.debut)} au {afficherDateTime(etal.fin)} </p>
                                                        </Grid.Col>

                                                    ) : (
                                                        <Grid.Col span={"content"}><p>Etalonnage actif depuis
                                                            le {afficherDateTime(etal.debut)} </p></Grid.Col>

                                                    )}
                                                </Grid>
                                            )}

                                        </>
                                    ))}

                                    {infosSupp.mesure.statut && (
                                        <>
                                            <Grid>
                                                <Grid.Col span={"auto"}><p>Abonnement : </p>
                                                </Grid.Col>
                                                <Grid.Col span={"content"}>
                                                    <p>#{infosSupp.mesure.statut.id} {infosSupp.mesure.statut.statut.nom} ({infosSupp.mesure.statut.statut.prix_achat}€/mois)</p>
                                                </Grid.Col>
                                            </Grid>
                                            <Grid>
                                                <Grid.Col span={"auto"}><p>Abonnement période : </p>
                                                </Grid.Col>
                                                <Grid.Col span={"content"}>
                                                    <p>Du {afficherDateTime(infosSupp.mesure.statut.debut)} à {afficherDateTime(infosSupp.mesure.statut.fin)}</p>
                                                </Grid.Col>
                                            </Grid>
                                        </>
                                    )}


                                    {infosSupp.message && (
                                        <>
                                            <Code block>
                                                {JSON.stringify(infosSupp.message, null, 2)}
                                            </Code>
                                            {infosSupp.message.data && typeof infosSupp.message.data === "string" && (
                                                <>
                                                    <p>Bloc data en hexadecimal : </p>
                                                    <Code block>
                                                        {base64ToHex(infosSupp.message.data)}
                                                        <br/>
                                                        En tete : {base64ToHex(infosSupp.message.data).substring(0, 2)}
                                                        <br/>
                                                        Config number
                                                        : {base64ToHex(infosSupp.message.data).substring(2, 4)}
                                                        <br/>
                                                        Datetime DMY HMS
                                                        : {base64ToHex(infosSupp.message.data).substring(4, 16)}
                                                        <br/>
                                                        Battery
                                                        : {base64ToHex(infosSupp.message.data).substring(16, 20)}
                                                        <br/>
                                                        Temperatures
                                                        :
                                                        {base64ToHex(infosSupp.message.data).substring(20, 28) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(28, 36) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(36, 44) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(44, 52)}
                                                        <br/>
                                                        Contacts
                                                        :
                                                        {base64ToHex(infosSupp.message.data).substring(52, 54) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(54, 56) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(56, 58) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(58, 60)}
                                                        <br/>
                                                        Error Code
                                                        : {base64ToHex(infosSupp.message.data).substring(60, 62)}
                                                        <br/>
                                                        Numerics
                                                        :
                                                        {base64ToHex(infosSupp.message.data).substring(62, 70) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(70, 78) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(78, 86) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(86, 90) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(90, 94) + " "}
                                                        {base64ToHex(infosSupp.message.data).substring(94, 98)}
                                                        <br/>
                                                    </Code>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Loading_div/>
                            )}
                        </>
                    )}

                </Modal>

            </div>


            <Sidebar>
                <Bandeau_user/>
                <Tabs defaultValue="Cellule">
                    <Tabs.List>
                        <Tabs.Tab value="Cellule" leftSection={<IconeRectangleVerticalHistory/>}>Cellule</Tabs.Tab>
                        <Tabs.Tab value="Donnees" leftSection={<IconeChartLine/>}>Données</Tabs.Tab>
                        {user.roles.includes("ROLE_ADMIN") && (
                            <Tabs.Tab value="Admin" leftSection={<IconeFlask/>}>Admin</Tabs.Tab>
                        )}

                    </Tabs.List>

                    <Tabs.Panel value="Cellule" pt="xs">
                        <div className={"actions"}>
                            <h3>Actions disponibles pour la cellule </h3>


                            <>
                                {estDesactivee(organisation) && (
                                    <p className={"notice"}>L'organisation est désactivée. La cellule ne peut pas être
                                        configurée.</p>
                                )}
                                {!estDesactivee(organisation) && (
                                    <>
                                        {permissionIsGranted(organisation.id, "Administrer_cellule") && (
                                            <div className={"carte_action"} id="carte_action_config_cellule">
                                                <p className={"titre"}>Configurer la cellule</p>
                                                <p className={"infos"}>Indiquer la source de données de cette cellule et
                                                    choisissez son abonnement</p>
                                                <div className={"tags"}>
                                                    <div className={"en-ligne"}>
                                                        Enregistreur :
                                                        <p className={"tag"} id={"lien_vers_capteur"}>
                                                            {cellule.extension ? (<Link
                                                                to={"/capteur/" + cellule.extension.capteur.uuid + "?cellule=" + cellule_id}>{cellule.extension.capteur.nom + " / " + cellule.extension.nom}</Link>) : "Aucun"}
                                                        </p>
                                                        {/*cellule.extension ? cellule.extension.sonde?.nom : "Aucun"*/}
                                                    </div>
                                                    <div className={"en-ligne"}>
                                                        Abonnement : <p
                                                        className={"tag"}>{cellule.statut_actuel ? cellule.statut_actuel.statut.nom : "Aucun"}</p>
                                                    </div>
                                                    {cellule.statut_promotionnel && cellule.statut_promotionnel.id !== cellule.statut_actuel?.id && (
                                                        <div className={"en-ligne"} style={{width: "100%"}}>
                                                            Abonnement spécial appliquée :
                                                            <p className={"tag green"} style={{width: "100%"}}>
                                                                <span className={"en-ligne"}><IconeCheck /> {cellule.statut_promotionnel.statut.nom}</span>

                                                            Appliqué jusqu'au {afficherDate(cellule.statut_promotionnel.fin)}
                                                        </p>
                                                        </div>
                                                    )}



                                                </div>
                                                <Modale_configurer_cellule cellule={cellule}
                                                                           setCellule={setCellule}
                                                                           forcer={(cellule.nom === "Nouvelle cellule" && !cellule.extension) || (queryParams.get('forcerconfig') === "true")}
                                                                           extension_url_param={queryParams.get('extension')}
                                                                           capteur_url_param={queryParams.get('capteur')}
                                                                           ouvrirModaleAlertes={() => {
                                                                               setForcerModaleAlerte(true)
                                                                               setTimeout(() => {
                                                                                   setForcerModaleAlerte(false)
                                                                               }, 500)
                                                                           }}
                                                >
                                                    <>
                                                        <IconeGear/>{queryParams.get('forcerconfig') === "true" ? "Reconfigurer" : "Configurer"} cette
                                                        cellule
                                                    </>
                                                </Modale_configurer_cellule>
                                                <Loading_button className={"sec"}
                                                                is_loading={bouton_tour_creer_cellule_loading}
                                                                onClick={() => handleAddCellule(cellule.zone.id)}>
                                                    <IconeArrowRotateLeft/>Créer une autre cellule
                                                </Loading_button>
                                                <Modale_appliquer_code_promo cellule={cellule} triggerRefresh={queryCellule} />
                                            </div>

                                        )}


                                        {(!cellule.config_rapide || (cellule.config_rapide && cellule.extension))
                                            && cellule.statut_promotionnel?.statut.can_use_alertes && (
                                                <div className={"carte_action"} id="carte_action_mesures_anormales">
                                                    <p className={"titre"}>
                                                        {permissionIsGranted(organisation.id, "Administrer_organisation") ? "Définir les mesures anormales" : "Mesures anormales"} </p>
                                                    <p className={"infos"}>Les anomalies indiquent quelles sont les
                                                        mesures
                                                        anormales, ce qui permet de vous informer par une Alerte lorsque
                                                        necessaire.</p>
                                                    <div className={"tags"}>
                                                        {cellule.conditions.map(condition => {
                                                            if (condition.slug == 2 || condition.slug == 3) {
                                                                return (
                                                                    <p className={"tag"} key={condition.id}>
                                                                        <strong>{condition.nom}</strong>
                                                                        {cellule?.extension?.sonde?.affichage_prefix}
                                                                        {condition.slug == 2 ? cellule?.extension?.sonde?.affichage_faux : cellule?.extension?.sonde?.affichage_vrai}
                                                                        {cellule?.extension?.sonde?.affichage_suffix}
                                                                    </p>)
                                                            }
                                                            if ([8, 9, 11].includes(condition.slug)) {
                                                                return <></>
                                                                return (
                                                                    <p className={"tag"} key={condition.id}>
                                                                        <strong>{condition.nom}</strong>
                                                                    </p>)
                                                            }
                                                            return (
                                                                <p className={"tag" + (condition.statut_validation > 0 ? " red" : "")}
                                                                   key={condition.id}>
                                                                    <strong>{condition.nom}</strong>
                                                                    {cellule?.extension?.sonde?.affichage_prefix}
                                                                    {condition.valeur_a_comparer}
                                                                    {cellule?.extension?.sonde?.affichage_suffix}
                                                                </p>)
                                                        })}
                                                    </div>

                                                    {permissionIsGranted(organisation.id, "Administrer_anomalies") && (
                                                        <>
                                                            {!cellule.config_rapide ? (
                                                                <Modale_configurer_conditions cellule={cellule}
                                                                                              setCellule={setCellule}/>
                                                            ) : (
                                                                <Modale_configurer_conditions_rapides cellule={cellule}
                                                                                                      setCellule={setCellule}
                                                                                                      forcer={false}
                                                                                                      extension={queryParams.get('extension')}
                                                                                                      etape={3}>
                                                                    <>
                                                                        <IconeListCheck/> Anomalies
                                                                    </>
                                                                </Modale_configurer_conditions_rapides>
                                                            )}
                                                        </>
                                                    )}


                                                </div>
                                            )}


                                    </>
                                )}


                            </>


                            {!estDesactivee(organisation) && organisation.permissions?.includes("Gestion_alertes_perso")
                                && cellule.statut_promotionnel?.statut.can_use_alertes && (
                                    <div className={"carte_action"} id="carte_action_alertes">
                                        <p className={"titre"}>Configurer vos alertes</p>
                                        <p className={"infos"}>Les alertes sont envoyés quand une anomalie est
                                            détectée</p>
                                        <div className={"tags"}>
                                            {cellule.conditions.map(condition => {
                                                if (condition.alertes) {
                                                    return condition.alertes.map(alerte => {
                                                        if (alerte.utilisateur.id !== user.id) return null
                                                        if (alerte.canaux.length === 0) return null
                                                        return (
                                                            <div className={"tag"} key={"condition-" + condition.id}>
                                                                <strong>{condition.nom}</strong>
                                                                <div className={"items"}>
                                                                    {alerte.canaux.map((canal, index) => {
                                                                        return (
                                                                            <span className={'item'}
                                                                                  key={index}>{canal}</span>)
                                                                    })
                                                                    }
                                                                </div>

                                                            </div>
                                                        )
                                                    })

                                                }
                                                return ""
                                            })}
                                        </div>

                                        <Modale_alertes cellule={cellule} setCellule={setCellule} user={user}
                                                        forcer={forcerModaleAlerte}
                                                        setIsModalOpen={setModaleAlerteIsOpen}/>

                                    </div>
                                )}


                            {!estDesactivee(organisation) && !cellule.last_mesures && !cellule.extension && (
                                <div className={"carte_action"}>
                                    <p className={"titre"}>Supprimer la cellule</p>
                                    <p className={"infos"}>Seules les cellules qui ne sont pas activée et qui n'ont
                                        aucune
                                        mesures peuvent être supprimée.</p>
                                    <Loading_button is_loading={loading_delete_btn} onClick={() => {
                                        setLoadingDeleteBtn(true)
                                        ajax.delete("/cellules/" + cellule_id).then(response => {
                                            navigate('/organisation/' + cellule?.organisation.id)
                                            notifications.show({
                                                withCloseButton: true,
                                                autoClose: env_config.notifications_duree_affichage,
                                                color: 'orange',
                                                title: "Supprimée",
                                                message: 'La cellule à bien été supprimée.',
                                                icon: <IconeCheck/>,
                                            });
                                            setLoadingDeleteBtn(false)
                                        }).catch(error => {
                                            setLoadingDeleteBtn(false)
                                        });
                                    }
                                    } className={"sec rouge"}><IconeTrashCan/>Supprimer</Loading_button>
                                </div>
                            )}


                            {organisation.zones && organisation.zones.length > 2 && (
                                <div className={"carte_action"}>
                                    <p className={"titre"}>Déplacer la cellule</p>
                                    <p className={"infos"}>Choisir la nouvelle zone dans laquelle placer la cellule</p>

                                    <Menu shadow="md" width={200}>
                                        <Menu.Target>
                                            <button className={"sec"}><IconeArrowsUpDownLeftRight/> Déplacer vers
                                                ... <IconeCaretDown/></button>
                                        </Menu.Target>

                                        <Menu.Dropdown>
                                            <Menu.Label>Vers la zone...</Menu.Label>
                                            {organisation.zones?.filter(z => z.nom !== "base.config.appareils").map(zone => {
                                                return (
                                                    <Menu.Item onClick={() =>
                                                        deplacerDansZone(zone)
                                                    }>
                                                        <div className={"en-ligne"}>
                                                            {zone.id === cellule?.zone.id && <IconeCheck/>}
                                                            {zone.nom}
                                                        </div>

                                                    </Menu.Item>
                                                )
                                            })}
                                        </Menu.Dropdown>
                                    </Menu>

                                </div>
                            )}


                            <div className={"carte_action"}>
                                <p className={"titre"}>Historique de la cellule</p>
                                <p className={"infos"}>Voir toutes les actions réalisées et modifications apportées à
                                    cette cellule.
                                </p>

                                <button className={"sec"} onClick={() => {
                                    navigate("/cellule/" + cellule.id + "/historique");
                                }}><IconeList/>Historique
                                </button>

                            </div>

                            {!estDesactivee(organisation) && organisation.permissions?.includes("Administrer_appareils")
                                && (
                                    <div className={"carte_action"}>
                                        <p className={"titre"}>Analyses intelligentes</p>
                                        <p className={"infos"}>Votre abonnement vous permet d'activer des analyses automatiques de votre cellules,
                                            pour par exemple détecter un problème avec le compresseur de votre chambre froide.
                                        </p>

                                        <Modale_analyses cellule={cellule} setCellule={e => {
                                            setCellule(e);
                                            if (e?.conditions.find(c => c.id === -6))
                                            {
                                                setForcerModaleAlerte(true)
                                                setTimeout(() => {
                                                    setForcerModaleAlerte(false)
                                                }, 500)
                                            }

                                        }} />

                                    </div>
                                )}


                        </div>
                    </Tabs.Panel>

                    <Tabs.Panel value="Donnees" pt="xs">
                        <div className={"actions"}>

                            <h3>Actions disponibles pour les données</h3>


                            {cellule.etalonnages.filter(e => e.fin === null).length > 0 && (
                                <div className={"carte_action"}>
                                    <p className={"titre"}>Etalonnage</p>
                                    <p className={"infos"}>Un étalonnage est appliqué sur l'enregistreur lié à cette
                                        cellule depuis
                                        le {afficherDateTime(cellule.etalonnages.filter(e => e.fin === null)[0].debut)}</p>

                                    {cellule.etalonnages.filter(e => e.fin === null).map(e => (
                                        <>
                                            <p className={"infos"}>Etalonnage actuel
                                                : {e.coef_c === 0 ? "Aucun" :
                                                    (e.coef_b === 0 ? "1 point (" + (e.coef_c > 0 ? "+" : "") + e.coef_c + cellule?.sonde_type?.affichage_suffix+")" :
                                                        (e.coef_a === 0 ? "Linéaire" : "Polynomial")) }</p>
                                            {e.commentaire !== "" && (
                                                <>
                                                    <p className={"infos"}>Commentaire d'étalonnage :
                                                        "{e.commentaire}"</p>
                                                </>
                                            )}
                                            {e.token && (
                                                <>
                                                    <button className={"sec"} onClick={() => {
                                                        window.open("https://api.freshtime.fr/app/dl_fichier/" + e.token);
                                                    }}><IconeDownload/>Rapport
                                                    </button>
                                                </>
                                            )}

                                        </>
                                    ))}
                                    {cellule.etalonnages.length > 1 && (
                                        <p className={"infos"}>Vous pouvez retrouver les étalonnage précédents depuis
                                            l'historique de la cellule.</p>
                                    )}
                                </div>
                            )}


                            {estDesactivee(organisation) ? (
                                <p className={"notice orange"}>Aucune action possible car l'organisation est
                                    désactivée</p>
                            ) : (
                                <>
                                    <div className={"carte_action"}>
                                        <p className={"titre"}>Analyse avancée / Graphiques</p>
                                        <p className={"infos"}>Afficher les données sous forme de graphique et les exporter.</p>
                                        <Modale_graphique_export cellule={cellule} setCellule={setCellule} />
                                    </div>
                                    {/*<div className={"carte_action"}>*/}
                                    {/*    <p className={"titre"}>Exporter les données</p>*/}
                                    {/*    <p className={"infos"}>Exporter les données au format CSV pour les sauvegarder*/}
                                    {/*        et les*/}
                                    {/*        ouvrir dans Excel, ou un rapport en PDF.</p>*/}
                                    {/*    <button className={"sec"}><IconeFileCsv/>Exporter CSV</button>*/}
                                    {/*    <button className={"sec"}><IconeFilePdf/>Exporter rapport PDF</button>*/}
                                    {/*</div>*/}
                                    {/*<div className={"carte_action"}>*/}
                                    {/*    <p className={"titre"}>Estimation des pertes d’efficacité</p>*/}
                                    {/*    <p className={"infos"}>Utiliser un algorithme qui analyse les mesures sur*/}
                                    {/*        plusieurs mois*/}
                                    {/*        pour vérifier si le refroidissement à gardé la même efficacité.</p>*/}
                                    {/*    <button className={"sec"}><IconeGear/>Lancer l’analyse</button>*/}
                                    {/*</div>*/}
                                </>
                            )}
                        </div>
                    </Tabs.Panel>

                    {user.roles.includes("ROLE_ADMIN") && (
                        <Tabs.Panel value="Admin" pt="xs">
                            <div className={"actions"}>
                                <h3>Actions admin </h3>
                                <div className={"carte_action"}>
                                    <p className={"titre"}>Historique des abonnements</p>
                                    <p className={"infos"}>Historique des abonnements de cette cellule.</p>
                                    <Historique_statut_modale cellule={cellule}/>
                                </div>
                            </div>
                        </Tabs.Panel>
                    )}
                </Tabs>


            </Sidebar>


            <Joyride
                steps={tourIntro}
                stepIndex={tourStepIndex}
                callback={handleJoyrideCallback}
                run={!modaleAlerteIsOpen && cellule.last_mesures === null && cellule.statut_actuel !== null && tourRun}
                styles={{
                    options: {
                        // arrowColor: '#e3ffeb',
                        // backgroundColor: '#e3ffeb',
                        // overlayColor: 'rgba(79, 26, 0, 0.4)',
                        primaryColor: 'var(--main-color)',
                        zIndex: 100000
                    },
                    tooltipContainer: {
                        lineHeight: 1.4,
                        textAlign: 'left',
                    },
                }}
                locale={{
                    back: 'Retour',
                    close: 'Fermer',
                    last: 'Fermer',
                    next: 'Suivant',
                    open: 'Ouvrir l\'aide',
                    skip: 'Passer'
                }}
                continuous
                showProgress
            />


        </div>

    );
}