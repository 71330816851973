import React, {ReactNode, useState} from 'react';
import './modale_promo_nbe_capteurs.scss'
import {IconeLoader} from "../icone/icone";
import {IconeGift} from "../icone/solid_react/gift";
import {setNePlusAfficherModalePromoNbeCapteurs} from "../../store/interfaceSlice";
import {Organisation} from "../../types/organisation";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Orga_state} from "../../store/organisationSlice";
import {Checkbox, Modal} from "@mantine/core";

export function Modale_promo_nbe_capteurs({
                                              organisation_id,
                                              children,
                                              forcer = false
                                          }: { organisation_id: number, children: ReactNode, forcer?: boolean }) {
    const modal_promo_ne_plus_afficher = useSelector<RootState, boolean>(state => state.interface.promo_nbe_capteurs_modal_ne_plus_afficher)
    const organisations_state = useSelector<RootState, Orga_state>(state => state.organisation)
    const [modal_promo_openned, setModal_promo_openned] = useState(forcer && !modal_promo_ne_plus_afficher);
    const dispatch = useDispatch();

    function getPromosDeCetteOrga(regle: string) {
        let cette_orga = organisations_state.list.find(o => o.id === organisation_id);
        if (!cette_orga) {
            alert("Erreur : Organisation introuvable. Impossible de calculer les promotions.");
            return <p>Erreur : Organisation introuvable. Impossible de calculer les promotions.</p>;
        }
        // @ts-ignore
        return cette_orga.regles_paiements[regle] ?? organisations_state.regles_paiement_defaut[regle]
    }

    return (
        <>
            <Modal opened={modal_promo_openned} onClose={() => setModal_promo_openned(false)}
                   title={<p className={"en-ligne"}><IconeGift/> Informations promotionnelles</p>}>
                <div className={"paragraph_promo_dans_modal"}>

                    <p><strong>Des économies croissantes à mesure que vous développez votre organisation !</strong></p>

                    <p>Chez nous, plus vous déployez d’enregistreurs dans votre organisation, plus vous bénéficiez d’une
                        remise globale sur tous vos abonnements actifs.
                        Voici comment fonctionne notre <strong> offre progressive:</strong></p>

                    <ul>
                        {getPromosDeCetteOrga("plage_capteur1a4") > 0 && (
                            <li>1 à 4 enregistreurs actifs : {getPromosDeCetteOrga("plage_capteur1a4")}% de réduction sur vos abonnements.</li>

                        )}
                        <li>5 à 9 enregistreurs actifs : {getPromosDeCetteOrga("plage_capteur5a9")}% de réduction sur vos abonnements.</li>
                        <li>10 à 14 enregistreurs actifs : {getPromosDeCetteOrga("plage_capteur10a14")}% de réduction sur vos abonnements.</li>
                        <li>15 à 29 enregistreurs actifs : {getPromosDeCetteOrga("plage_capteur15a29")}% de réduction sur vos abonnements.</li>
                        <li>30 enregistreurs ou plus : {getPromosDeCetteOrga("plage_capteur30etPlus")}% de réduction sur vos abonnements.</li>
                    </ul>

                    <p><strong>👉 Chaque ajout compte </strong> : Dès que votre organisation atteint un nouveau palier,
                        votre réduction globale est automatiquement ajustée pour refléter votre croissance.</p>


                </div>

                <div className={"en-ligne spacebetween"} style={{marginTop: "1rem"}}>
                    <Checkbox
                        label={"Ne plus afficher"}
                        checked={modal_promo_ne_plus_afficher}
                        onChange={e => {
                            dispatch(setNePlusAfficherModalePromoNbeCapteurs(e.target.checked))
                        }
                        }
                    />
                    <button onClick={() => setModal_promo_openned(false)}>J'ai compris</button>
                </div>

            </Modal>
            {<div onClick={() => setModal_promo_openned(true)}>{children}</div>}
        </>
    );
}