import React, {useEffect, useState} from 'react';
import './bandeau.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {IconeXmark} from "../icone/solid_react/xmark";
import {IconeInfo} from "../icone/solid_react/info";
import {setBandeauAffichageEffacer} from "../../store/organisationSlice";
export function Bandeau() {
    const dispatch = useDispatch();
    const bandeau_contenu = useSelector<RootState, string>(state => state.organisation.bandeau_top.texte)

    if (bandeau_contenu === "") return <></>

    return (
        <>
            <div className={"bandeau_affichage"}>
                <p className={"flex-row"}><IconeInfo /> {bandeau_contenu}</p>
                <button className={"sec blanc noMargin"} onClick={() => {
                    dispatch(setBandeauAffichageEffacer());
                }}><IconeXmark /></button>
            </div>
        </>

    );
}
