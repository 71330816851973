import React, {useEffect, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from "react-joyride";
import {Organisation} from "../../../types/organisation";

export function Accueil_intro_tour({organisations}:{organisations: Organisation[]}) {

	const [tourIntro, setTourIntro] = useState<any>([]);
	const [tourStepIndex, setTourStepIndex] = useState<number>(0);
	const [tourRun, setTourRun] = useState<boolean>(false);
	useEffect(() => {
		if (JSON.stringify(tourIntro) !== "[]" || organisations.length === 0)
		{
			return;
		}
		let tour:any = [
			{
				target: 'body',
				content:
					<>
						<h3>Bienvenue sur la plateforme Freshtime!</h3>
						<p>Vous trouverez ici toutes les informations liées à vos appareils.</p>
						{/*<p style={{fontWeight: "bold"}}>Voici un tour rapide (moins de 1 minutes) pour vous présenter les fonctionnalités de cette page</p>*/}
					</>,
				placement: 'center',
				spotlightClicks: false,
			},
			{
				target: 'body',
				content:
					<>
						<p>Vous êtes sur la page d’accueil. Cette page regroupe toutes les différentes « Cellules » que vous créez.</p>
						<p>Une Cellules représente une des sorties (Sonde, contact sec) de votre Enregistreur ainsi que le stockage de ses données.</p>
						{/*<p style={{fontWeight: "bold"}}>Voici un tour rapide (moins de 1 minutes) pour vous présenter les fonctionnalités de cette page</p>*/}
					</>,
				placement: 'center',
				spotlightClicks: false,
			},
			{
				// target: "#btn_administrer_orga_" + organisations[0].id,
				target: "#btn_administrer_orga_" + organisations[0].id,
				content:
					<>
						<p>Pour créer votre première cellule, cliquez sur « Configurer l’organisation » pour accéder à votre organisation</p>
						{/*<p style={{fontWeight: "bold"}}>Voici un tour rapide (moins de 1 minutes) pour vous présenter les fonctionnalités de cette page</p>*/}
					</>,
				placement: 'bottom',
				spotlightClicks: true,
			},
			// {
			//     target: '.mesures_nav',
			//     content: "Ces controles servent à choisir comment vous souhaitez l'affichage de vos mesures, et de vous déplacer sur différentes périodes.",
			// }
		];

		setTourIntro(tour)

		if (organisations.length === 1 && organisations[0] != null && organisations[0].zones != null)
		{
			let zone_par_defaut = organisations[0].zones.find(z => z.nom === organisations[0].nom);

			if (organisations[0].zones.length < 3 && zone_par_defaut?.cellules_minimum?.length === 0)
			{
				setTourRun(true)
			}
		}


	}, [organisations]);


	const handleJoyrideCallback = (data: CallBackProps) => {
		const { action, index, origin, status, type } = data;

		if (action === ACTIONS.CLOSE) {
			// do something
			setTourRun(false);
		}

		// @ts-ignore
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour

			setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

		} else { // @ts-ignore
			if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
				// You need to set our running state to false, so we can restart if we click start again.
				setTourRun(false);
			}
		}

		// console.groupCollapsed(type);
		// console.log(data); //eslint-disable-line no-console
		// console.groupEnd();
	};

	return (
		<Joyride
			steps={tourIntro}
			stepIndex={tourStepIndex}
			callback={handleJoyrideCallback}
			run={tourRun}
			styles={{
				options: {
					// arrowColor: '#e3ffeb',
					// backgroundColor: '#e3ffeb',
					// overlayColor: 'rgba(79, 26, 0, 0.4)',
					primaryColor: 'var(--main-color)',
					zIndex: 100000
				},
				tooltipContainer: {
					lineHeight: 1.4,
					textAlign: 'left',
				},
			}}
			locale={{ back: 'Retour', close: 'Fermer', last: 'Fermer', next: 'Suivant', open: 'Ouvrir l\'aide', skip: 'Passer' }}
			continuous
			// showProgress
		/>
	)
}