import React, {ReactNode, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {dashboardCollapse, setMobile_sidebar_show, toggleMobile_sidebar_show} from "../../store/interfaceSlice";
import {useLocation} from "react-router-dom";

interface SidebarProps {
	children: ReactNode;
	hideOnMobile?:boolean
	className?:string
}

function Sidebar({ children, hideOnMobile, className }: SidebarProps) {


	const mobile_sidebar_show = useSelector<RootState, boolean>(state => state.interface.mobile_sidebar_show)
	const [hideNavItems, setHideNavItems] = useState(true);

	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {

			setTimeout(() => {
				setHideNavItems(!mobile_sidebar_show)
			}, 500)

	}, [mobile_sidebar_show])

	useEffect(() => {
		setHideNavItems(true)
		dispatch(setMobile_sidebar_show(false));

		// Specify the dependencies, so the effect only runs when the route changes
	}, [location]);

	return (
		<div className={"sidebar" + (mobile_sidebar_show ? " mobile_visible" : "") + (hideNavItems ? " mobile_visible_hide" : "") + (hideOnMobile ? " hide_on_mobile" : "") + (className ? " " + className : "")}>
			{children}
		</div>
	);
}

export default Sidebar;