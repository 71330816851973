import React from 'react';
import './loading_div.scss'
import {IconeLoader} from "../icone/icone";
export function Loading_div({customTexte="Chargement..."}:{customTexte?:string}) {
    return (
        <div className={"loading_div"}>
            <IconeLoader />
            {customTexte}
        </div>
    );
}