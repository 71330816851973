import React, {useEffect, useState} from 'react';
import { Link, useLocation } from "react-router-dom";
import './navbar.scss'
import logo_freshtime from './logo_freshtime.svg';
import {Icone} from "../icone/icone";
import {useDispatch, useSelector} from "react-redux";
import {deleteUser} from "../../store/userSlice";
import {logOut} from "../../store/authSlice";
import {unsetCapteurModale} from "../../store/capteur_modale_Slice";
import {RootState, store} from "../../store/store";
import {User} from "../../types/user";
import {clearOrganisations} from "../../store/organisationSlice";
import {useDisclosure} from "@mantine/hooks";
import {Burger, Button, FocusTrap, Menu, Modal, rem, TextInput} from "@mantine/core";
import {Simulate} from "react-dom/test-utils";
import toggle = Simulate.toggle;
import {setMobile_sidebar_show, setNavMenuOpenedState, toggleMobile_sidebar_show} from "../../store/interfaceSlice";
import {IconeHouse} from "../icone/solid_react/house";
import {IconeSatelliteDish} from "../icone/solid_react/satellite-dish";
import {IconeEnvelope} from "../icone/solid_react/envelope";
import {IconeSignalStream} from "../icone/solid_react/signal-stream";
import {IconeEuroSign} from "../icone/solid_react/euro-sign";
import {IconeFlask} from "../icone/solid_react/flask";
import {IconeReceipt} from "../icone/solid_react/receipt";
import {IconeSliders} from "../icone/solid_react/sliders";
import {IconeCircleQuestion} from "../icone/solid_react/circle-question";
import {IconeUser} from "../icone/solid_react/user";
import {IconeRightFromBracket} from "../icone/solid_react/right-from-bracket";
import {IconeBell} from "../icone/solid_react/bell";
import {IconePenToSquare} from "../icone/solid_react/pen-to-square";
import {IconeWindWarning} from "../icone/solid_react/wind-warning";
import {IconeCircleExclamation} from "../icone/solid_react/circle-exclamation";
import {Organisation} from "../../types/organisation";
import env_config from "../../env_config";
import {IconeAddressBook} from "../icone/solid_react/address-book";
import {IconeNarwhal} from "../icone/solid_react/narwhal";
import {IconeStore} from "../icone/solid_react/store";
import {texte_est_inclus} from "../../services/GestionTextes";
import {IconeHandPointRight} from "../icone/solid_react/hand-point-right";
import {IconeMagnifyingGlassLocation} from "../icone/solid_react/magnifying-glass-location";
import {IconeFileMagnifyingGlass} from "../icone/solid_react/file-magnifying-glass";
import {Ajouter_un_utilisateur} from "../Tours/pas-a-pas/Ajouter_un_utilisateur";
import {pasApasData, pasApasSlice, setPasApasEtat, setPasApasTour} from "../../store/pasApasSlice";
import {Ajouter_un_appareil} from "../Tours/pas-a-pas/Ajouter_un_appareil";
import {Modifier_anomalies} from "../Tours/pas-a-pas/Modifier_anomalies";
import {IconeMagnifyingGlass} from "../icone/solid_react/magnifying-glass";
import {Ajouter_une_sonde} from "../Tours/pas-a-pas/Ajouter_une_sonde";
import {Modifier_une_sonde} from "../Tours/pas-a-pas/Modifier_une_sonde";
import {Remplacer_appareil} from "../Tours/pas-a-pas/Remplacer_appareil";
import {Modifier_alertes_tous_utilisateurs} from "../Tours/pas-a-pas/Modifier_alertes_tous_utilisateurs";
import {Modifier_permission_utilisateur} from "../Tours/pas-a-pas/Modifier_permission_utilisateur";
import {Modifier_alerte_cellule} from "../Tours/pas-a-pas/Modifier_alerte_cellule";
export function Navbar() {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector<RootState, User>(state => state.user)
    const [hideNavItems, setHideNavItems] = useState(true);
    const [burger_opened, burger_handlers] = useDisclosure(false);
    const mobile_sidebar_show = useSelector<RootState, boolean>(state => state.interface.mobile_sidebar_show)
    const mobile_sidebar_icon = useSelector<RootState, string>(state => state.interface.mobile_sidebar_icon)
    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)
    const nav_menu_opened = useSelector<RootState, boolean>(state => state.interface.nav_menu_opened)

    const [menu_orgas_recherche, setMenu_orgas_recherche] = useState("");

    const [modale_pas_a_pas_opened, modale_pas_a_pas_handlers] = useDisclosure(false);

    const orga_nouvelle_paiement_demain = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const pasApas = useSelector<RootState, pasApasData>(state => state.pasApas)

    const [pas_a_pas_recherche, setPas_a_pas_recherche] = useState("");

    function checkSiDoitAfficherMarqueurPaiementDemain()
    {
        return orga_nouvelle_paiement_demain.filter(o => {
            return o.etat === "New" && o.prochainPrelevement && o.balance <= 0
        }).length > 0;
    }

    useEffect(() => {
            setTimeout(() => {
                setHideNavItems(!burger_opened)
            }, 500)
    }, [burger_opened])


    function setTourAactiver(tour:React.ReactElement)
    {
        dispatch(setPasApasTour(tour))
        modale_pas_a_pas_handlers.close();

        // store.dispatch(setNavMenuOpenedState(false))
        store.dispatch(setMobile_sidebar_show(false))
    }

    useEffect(() => {
        if (burger_opened)
        {
            dispatch(setPasApasEtat("nav_opened"))
        }
    }, [burger_opened]);

    useEffect(() => {
        if (burger_opened && !nav_menu_opened)
        {
            burger_handlers.close();
        }
    }, [nav_menu_opened]);

    return (
        <>
            <div className={"mobile_header"}>
                <Burger opened={burger_opened}
                        onClick={() => {
                            dispatch(setNavMenuOpenedState(!burger_opened))
                            if (!burger_opened)
                            {
                                setHideNavItems(false)
                                setTimeout(() => {
                                    burger_handlers.toggle()
                                }, 20)
                            }
                        }}
                        aria-label={burger_opened ? 'Fermer navigation' : 'Ouvrir navigation'}
                        color="#fff" size={18}
                        id={"burger-nav"}
                />

                <Link to="/" onClick={() => {dispatch(unsetCapteurModale())}} className="logo-link"><img src={logo_freshtime} className={"logo"} alt=""/></Link>


                {mobile_sidebar_icon !== "aucun" ? (
                    <div className={"mobile_trigger_notifications" + (mobile_sidebar_show ? " open" : "")} onClick={() => {
                        if (!mobile_sidebar_show)
                        {
                            store.dispatch(setPasApasEtat("menu_opened"))
                        }
                        dispatch(toggleMobile_sidebar_show())
                    }}>
                        {/*{location.pathname === "/" ? (*/}
                        {/*    <Icone nom={"bell"} />*/}
                        {/*) : (*/}
                        {/*    <Icone nom={"pen-to-square"} />*/}
                        {/*)}*/}
                        {mobile_sidebar_icon === "bell" || mobile_sidebar_icon === "pen-to-square" ? (
                            <>
                                {mobile_sidebar_icon === "bell" && <IconeBell />}
                                {mobile_sidebar_icon === "pen-to-square" && <IconePenToSquare />}
                            </>
                        ) : (
                            <Icone nom={mobile_sidebar_icon} />
                        )}

                    </div>
                ) : (<div className={"mobile_trigger_notifications"}> <Icone nom={"aucun"} /> </div>)}


                <div className={"nav_background" + (burger_opened ? " open" : "") + (hideNavItems ? " hide" : "")} onClick={(e) => {
                    e.stopPropagation();
                    burger_handlers.close();
                }}></div>

            </div>
            <nav className={(burger_opened ? " open" : "") + (hideNavItems ? " hide" : "")}>
                <Link to="/" onClick={() => {dispatch(unsetCapteurModale())}} className="logo-link"><img src={logo_freshtime} className={"logo"} alt=""/></Link>

                <ul>
                    <li className={location.pathname === "/" ? "selected" : ""}>
                        <Link to="/" id="nav_accueil" onClick={() => {dispatch(unsetCapteurModale())}}><IconeHouse /> Accueil</Link>
                    </li>
                    {organisations.length === 1 ? (
                            <li className={false ? "selected" : ""} id={"nav_organisations"}>
                                <Link to={"/organisation/" + organisations[0].id} onClick={() => {
                                    dispatch(setPasApasEtat("menu_orga_nav_open"))
                                }}><IconeStore />Organisation</Link>
                            </li>

                    ) : (
                        <li className={false ? "selected" : ""} id={"nav_organisations"}>
                            <Menu position="right-start" offset={6}
                                  withArrow arrowPosition="center"
                                  trigger="hover" openDelay={100} closeDelay={200}
                                  closeOnItemClick={false}
                                  portalProps={{
                                      className: "orgas_nav"
                                  }}
                            >
                                <Menu.Target>
                                    <a onClick={() => dispatch(setPasApasEtat("menu_orga_nav_open"))}><IconeStore />Organisations</a>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    {organisations.length > 10 && (
                                        <Menu.Item>
                                            <TextInput
                                                placeholder={"Recherche..."}
                                                value={menu_orgas_recherche}
                                                onChange={e => setMenu_orgas_recherche(e.target.value)}
                                            />
                                        </Menu.Item>
                                    )}

                                    {organisations.filter(o => texte_est_inclus(o.nom, menu_orgas_recherche) || o.id+"" === menu_orgas_recherche).slice(0, 16).map(orga => (
                                        <Menu.Item closeMenuOnClick={true}>
                                            <Link to={"/organisation/" + orga.id} style={{width: "100%"}}>
                                                {orga.nom}
                                            </Link>
                                        </Menu.Item>
                                    ))}

                                </Menu.Dropdown>
                            </Menu>
                        </li>
                    )}

                    <li className={location.pathname === "/hubs" ? "selected" : ""}>
                        <Link to="/hubs"><IconeSatelliteDish />Hubs</Link>
                    </li>
                    <li className={location.pathname === "/notifications" ? "selected" : ""}>
                        <Link to="/notifications"><IconeEnvelope />Notifications</Link>
                    </li>
                    <li className={location.pathname === "/en-direct" ? "selected" : ""}>
                        <Link to="/en-direct"><IconeSignalStream />En direct</Link>
                    </li>
                    <li className={location.pathname === "/gestion" ? "selected" : ""}>
                        <Link to="/gestion"><IconeEuroSign />
                            Paiements
                            {checkSiDoitAfficherMarqueurPaiementDemain() && (
                                <IconeCircleExclamation className={"red"} />
                            )}

                        </Link>
                    </li>
                </ul>
                <div className={"separator"}></div>
                <ul>
                    {user.roles.includes("ROLE_ADMIN") && (
                        <li className={location.pathname === "/bureau" ? "selected" : ""}>
                            <Link to="/bureau"><IconeFlask />Bureau d'étude</Link>
                        </li>
                    )}
                    {(user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_PRESTATAIRE")) && (
                        <li className={location.pathname === "/prestataire" ? "selected" : ""}>
                            <Link to="/prestataire"><IconeReceipt />Prestataire</Link>
                        </li>
                    )}


                </ul>
                <div className={"separator"}></div>
                <ul>
                    <li className={location.pathname === "/contacts" ? "selected" : ""}>
                        <Link to="/contacts"><IconeAddressBook />Contacts</Link>
                    </li>
                    {/*<li className={location.pathname === "/parametres" ? "selected" : ""}>*/}
                    {/*    <Link to="/parametres"><IconeSliders />Paramètres</Link>*/}
                    {/*</li>*/}
                    <li className={location.pathname === "/assistance" ? "selected" : ""}>
                        <a onClick={modale_pas_a_pas_handlers.open}><IconeFileMagnifyingGlass />Pas-à-pas</a>
                    </li>
                    <li className={location.pathname === "/assistance" ? "selected" : ""}>
                        <Link to="/assistance"><IconeCircleQuestion />Documentation</Link>
                    </li>
                    <li className={(location.pathname === "/mon-compte" ? "selected" : "")}>
                        <Link to="/mon-compte"><IconeUser />Mon compte</Link>
                    </li>
                    <li className={"only_mobile"}>
                        <Link to="/logout"><IconeRightFromBracket />Déconnexion</Link>
                    </li>
                </ul>
            </nav>

            <Modal opened={modale_pas_a_pas_opened} onClose={modale_pas_a_pas_handlers.close}
                   title={<div className={"en-ligne"}

                   >
                       <IconeFileMagnifyingGlass />Pas-à-pas</div>}>

                <div className={"en-ligne"} style={{gap: "0.5rem"}}>
                    <p>Le système Pas-à-pas vous guide sur les actions basiques de la plateforme Freshtime, pour que vous ne perdiez jamais de temps à chercher les choses.</p>

                    {pasApas.etat}

                    <TextInput
                        placeholder="Recherche..."
                        leftSection={<IconeMagnifyingGlass />}
                        value={pas_a_pas_recherche}
                        onChange={e => setPas_a_pas_recherche(e.target.value)}
                        w={"100%"}
                    />
                    {(() => {
                        return [
                            {nom: "Ajouter un utilisateur à votre organisation", elem: <Ajouter_un_utilisateur />},
                            {nom: "Modifier les alertes de tous les utilisateurs", elem: <Modifier_alertes_tous_utilisateurs/>},
                            {nom: "Modifier les permissions d'un utilisateur", elem: <Modifier_permission_utilisateur/>},
                            {nom: "Ajouter un appareil à votre organisation", elem: <Ajouter_un_appareil />},
                            {nom: "J'ai branché une nouvelle sonde sur mon appareil", elem: <Ajouter_une_sonde/>},
                            {nom: "J'ai changé une sonde sur mon appareil par une autre différente", elem: <Modifier_une_sonde/>},
                            {nom: "Remplacer un appareil existant par un nouveau", elem: <Remplacer_appareil/>},
                            {nom: "Modifier les anomalies d'une cellule (seuils MIN et MAX)", elem: <Modifier_anomalies />},
                            {nom: "Modifier les alertes sur une cellule", elem: <Modifier_alerte_cellule/>},
                        ].filter(i => texte_est_inclus(i.nom, pas_a_pas_recherche)).map(item =>
                            <button style={{boxSizing: "border-box", width: "100%", justifyContent: "start"}} onClick={() => setTourAactiver(item.elem)}>{item.nom}</button>
                        )
                    })()}
                </div>

            </Modal>

        </>

    );
}
