export type Hub = {
    uuid: string,
    nom: string,
    modele: string,
    ref: string,
    signal_gsm: string,
    version_logicielle: string,
    organisation?: {
        id: number
        nom : string
    },
    derniere_connexion: string,
    heartbeat: string,
    notes: string,
    notes_admin?: string,
    vpn_ip?: string
    config:hub_config
    heartbeat_etat: {
        wifi: number //-1 erreur de lecture, 0 pas connecté, 1 connecté
        ssid: string
        rj45: number //-1 erreur de lecture, 0 pas connecté, 1 connecté
        erreur: string
    }|null
}


export type wifi_data = {
    "bss": string,
    "signal": number,
    "ssid": string,
    "auth": number,
    "cipher": number,
    "freq": number

}

export type hub_config = {
    data: wifi_data,
    key:string,
    enable_wifi: boolean
    dhcp: boolean
    static_ip:string
    static_masque:string
    static_passerelle:string
    wifi_dhcp: boolean
    wifi_ip:string
    wifi_masque:string
    wifi_passerelle:string
    xsupplicant_type: number,
    user: string,
    identity: string,
    phase2: string,
    certificate: string
}

export const hub_config_init:hub_config = {"dhcp":true,"static_ip":"","static_masque":"","static_passerelle":"","wifi_dhcp":true,"wifi_ip":"","wifi_masque":"","wifi_passerelle":"","data":{"bss":"","signal":0,"ssid":"","auth":0,"cipher":0,"freq":0},"enable_wifi":false,"key":"", "certificate":"", "identity": "", "phase2": "", "user": "", "xsupplicant_type": 0}