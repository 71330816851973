import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../../services/AxiosInterceptor";
import {Avatar, Group, Modal, Select, Textarea, TextInput} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {Capteur} from "../../../../types/capteur";

export function Modale_editer_capteur({capteur, setEditedCapteur, capteurs, setCapteurs}: { capteur: Capteur|null, setEditedCapteur:(capteurs:Capteur|null)=>void, capteurs:Capteur[]|null, setCapteurs:(capteurs:Capteur[])=>void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);


    const [tmp_capteur, setTmp_capteur] = useState<Capteur|null>(capteur);

    function handleEnregistrer() {
        set_btn_loading(true);
        if (!tmp_capteur){
            alert("Erreur, l'enregistreur est null.")
            return;
        }
        ajax.put("/capteurs/" + tmp_capteur.uuid, {
            "nom": tmp_capteur.nom,
            "notes": tmp_capteur.notes,
        }).then(response => {
            let capteurs_maj = capteurs?.map(h => {
                if (h.uuid === tmp_capteur.uuid)
                {
                    return tmp_capteur
                }
                return h;
            })
            if (capteurs_maj)
            {
                setCapteurs(capteurs_maj);
                handlers.close();
            }
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }


    useEffect(() => {
        if (capteur) handlers.open()
        else handlers.close()
        setTmp_capteur(capteur)
    }, [capteur])


    if (!tmp_capteur) return <></>

    return (
        <>
            <Modal opened={opened} onClose={() => {
                handlers.close()
                setEditedCapteur(null);
            }} title={"Modifier l'enregistreur " + tmp_capteur.uuid}>
                <div className={"form"}>

                    <TextInput
                        label={"Nom"}
                        value={tmp_capteur.nom}
                        onChange={e => {
                            let _th = {...tmp_capteur};
                            _th.nom = e.target.value
                            setTmp_capteur(_th)
                        }}
                    />
                    <Textarea
                        label={"Notes"}
                        value={tmp_capteur.notes}
                        onChange={e => {
                            let _th = {...tmp_capteur};
                            _th.notes = e.target.value
                            setTmp_capteur(_th)
                        }}
                        autosize
                        minRows={2}
                    />

                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} icone={"check"}>Enregistrer</Loading_button>
                </div>

            </Modal>
        </>

    )
}