import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Avatar, Center, Checkbox, Group, Modal, NumberInput, Select, Switch, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Capteur} from "../../../types/capteur";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {DatePicker, DateTimePicker} from "@mantine/dates";
import {IconeGear} from "../../../components/icone/solid_react/gear";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {DateTZ} from "../../../services/GestionDates";

export function Modale_ordre_remonter_mesure({
                                                  capteur,
                                                  setCapteur
                                              }: { capteur: Capteur, setCapteur: (capteur: Capteur) => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);


    const [uniquementUnJour, setUniquementUnJour] = useState<boolean>(true);
    const [dates, setDates] = useState<[Date | null, Date | null]>([null, null]);

    const [fuseau, setFuseau] = useState<string>((0 - new Date().getTimezoneOffset()/60) + "");

    function calculTempsEstime()
    {
        let dureeMessageMesure = 20;
        let refresh_rate = capteur.configurations?.filter(c => c.parametre.slug === "refresh_rate")
        if (refresh_rate && refresh_rate[0] && dates[0] && dates[1])
        {
            let messagesParJours = (60*60*24) / Number(refresh_rate[0].valeur);
            let debut = new DateTZ(dates[0].getTime());
            debut?.setUTCFullYear(debut?.getFullYear(),debut?.getMonth(),debut.getDate());
            debut?.setUTCHours(debut?.getHours(),debut?.getMinutes(),0);
            let fin = new DateTZ(dates[1].getTime());
            fin?.setUTCFullYear(fin?.getFullYear(),fin?.getMonth(),fin.getDate());
            fin?.setUTCHours(0,0,0);

            let duree_selec_secondes =  (fin.getTime() - debut.getTime())/1000 + 60*60*24
            let messagesTotal = messagesParJours * (duree_selec_secondes / (60*60*24))

            let duree_remontee_secondes = 45 * messagesTotal
            let jours = Math.floor(duree_remontee_secondes / (60*60*24));
            let heures = Math.floor((duree_remontee_secondes - jours*(60*60*24)) / 3600);
            let minutes = Math.floor((duree_remontee_secondes - jours*(60*60*24) - heures*3600) / 60);

            return " "
                +jours + " jours, "+heures + " heures et " + minutes
                + " minutes"
        }
        else return " impossible à calculer."
    }

    function handleEnregistrer() {
        if (dates[0] && dates[1]){
            set_btn_loading(true);
            let debut = new DateTZ(dates[0].getTime());
            debut?.setUTCFullYear(debut?.getFullYear(),debut?.getMonth(),debut.getDate());
            debut?.setUTCHours(debut?.getHours(),debut?.getMinutes(),0);
            let fin = new DateTZ(dates[1].getTime());
            fin?.setUTCFullYear(fin?.getFullYear(),fin?.getMonth(),fin.getDate());
            fin?.setUTCHours(0,0,0);
            ajax.put("/capteurs/" + capteur.uuid + "/remonteeMesuresProcedure", {
                debut: debut?.toISOString(),
                fin: fin?.toISOString(),
                timezone: Number(fuseau),
            }).then(response => {
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Pris en compte",
                    message: 'L\'ordre sera envoyé à la prochaine actualisation de l\'enregistreur.',
                    icon: <IconeCheck/>,
                });
                handlers.close();
                set_btn_loading(false);
            }).catch(error => {
                set_btn_loading(false);
            })
        }


    }

    if (capteur.configurations === undefined) return (
        <p>Erreur : la configuration de l'enregistreur est undefined</p>
    )

    return (
        <>
            <button className={"sec"} onClick={handlers.open}><IconeGear/> Récupération des mesures</button>

            <Modal opened={opened} onClose={handlers.close} title="Remontée des mesures">
                <div className={"form"}>

                    <Switch label="Uniquement un jour ?" checked={uniquementUnJour} onChange={e => {
                        setUniquementUnJour(e.target.checked)
                        if (e.target.checked) setDates([new DateTZ(), new DateTZ()])
                    }} />


                    <Select
                        value={fuseau}
                        label={"Fuseau horaire : (Celui du navigateur : "+(0 - new Date().getTimezoneOffset()/60)+"h)"}
                        onChange={e => setFuseau(e ?? "0")}
                        data={[
                            { "value": "-12", "label": "UTC-12:00 - Île Baker, Île Howland" },
                            { "value": "-11", "label": "UTC-11:00 - Niue, Samoa américaines" },
                            { "value": "-10", "label": "UTC-10:00 - Heure des îles Aléoutiennes, Tahiti" },
                            { "value": "-9", "label": "UTC-09:00 - Heure de l'Alaska, Îles Gambier" },
                            { "value": "-8", "label": "UTC-08:00 - Heure du Pacifique, Îles Pitcairn" },
                            { "value": "-7", "label": "UTC-07:00 - Heure des Rocheuses, Chihuahua" },
                            { "value": "-6", "label": "UTC-06:00 - Heure centrale, Mexico" },
                            { "value": "-5", "label": "UTC-05:00 - Heure de l'Est, Bogota" },
                            { "value": "-4", "label": "UTC-04:00 - Heure de l'Atlantique, Caracas" },
                            { "value": "-3", "label": "UTC-03:00 - Argentine, São Paulo" },
                            { "value": "-2", "label": "UTC-02:00 - Géorgie du Sud et îles Sandwich du Sud" },
                            { "value": "-1", "label": "UTC-01:00 - Açores, Cap-Vert" },
                            { "value": "0", "label": "UTC+00:00 - Londres, Lisbonne, Accra" },
                            { "value": "1", "label": "UTC+01:00 - Heure d'Europe Centrale, Lagos" },
                            { "value": "2", "label": "UTC+02:00 - Heure d'Europe de l'Est, Le Caire" },
                            { "value": "3", "label": "UTC+03:00 - Moscou, Nairobi, Riyad" },
                            { "value": "4", "label": "UTC+04:00 - Dubaï, Bakou, Samara" },
                            { "value": "5", "label": "UTC+05:00 - Islamabad, Karachi, Iekaterinbourg" },
                            { "value": "6", "label": "UTC+06:00 - Dacca, Almaty, Omsk" },
                            { "value": "7", "label": "UTC+07:00 - Bangkok, Jakarta, Krasnoïarsk" },
                            { "value": "8", "label": "UTC+08:00 - Pékin, Perth, Singapour" },
                            { "value": "9", "label": "UTC+09:00 - Tokyo, Séoul, Iakoutsk" },
                            { "value": "10", "label": "UTC+10:00 - Sydney, Guam, Vladivostok" },
                            { "value": "11", "label": "UTC+11:00 - Îles Salomon, Nouvelle-Calédonie" },
                            { "value": "12", "label": "UTC+12:00 - Auckland, Fidji, Kamtchatka" },
                            { "value": "13", "label": "UTC+13:00 - Tonga, Îles Phoenix" },
                            { "value": "14", "label": "UTC+14:00 - Îles de la Ligne" }
                        ]
                        }
                    />

                    {uniquementUnJour ? (
                        // <>
                        //     <label>Choissez le jour et l'heure. La période sera de votre selection jusqu'au meme jour à minuit : </label>
                        //     <Center>
                        //         <DateTimePicker value={dates[0]} onChange={e => {
                        //             setDates([
                        //                 e, e
                        //             ]);
                        //         }} locale="fr"
                        //         popoverProps={{
                        //             position: "bottom"
                        //         }}
                        //         />
                        //     </Center>
                        //     <Center>
                        //         <p className={"inline-tag"}>Attention : L'heure est UTC. Il est conseillé d'indiquer 2h avant l'heure pour laquelle vous voulez les mesures.</p>
                        //     </Center>
                        // </>
                        <>
                            <label>Choissez le jour : </label>
                            <Center>
                                <DatePicker value={dates[0]} onChange={e => {
                                    setDates([
                                        e, e
                                    ]);
                                }} locale="fr"
                                    maxDate={new DateTZ()}
                                />
                            </Center>
                        </>
                    ) : (
                        <>
                            <label>Choissez la période pour laquelle vous voulez remonter les données : </label>
                            <Center>
                                <DatePicker type="range"
                                            value={dates}
                                            onChange={setDates}
                                            locale="fr"
                                            maxDate={new DateTZ()}/>
                            </Center>
                        </>
                    )}

                    {!(dates[0] === null || dates[1] === null) && (
                        <p className={"inline-tag"}>Temps estimé :
                            {calculTempsEstime()}</p>
                    )}


                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} icone={"check"} disabled={dates[0] === null || dates[1] === null}>Valider</Loading_button>
                </div>

            </Modal>
        </>

    )
}