import React, {useEffect, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from "react-joyride";
import {Organisation} from "../../types/organisation";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {pasApasData} from "../../store/pasApasSlice";

export function En_direct_tuto({organisations}:{organisations: Organisation[]}) {

	const [tourIntro, setTourIntro] = useState<any>([]);
	const [tourStepIndex, setTourStepIndex] = useState<number>(0);
	const [tourRun, setTourRun] = useState<boolean>(false);

	const pasApas = useSelector<RootState, pasApasData>(state => state.pasApas)

	const [was_started, setWas_started] = useState(false);

	useEffect(() => {
		let tour:any = [
			{
				target: 'body',
				content:
					<div className={"flex-col"} style={{gap: "0.5rem"}}>
						<strong>Bienvenue sur la vue En Direct. </strong>
						<p>Cette page vous permet d'afficher vos cellules de la façon dont vous le souhaitez, pour les afficher au mieux sur un support de votre choix.</p>
						<p>Voici un petit tour rapide des fonctionnalités</p>
					</div>,
				placement: 'center',
				spotlightClicks: false,
			},
			{
				target: "#btn_accueil",
				content:
					<>
						<p>Pour revenir à la plateforme, cliquez ici</p>
					</>,
				placement: 'bottom',
				spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: "#btn_reglages",
				content:
					<div className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Pour ouvrir ou fermer les réglages de la page, cliquez ici.</p>
						<p>Les réglages sont actuellement ouvert.</p>
					</div>,
				placement: 'bottom',
				spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: ".en-direct-reglages",
				content:
					<div className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Voici les réglages de la page.</p>
						<p>Pour continuer, merci de sélectionner une organisation à afficher</p>
					</div>,
				placement: 'bottom',
				spotlightClicks: true,
				hideFooter: true,
			},
			{
				target: ".accueil_organisation",
				content:
					<>
						<p>Les cellules de votre organisation se sont affichés.</p>
					</>,
				placement: 'top',
				spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: ".en-direct-reglages",
				content:
					<div className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Les réglages présent ici vont changer l'affichage des cellules affichés dessous.</p>
						<p>Nous vous invitons a les essayer, leur effet sont visuels uniquement.</p>
					</div>,
				placement: 'bottom',
				spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: ".btn_custom_carte_mesure",
				content:
					<div  className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Dans les mesures et zones affichés, vous pouvez choisir d'en cacher certaines en cliquant sur le bouton avec l'oeil.</p>
						<p>Le bouton n'est disponible que quand les réglages sont ouvert, et les cellules et zones cachées le seront une fois les réglages fermés.</p>
					</div>,
				placement: 'bottom',
				spotlightClicks: true,
				// hideFooter: true,
			},
		];

		setTourIntro(tour)

		if (organisations.length === 0 && pasApas.tour === null)
		{
			setTourRun(true)
			setWas_started(true)
		}

		if (tourStepIndex === 3 && organisations.length > 0)
		{
			setTourStepIndex(tourStepIndex+1);
		}

	}, [organisations]);

	// useEffect(() => {
	// 	if (organisation.zones?.length === 2 && custom_step === 1)
	// 	{
	// 		setCustom_step(2)
	// 		setTourStepIndex(tourStepIndex+1)
	// 		setTourIntro([ ...tourIntro,
	// 			{
	// 				target: ".nouvelle-zone input",
	// 				content:
	// 					<>
	// 						<p>Vous pouvez nommer votre zone.</p>
	// 						{/*<p style={{fontWeight: "bold"}}>Voici un tour rapide (moins de 1 minutes) pour vous présenter les fonctionnalités de cette page</p>*/}
	// 					</>,
	// 				placement: 'bottom',
	// 				spotlightClicks: true,
	// 			},
	// 		])
	// 	}
	//
	// }, [organisation]);


	const handleJoyrideCallback = (data: CallBackProps) => {
		const { action, index, origin, status, type } = data;

		if (action === ACTIONS.CLOSE) {
			// do something
			setTourRun(false);
		}

		// @ts-ignore
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour

			setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

		} else { // @ts-ignore
			if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
				// You need to set our running state to false, so we can restart if we click start again.
				setTourRun(false);
			}
		}

		// console.groupCollapsed(type);
		// console.log(data); //eslint-disable-line no-console
		// console.groupEnd();
	};

	return (
		<Joyride
			steps={tourIntro}
			stepIndex={tourStepIndex}
			callback={handleJoyrideCallback}
			run={tourRun}
			styles={{
				options: {
					// arrowColor: '#e3ffeb',
					// backgroundColor: '#e3ffeb',
					// overlayColor: 'rgba(79, 26, 0, 0.4)',
					primaryColor: 'var(--main-color)',
					zIndex: 100000
				},
				tooltipContainer: {
					lineHeight: 1.4,
					textAlign: 'left',
				},
			}}
			locale={{ back: 'Retour', close: 'Fermer', last: 'Fermer', next: 'Suivant', open: 'Ouvrir l\'aide', skip: 'Passer' }}
			continuous
			// showProgress
		/>
	)
}