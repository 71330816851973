import {Cellule} from "../../../../types/cellule";
import {useDisclosure} from "@mantine/hooks";
import React, {useEffect, useState} from "react";
import {Statut_cellule} from "../../../../types/statut_cellule";
import "./modale_configurer_conditions.scss"
import {
    Accordion,
    AccordionControlProps,
    Box, Checkbox,
    Modal,
    MultiSelect,
    NumberInput,
    Select, Switch, Tabs,
    Textarea,
    TextInput, Tooltip
} from "@mantine/core";
import {IconeLoader} from "../../../../components/icone/icone";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {horaire_maintenance} from "../../../../types/condition";
import ajax from "../../../../services/AxiosInterceptor";
import {Alerte} from "../../../../types/alerte";
import {
    Modale_horaire_maintenance
} from "../../../../components/Modales/Modale_horaire_maintenance/Modale_horaire_maintenance";
import {getAffichageNom} from "../../../../services/UserService";
import {IconeListCheck} from "../../../../components/icone/solid_react/list-check";
import {IconeStopwatch} from "../../../../components/icone/solid_react/stopwatch";
import {IconePlus} from "../../../../components/icone/solid_react/plus";
import {IconeTrashXmark} from "../../../../components/icone/solid_react/trash-xmark";
import {setPasApasEtat} from "../../../../store/pasApasSlice";
import {useDispatch} from "react-redux";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {IconeCheck} from "../../../../components/icone/solid_react/check";

export type condition_unit = {
    index: number
    nom: string
    texte_a_afficher: string
    type: string
    valeur: number
    duree: number
    repetition: boolean
    horaire: horaire_maintenance[]
    alertes: Alerte[]
}

export function Modale_configurer_conditions({
                                                 cellule,
                                                 setCellule
                                             }: { cellule: Cellule, setCellule: (cellule: Cellule) => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_loading_btn] = useState(false);

    const [hide_modale, set_hide_modale] = useState(false);

    const [conditions, set_conditions] = useState<condition_unit[] | null>(null);
    const [index_compt, set_index_compt] = useState<number>(-1);

    const [valueAccordion, setValueAccordion] = useState<string | null>(null);

    const dispatch = useDispatch();
    const [condition_deconnexion_capteur, setConditionDecoCapteur] = useState<condition_unit | null>(null);

    useEffect(() => {
        if (opened) return;
        set_conditions(cellule.conditions.filter(c => ![8, 11].includes(c.slug)).map(condition => {
            let retour: condition_unit = {
                index: condition.id,
                nom: condition.nom,
                texte_a_afficher: condition.texte_a_afficher,
                type: "" + condition.slug,
                valeur: Number(condition.valeur_a_comparer),
                duree: condition.delais,
                horaire: condition.horaires_maintenance,
                alertes: condition.alertes,
                repetition: condition.repetition
            }
            return retour;
        }))

        let condition_deco = cellule.conditions.filter(c => c.slug === 8 && !c.is_hidden).map(condition => {
            let retour: condition_unit = {
                index: condition.id,
                nom: condition.nom,
                texte_a_afficher: condition.texte_a_afficher,
                type: "" + condition.slug,
                valeur: Number(condition.valeur_a_comparer),
                duree: condition.delais,
                horaire: condition.horaires_maintenance,
                alertes: condition.alertes,
                repetition: condition.repetition
            }
            return retour;
        })[0];
        setConditionDecoCapteur(condition_deco ? condition_deco : null)
    }, [cellule])

    useEffect(() => {
        if (opened)
        {
            dispatch(setPasApasEtat("modale_anomalie_open"))
        }
    }, [opened]);

    function handleAjouterCondition() {
        if (!conditions) return;
        let maj = conditions;
        maj.push({
            index: index_compt,
            nom: "",
            texte_a_afficher: "",
            type: "",
            valeur: 0,
            duree: 60,
            repetition: false,
            horaire: [],
            alertes: []
        });
        setValueAccordion("" + index_compt)
        set_index_compt(index_compt - 1);
        set_conditions(maj);
    }

    function handleSupprimerCondition(index: number) {
        if (!conditions) return;
        let continuer = true;
        conditions.map(condition => {
            if (condition.index === index) {
                let user_alertes = "";
                condition.alertes.map(alerte => {
                    user_alertes = user_alertes + "\n - " + getAffichageNom(alerte.utilisateur);
                })
                if (user_alertes !== "") {
                    if (!window.confirm("Ces utilisateurs ont une alertes sur cette condition : " + user_alertes + "\nEtes vous sûr de vouloir la supprimer ?")) {
                        continuer = false;
                    }
                }
            }

        })
        if (!continuer) return;
        let maj = conditions;
        set_index_compt(index_compt - 1);
        set_conditions(maj.filter(c => c.index !== index));
    }

    function afficherBoutonOuvertureModale() {
        // if (!cellule.extension || !cellule.extension.sonde) {
        if (!cellule.sonde_type) {
            return (
                <Tooltip label={"Vous devez configurer la cellule."} position={"bottom"}>
                    <button className={"sec"} disabled onClick={handlers.open}><IconeListCheck/>Anomalies
                    </button>
                </Tooltip>
            )
        }
        return (
            <button className={"sec"} onClick={handlers.open}><IconeListCheck/>Anomalies</button>
        )
    }


    function handleEnregistrer() {
        let test = "";
        conditions?.map(condition => {
            if (!condition.nom) test += "Le nom est vide.\n";
            if (!condition.texte_a_afficher) test += "Le texte est vide.\n";
        })
        let condition_up:condition_unit[] = conditions ? [...conditions] : []
        if (condition_deconnexion_capteur) condition_up.push(condition_deconnexion_capteur)
        if (test !== "") {
            alert(test);
            return;
        }

        set_loading_btn(true);
        ajax.put('/cellules/' + cellule.id + '/conditions', condition_up)
            .then(response => {
                //console.log(response);
                set_loading_btn(false);

                setCellule(response.data.cellule)
                handlers.close();

            }).catch(error => {
            set_loading_btn(false);
        });
    }

    function repasserEnModeRapide()
    {
        ajax.put('/cellules/' + cellule.id + '/statut', {
            "config_rapide": true
        })
            .then(response => {
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Configuration rapide restaurée",
                    message: '',
                    icon: <IconeCheck/>,
                });
                setCellule(response.data.cellule);
                handlers.close()
            })
            .catch(err => {});
    }

    if (!conditions) {
        return (
            <>
                <Modal opened={opened} onClose={handlers.close} title="Définir les anomalies">
                    <p className={"en-ligne"}><IconeLoader /> Chargement ...</p>
                </Modal>

                {afficherBoutonOuvertureModale()}
            </>
        );
    }

    let condition_possible: { value: string, label: string }[] = [];
    if (cellule.sonde_type?.type_de_donnee === "number") condition_possible = [
        {value: '0', label: '(MIN) Les mesures sont plus petite que...'},
        {value: '1', label: '(MAX) Les mesures sont plus grande que...'},
        {value: '9', label: '(ERR) La sonde apparait débranché depuis...'},
    ];
    if (cellule.sonde_type?.type_de_donnee === "bool") condition_possible = [
        {value: '2', label: 'Les mesures sont : ' + cellule.sonde_type?.affichage_faux},
        {value: '3', label: 'Les mesures sont : ' + cellule.sonde_type?.affichage_vrai},
    ];
    if (cellule.zone.nom === "base.config.appareils") condition_possible = [
        {value: '8', label: 'l\'enregistreur est deconnecté'},
    ];


    function generer_texte_a_afficher(condition: condition_unit) {
        let texte = "La cellule " + cellule.nom;
        let titre = condition.nom;
        if (condition.type === "0") {
            texte = texte + " est en dessous de ";
            texte = texte + condition.valeur + cellule.sonde_type?.affichage_suffix + " depuis " + condition.duree + " minutes";
            titre = "MIN"
        }
        if (condition.type === "1") {
            texte = texte + " est au dessus de ";
            texte = texte + condition.valeur + cellule.sonde_type?.affichage_suffix + " depuis " + condition.duree + " minutes";
            titre = "MAX"
        }
        if (condition.type === "2") {
            texte = texte + " est ";
            texte = texte + cellule.sonde_type?.affichage_faux + " depuis " + condition.duree + " minutes";
            titre = cellule.sonde_type?.affichage_faux ? cellule.sonde_type?.affichage_faux : "Etat normal"
        }
        if (condition.type === "3") {
            texte = texte + " est ";
            texte = texte + cellule.sonde_type?.affichage_vrai + " depuis " + condition.duree + " minutes";
            titre = cellule.sonde_type?.affichage_vrai ? cellule.sonde_type?.affichage_vrai : "Etat Anormal"
        }
        if (condition.type === "8") {
            texte = "Géré par le onChange du numberInput dans la modale.";
            titre = "/"
        }
        if (condition.type === "9") {
            texte = "La sonde " + cellule.sonde_type?.nom + " de la cellule " + cellule.nom + " apparait débranché depuis " + condition.duree + " minutes";
            titre = "Sonde débranché"
        }

        if (conditions){
            set_conditions(conditions?.map(_condition => {
                if (_condition.index === condition.index) {
                    _condition.texte_a_afficher = texte;
                    _condition.nom = titre;
                }
                return _condition
            }))
        }

        return condition;
    }

    return (
        <>
            <Modal opened={opened} onClose={handlers.close} title="Définir les anomalies"
                   className={hide_modale ? "modal_hide" : ""}>

                <Tabs defaultValue="cellule" className={"Tabs"}>
                    <Tabs.List>
                        <Tabs.Tab value="cellule">Cellule</Tabs.Tab>
                        <Tabs.Tab value="capteur">Enregistreur</Tabs.Tab>
                        <Tabs.Tab value="rapide">Config rapide</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="cellule">
                        {conditions.length > 0 ? (
                            <Accordion value={valueAccordion} onChange={setValueAccordion}>
                                {conditions.map(condition => {
                                    if (!cellule?.sonde_type) {
                                        return (<>Vous devez configurer la cellule pour pouvoir indiquer des anomalies.</>)
                                    }
                                    let titre = "";
                                    if (condition.type) {
                                        if (condition.type === "0") titre = titre + " - inférieur à " + condition.valeur + cellule.sonde_type.affichage_suffix + " pendant " + condition.duree + "min";
                                        if (condition.type === "1") titre = titre + " - supérieur à " + condition.valeur + cellule.sonde_type.affichage_suffix + " pendant " + condition.duree + "min";
                                        if (condition.type === "2") titre = titre + " - " + cellule.sonde_type.affichage_faux + " pendant " + condition.duree + "min";
                                        if (condition.type === "3") titre = titre + " - " + cellule.sonde_type.affichage_vrai + " pendant " + condition.duree + "min";
                                        if (condition.type === "8") titre = titre + " - Enregistreur déconnecté" + " pendant " + condition.duree + "min";
                                        if (condition.type === "9") titre = titre + " pendant " + condition.duree + "min";
                                    }
                                    else{

                                    }
                                    return (
                                        <Accordion.Item value={"" + condition.index} key={condition.index}>


                                            <Box style={{display: 'flex', alignItems: 'center'}}>
                                                <Accordion.Control>
                                                    {condition.nom ? condition.nom : "Nouvelle anomalie"}
                                                    <i style={{fontWeight: "lighter"}}>
                                                        {titre}
                                                    </i>
                                                </Accordion.Control>
                                                <button onClick={() => handleSupprimerCondition(condition.index)}><IconeTrashXmark/></button>
                                            </Box>
                                            <Accordion.Panel>

                                                {cellule.conditions.filter(c => c.id === condition.index && c.statut_validation > 0)[0] && (
                                                    <div className={"inline-tag red"}>
                                                        <strong>Anomalie {condition.nom} detectée.</strong>
                                                        <br/>
                                                        <i>Cliquer sur "Enregistrer" passera l'anomalie en résolue.</i>
                                                    </div>
                                                )}

                                                <Select
                                                    label="Il y a un problème quand :"
                                                    data={condition_possible}
                                                    value={condition.type}
                                                    onChange={value => {
                                                        set_conditions(conditions?.map(_condition => {
                                                            if (_condition.index === condition.index) {
                                                                if (value) _condition.type = value;
                                                                else _condition.type = "";
                                                            }
                                                            return _condition
                                                        }))
                                                        generer_texte_a_afficher(condition);
                                                    }}
                                                    className={"match_height"}
                                                />
                                                {(condition.type === "0" || condition.type === "1") && (

                                                    <NumberInput
                                                        label={(cellule.sonde_type.affichage_nom_mesure ? cellule.sonde_type.affichage_nom_mesure : "Valeur") + " limite :"}
                                                        defaultValue={0}
                                                        leftSection={"" + cellule.sonde_type?.affichage_prefix + cellule.sonde_type?.affichage_suffix}
                                                        value={condition.valeur}
                                                        onChange={value => {
                                                            set_conditions(conditions?.map(_condition => {
                                                                if (_condition.index === condition.index) {
                                                                    _condition.valeur = Number(value);
                                                                }
                                                                return _condition
                                                            }))
                                                            generer_texte_a_afficher(condition);
                                                        }}
                                                        size="xs"
                                                    />
                                                )}


                                                <NumberInput
                                                    label="Durée en minutes de validation avant de déclencher les alertes :"
                                                    defaultValue={60}
                                                    min={0}
                                                    step={15}
                                                    leftSection={<IconeStopwatch/>}
                                                    value={condition.duree}
                                                    onChange={value => {
                                                        set_conditions(conditions?.map(_condition => {
                                                            if (_condition.index === condition.index) {
                                                                _condition.duree = Number(value);
                                                            }
                                                            return _condition
                                                        }))
                                                        generer_texte_a_afficher(condition);
                                                    }}
                                                    size="xs"
                                                />


                                                <Modale_horaire_maintenance horaires={condition.horaire}
                                                                            setHoraires={value => {
                                                                                set_conditions(conditions?.map(_condition => {
                                                                                    if (_condition.index === condition.index) {
                                                                                        _condition.horaire = value;
                                                                                    }
                                                                                    return _condition
                                                                                }))
                                                                            }}
                                                                            setFlou={set_hide_modale}/>


                                                <TextInput
                                                    label="Nom de l'anomalie"
                                                    value={condition.nom}
                                                    placeholder={"MIN / MAX"}
                                                    onChange={event => {
                                                        set_conditions(conditions?.map(_condition => {
                                                            if (_condition.index === condition.index) {
                                                                _condition.nom = event.currentTarget.value;
                                                            }
                                                            return _condition
                                                        }))
                                                    }}
                                                />

                                                <Textarea
                                                    label="Texte à afficher lors des alertes :"
                                                    autosize
                                                    minRows={2}
                                                    maxRows={4}
                                                    value={condition.texte_a_afficher}
                                                    onChange={event => {
                                                        set_conditions(conditions?.map(_condition => {
                                                            if (_condition.index === condition.index) {
                                                                _condition.texte_a_afficher = event.currentTarget.value;
                                                            }
                                                            return _condition
                                                        }))
                                                    }}
                                                />

                                                <Switch
                                                    label="Renvoyer les alertes toutes les 15 minutes jusqu'à résolution ?"
                                                    checked={condition.repetition}
                                                    onChange={event => {
                                                        set_conditions(conditions?.map(_condition => {
                                                            if (_condition.index === condition.index) {
                                                                _condition.repetition = event.currentTarget.checked;
                                                            }
                                                            return _condition
                                                        }))
                                                    }}
                                                />

                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    )
                                })}

                            </Accordion>
                        ) : (
                            <div style={{padding: "1rem"}}><p>Aucune anomalie n'est configurée.</p></div>
                            
                        )}


                        <div className={"en-ligne"} style={{marginTop: "1rem"}}>
                            <button className={"sec"} onClick={handleAjouterCondition} disabled={!cellule.extension?.sonde} ><IconePlus/> Ajouter une
                                anomalie
                            </button>
                            <Loading_button is_loading={btn_loading}
                                            onClick={handleEnregistrer} icone={"floppy-disk"}
                                            disabled={!cellule.extension?.sonde}>
                                Enregistrer
                            </Loading_button>
                            {!cellule.extension?.sonde && (
                                <p className={"notice"} style={{width: "100%"}}>Vous devez activer la cellule pour parametrer les anomalies.</p>
                            )}
                        </div>

                    </Tabs.Panel>

                    <Tabs.Panel value="capteur">
                        <div style={{padding: "1rem"}}>
                            {!cellule.extension ? (
                                <p>Vous devez configurer la cellule pour parametrer les anomalies capteur.</p>
                            ) : (
                                <>
                                    <p className={"inline-tag"} style={{marginBottom: "0.5rem"}}>Une detection automatique de déconnexion est déjà activée pour votre enregistreur.
                                        Vous la retrouverez dans la liste sur laquelle connecter vos alertes.</p>
                                    <Checkbox
                                        label={"Activer une detection secondaire avancée de déconnexion pour l'enregistreur"}
                                        checked={condition_deconnexion_capteur !== null}
                                        onChange={event => {
                                            if (event.target.checked)
                                            {
                                                setConditionDecoCapteur({
                                                    index: cellule.conditions.filter(c => c.slug === 8 && !c.is_hidden)[0]
                                                        ? cellule.conditions.filter(c => c.slug === 8 && !c.is_hidden)[0].id
                                                        : -1000,
                                                    nom: "Enregistreur hors ligne (avancé)",
                                                    texte_a_afficher: "L'enregistreur "
                                                        + cellule.extension?.capteur.nom
                                                        + " (ref " + cellule.extension?.capteur.ref
                                                        + ") est déconnecté depuis " + 60 + " minutes",
                                                    type: "8",
                                                    valeur: 0,
                                                    duree: 60,
                                                    repetition: false,
                                                    horaire: [],
                                                    alertes: []
                                                })
                                            }
                                            else{
                                                setConditionDecoCapteur(null)
                                            }
                                        }
                                        }
                                    />
                                </>
                            )}

                            {condition_deconnexion_capteur && (
                                <>
                                    <br />
                                    <label>
                                        <p style={{fontSize: "var(--texte-size-petit)"}}>Durée en minutes de deconnexion avant de déclencher les alertes :</p>
                                        <NumberInput
                                            defaultValue={60}
                                            leftSection={<IconeStopwatch/>}
                                            min={15}
                                            value={condition_deconnexion_capteur.duree}
                                            onChange={value => {
                                                let maj = {...condition_deconnexion_capteur}
                                                maj.duree = Number(value);
                                                maj.texte_a_afficher = "l'enregistreur " + cellule.extension?.capteur.nom
                                                    + " (réf " + cellule.extension?.capteur.ref
                                                    + ") est déconnecté depuis " + Number(value) + "min";
                                                maj.texte_a_afficher = "l'enregistreur "
                                                + cellule.extension?.capteur.nom
                                                + " (ref " + cellule.extension?.capteur.ref
                                                + ") est déconnecté depuis " + maj.duree + " minutes";
                                                setConditionDecoCapteur(maj)
                                            }}
                                            size="xs"
                                        />
                                    </label>
                                    <br />


                                    <Modale_horaire_maintenance horaires={condition_deconnexion_capteur.horaire}
                                                                setHoraires={value => {
                                                                    let maj = {...condition_deconnexion_capteur}
                                                                    maj.horaire = value
                                                                    setConditionDecoCapteur(maj)
                                                                }}
                                                                setFlou={set_hide_modale}/>
                                </>

                            )}
                        </div>


                        <div className={"en-ligne"} style={{marginTop: "1rem"}}>
                            <Loading_button is_loading={btn_loading}
                                            onClick={handleEnregistrer} icone={"floppy-disk"}
                                            disabled={!cellule.extension?.sonde}>
                                Enregistrer
                            </Loading_button>
                            {!cellule.extension?.sonde && (
                                <p className={"notice"} style={{width: "100%"}}>Vous devez activer la cellule pour parametrer les anomalies.</p>
                            )}
                        </div>

                    </Tabs.Panel>

                    <Tabs.Panel value="rapide">
                        <div style={{padding: "1rem"}}>
                            {!cellule.extension ? (
                                <p>Vous devez configurer la cellule pour paramétrer les anomalies capteur.</p>
                            ) : (
                                <>
                                    <p className={""} style={{marginBottom: "0.5rem"}}>
                                        Vous avez activé la configuration d'anomalie avancée pour cette cellule.
                                        <br />
                                        <br />
                                        Vous pouvez, si vous le souhaitez, repasser en configuration rapide mais les modifications que vous avez faite en mode avancés seront perdues.
                                    </p>

                                    <button onClick={repasserEnModeRapide}>Repasser en configuration rapide</button>


                                </>
                            )}
                        </div>


                    </Tabs.Panel>
                </Tabs>


            </Modal>


            {afficherBoutonOuvertureModale()}
        </>
    );
}
