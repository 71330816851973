import React, {useEffect, useRef, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, ORIGIN, STATUS, Step} from "react-joyride";
import {Organisation} from "../../../types/organisation";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {pasApasData, setPasApas, setPasApasTour} from "../../../store/pasApasSlice";
import {RootState} from "../../../store/store";
import {Pas_a_pas_services} from "../pas_a_pas_services";

export function Modifier_une_sonde() {

    const [tourIntro, setTourIntro] = useState<Array<Step>>([]);
    const [tourStepIndex, setTourStepIndex] = useState<number>(0);
    const [tourRun, setTourRun] = useState<boolean>(true);

    const location = useLocation();

    const dispatch = useDispatch();

    const pasApas = useSelector<RootState, pasApasData>(state => state.pasApas)

    const organisations_compt = useSelector<RootState, number>(state => state.organisation.list.length)

    useEffect(() => {

        let run_etat:"keep"|"true"|"false"|"delay"|"delay+1" = "keep";
        let tour: Array<Step> = [];
        let index = tourStepIndex;

        // @ts-ignore
        run_etat = Pas_a_pas_services.gestion_etat_all();

        if (location.pathname.startsWith("/organisation/")) {
            Pas_a_pas_services.closeNav()
            run_etat = "false";


            tour = [
                {
                    target: '.zones',
                    content:
                        <>
                            <p>Sur cette page, vous trouverez vos appareils et dessous vos cellules.</p>
                            <strong>Cliquez sur la cellule qui porte les mesures prise par l'ancienne sonde</strong>
                            <button onClick={() => setTourRun(false)}>J'ai compris</button>
                        </>,
                    placement: 'center',
                    spotlightClicks: true,
                    disableBeacon: true,
                    hideFooter: true
                },
            ];
            // tour = tour.concat([
            //     {
            //         target: "#nexiste-pas",
            //         content:
            //             <>
            //                 <p>Cliquez ici pour ajouter un nouvel appareil.</p>
            //             </>,
            //         placement: 'right',
            //         spotlightClicks: true,
            //     },])


            if (pasApas.etat === "page_orga_loaded") {
                run_etat = "delay";
                index = 0;
            }
        }


        else if (location.pathname.startsWith("/cellule/")) {

            Pas_a_pas_services.closeNav()
            run_etat = "false";



            index = Pas_a_pas_services.step_ouvrir_menu(tour, index)
            // if (Pas_a_pas_services.checkIfMenuIsOpen()) index++;
            tour = tour.concat([
                {
                    target: '#carte_action_config_cellule',
                    content:
                        <>
                            <p>Pour garder la continuité des mesures, vous allez re-configurer cette cellule pour lui indiquer la nouvelle sonde</p>
                            <strong>Cliquez sur Configurer cette cellule</strong>
                        </>,
                    placement: 'bottom',
                    spotlightClicks: true,
                    disableBeacon: true,
                    hideFooter: true,
                    disableOverlay: Pas_a_pas_services.is_mobile(),
                    // disableScrolling: true
                },
                {
                    target: '#btn_suivant',
                    content:
                        <>
                            <p>Cliquez sur suivant</p>
                        </>,
                    placement: 'left',
                    spotlightClicks: true,
                    disableBeacon: true,
                    hideFooter: true,
                    disableScrolling: true
                },
                {
                    target: '.mantine-Modal-body',
                    content:
                        <>
                            <p>Ici, indiquez la nouvelle source d'information de la cellule (Appareil, extension et <strong>sonde</strong>).</p>
                            <p>Ensuite, cliquez sur Suivant</p>

                            <button onClick={() => setTourRun(false)}>J'ai compris</button>
                        </>,
                    placement: 'center',
                    spotlightClicks: true,
                    disableBeacon: true,
                    disableScrolling: true,
                    hideFooter: true,
                },
                {
                    target: '#btn_suivant',
                    content:
                        <>
                            <p>Cliquez sur suivant sur chaque page jusqu'à confirmer la nouvelle configuration.</p>

                        </>,
                    placement: 'left',
                    spotlightClicks: true,
                    disableBeacon: true,
                    disableScrolling: true,
                    hideFooter: true,
                    disableOverlay: Pas_a_pas_services.is_mobile()
                },
                {
                    target: '#btn_valider',
                    content:
                        <>
                            <p>Cliquez sur Valider pour enregistrer votre nouvelle sonde.</p>
                        </>,
                    placement: 'left',
                    spotlightClicks: true,
                    disableBeacon: true,
                    disableScrolling: true,
                    hideFooter: true,
                },
            ]);

            if (pasApas.etat === "page_cellule_loaded" || document.querySelector("#carte_action_config_cellule")) {
                run_etat = "delay";
                index = 0;
            }

            if (pasApas.etat === "modale_config_cellule_opened") {
                run_etat = "delay";
                index = index+1;

            }
            if (pasApas.etat === "modale_config_cellule_page2") {
                run_etat = "delay";
                index = index+2;
            }
            if (pasApas.etat === "modale_config_cellule_page3") {
                run_etat = "delay";
                index = index+3;
            }
            if (pasApas.etat === "modale_config_cellule_page3b") {
                run_etat = "delay";
                index = index+3;
            }
            if (pasApas.etat === "modale_config_cellule_page4") {
                run_etat = "delay";
                index = index+4;
            }
            if (pasApas.etat === "modale_config_cellule_enregistré") {
                Pas_a_pas_services.reset();
            }
        }
        else{

            run_etat = "delay";
            index = 0;

            tour = [
                {
                    target: 'body',
                    content:
                        <>
                            <p>Vous aviez une sonde déjà branché sur votre appareil, vous avez changé cette sonde et vous voulez indiquer quelle nouvelle sonde est désormais branchée.</p>
                        </>,
                    placement: 'center',
                    spotlightClicks: false,
                },
            ];


            index = Pas_a_pas_services.step_ouvrir_nav(tour, index);
            tour.push({
                target: "#nav_organisations",
                content:
                    <>
                        <p>Pour commencer, rendez vous sur la page de votre organisation</p>
                        {organisations_compt > 1 && (
                            <p>Cliquez sur le bouton Organisations et cliquez sur votre organisation dans le menu qui apparait</p>
                        )}

                    </>,
                placement: 'right',
                spotlightClicks: false,
            })
        }

        console.log(pasApas.etat)
        console.log("index : " + index)

        setTourIntro(tour)

        setTourStepIndex(index)

        switch (run_etat) {
            case "delay":
                setTourRun(false)
                // setTourStepIndex(0)
                setTimeout(() => {
                    setTourRun(true)
                }, Pas_a_pas_services.delay)
                break;


            // @ts-ignore
            case "delay+1":
                setTourRun(false)
                setTourStepIndex(tourStepIndex+1)
                setTimeout(() => {
                    setTourRun(true)
                }, Pas_a_pas_services.delay)
                break;


            // @ts-ignore
            case "false":
                setTourRun(false)
                setTourStepIndex(0)
                break;


            // @ts-ignore
            case "keep":
                // Rien faire
                break;


            // @ts-ignore
            case "true":
                // setTourStepIndex(0)
                setTourRun(true)
                break;
        }





    }, [location.pathname, pasApas]);

    // useEffect(() => {
    //     alert(pasApas.etat)
    // }, [pasApas.etat]);


    return (
        <Joyride
            steps={tourIntro}
            stepIndex={tourStepIndex}
            callback={(data) => Pas_a_pas_services.handleJoyrideCallback(data, setTourRun, setTourStepIndex)}
            run={tourRun}
            styles={Pas_a_pas_services.styles}
            locale={Pas_a_pas_services.locale}
            continuous
        />
    )
}