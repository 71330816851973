import {Capteur} from "./capteur";
import {Extension} from "./extension";

export type Regle_paiement = {
        "premiereCellule": number,
        "deuxiemeCellule": number,
        "autre_cellules": number,
        "plage_capteur1a4": number,
        "plage_capteur5a9": number,
        "plage_capteur10a14": number,
        "plage_capteur15a29": number,
        "plage_capteur30etPlus": number,
        // "globale": number,
}

export const regles_par_defaut:Regle_paiement = {
        "premiereCellule": 0,
        "deuxiemeCellule": 100,
        "autre_cellules": 50,
        "plage_capteur1a4": 0,
        "plage_capteur5a9": 10,
        "plage_capteur10a14": 15,
        "plage_capteur15a29": 20,
        "plage_capteur30etPlus": 25,
        // "globale": 0
}