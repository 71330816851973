import React, {useEffect, useState} from "react";

import ajax from "../../../../services/AxiosInterceptor";
import {Tooltip} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {AxiosResponse} from "axios";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {Offre_abonnement} from "../../../../types/statut_cellule";

export function Offre_abonnements_admin({offres}:{offres:Offre_abonnement[]|null}) {

    const [donnees, setDonnees] = useState<Offre_abonnement[] | null>(() => offres);

    const Offre_abonnement_Tableau_admin:typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {key: "nom", nom: "Nom"},
        {key: "informations", wrapper: (texte:string) => (
                <Tooltip label={texte} onClick={() => alert(texte)}>
                    <p className={"inline-tag"}><IconeEye /></p>
                </Tooltip>),
            spanClassname: "no-wrap flex_align_center",
            desc: "Description affichée au client",
            type: "textarea"
        },
        {action_editer: true},
    ]
    function handleEnregistrer(statut:Offre_abonnement|null, setIsLoading:(isLoading:boolean) => void) {

        if (!statut)
        {
            alert( "Erreur, abonnement cellule est : " + JSON.stringify(statut))
            return;
        }
        setIsLoading(true);
        let ajax_response = (response:AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'L\'offre est enregistré',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setIsLoading(false);

            if (statut.id < 0){
                let new_statut:Offre_abonnement = response.data.offre_abonnements;
                if (donnees) setDonnees([...donnees, new_statut])
            }else{
                if (donnees) setDonnees(donnees?.map(donnee => {
                    if (donnee.id === statut.id){
                        return statut
                    }
                    return donnee;
                }))
            }
        }
        if (statut?.id < 0)
        {
            ajax.post("/offreabonnements", statut).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        } else{
            ajax.put("/offreabonnements/" + statut.id, statut).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        }

    }

    useEffect(() => {
        setDonnees(offres)
    }, [offres]);

    return (


            <Tableau_helper
                typeDonnee={Offre_abonnement_Tableau_admin}
                donnees={donnees}
                handleEnregistrer={handleEnregistrer}
                formatNew={{
                    id : -1,
                    nom : "",
                    informations : "",
                }}
            />


    );
}
