import React from 'react';
import ReactDOM from 'react-dom/client';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import './styles/index.scss';
import './styles/pages.scss';
import './styles/custom_classes.scss';
import './styles/form_inputs.scss';
import './styles/html_components.scss';
import './styles/modales.scss';
import './components/sidebar/sidebar.scss';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import {store} from './store/store'
import {Initial_requests} from "./services/init & router/initial_requests";
import {MantineProvider} from '@mantine/core';
import {Notifications} from "@mantine/notifications";
import ErrorBoundary from "./services/ErrorBoundary";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <MantineProvider>
        <Provider store={store}>
            <Notifications position="top-right"/>
            <ErrorBoundary>
                <Initial_requests/>
            </ErrorBoundary>
        </Provider>
    </MantineProvider>

    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
