import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Auth} from "../types/auth";
import {deleteAuthState, saveAuthState} from "../services/localstorage";

let initial_state: Auth = {
    token: "",
    expiration: "2000-01-01T00:00:00Z",
    user: {
        username: "Non connecté",
        roles: []
    },
}

export const authSlice = createSlice({
    name: "auth",
    initialState:initial_state as Auth,
    reducers: {
        logIn: (state, action:PayloadAction<Auth>) => {
            state = action.payload;
            return state
        },
        logOut: (state, action:PayloadAction<void>) => {
            state = initial_state
            deleteAuthState();
            return state
        }
    }
})

export const {logIn, logOut} = authSlice.actions;