import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {IconeLoader} from "../../icone/icone";
import {Checkbox, Modal, Select, Tooltip} from "@mantine/core";
import {Loading_button} from "../../loading_button/loading_button";
import {User} from "../../../types/user";
import ajax from "../../../services/AxiosInterceptor";
import {Organisation} from "../../../types/organisation";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {Cellule} from "../../../types/cellule";
import {addZonesToOrganisation} from "../../../store/organisationSlice";
import "./modale_alertes_users.scss"
import {Alerte} from "../../../types/alerte";
import {Zone} from "../../../types/zone";
import {Condition} from "../../../types/condition";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {getAffichageNom, getAffichageNomShort} from "../../../services/UserService";
import {IconeBallotCheck} from "../../icone/solid_react/ballot-check";
import {estDesactivee} from "../../../pages/Admin/Orga_admin/Orga_admin";

export function Modale_alertes_users_condition({organisation_id, conditions, refreshParent, forcer}:{ organisation_id: number, conditions:Condition[], refreshParent:()=>void, forcer?:boolean}) {
    const [opened, handlers] = useDisclosure(forcer ?? false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    type user_canaux = {
        user: User
        canaux: string[]
    }

    useEffect(() => {
        if (forcer) handlers.open();
        else handlers.close();
    }, [forcer]);


    const organisation = useSelector<RootState, Organisation>(state => state.organisation.list.filter(o => o.id === organisation_id)[0]);
    const [orga_all_users, setOrga_all_users] = useState<user_canaux[] | null>(null);

    const [hoveredUser, setHoveredUser] = useState<User|null>(null);
    const alertes_types = ['SMS', 'EMAIL', 'TEL', 'PUSH'];
    // const alertes_types = ['EMAIL'];


    const [conditionsSelected, setConditionsSelected] = useState<Condition | null>(null);
    useEffect(() => {
        setConditionsSelected(conditions.length > 0 ? conditions[0] : null);
    }, [conditions])


    useEffect(() => {
        if (!opened) return;
        setIsLoading(true)
        ajax.get("/organisations/" + organisation_id + "/utilisateurs").then(response => {
            let users:User[] = response.data.utilisateurs
            if (conditionsSelected) {
                setOrga_all_users(users.map(u => {
                    let _canaux = (conditionsSelected.alertes.filter(a => a.utilisateur.id === u.id).length > 0
                        ? conditionsSelected.alertes.filter(a => a.utilisateur.id === u.id)[0].canaux
                        : [])
                    return {
                        user:u,
                        canaux: _canaux
                    }
                }));
            }

            setIsLoading(false)
        }).catch(error => {
        })
    }, [opened])

    function handleEnregistrer() {
        set_btn_loading(true);
        ajax.put("/organisations/" + organisation_id + "/alertes", {
            "ids": conditions.map(c => c.id),
            "users_canaux" : orga_all_users
        }).then(response => {
            refreshParent();
            handlers.close();
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }


    if (!organisation.permissions?.includes("Gestion_alertes_toutes")){
        return <></>
    }

    return (
        <>
            <button onClick={handlers.open} disabled={conditions.length === 0 || estDesactivee(organisation)} id={"btn_gerer_alertes"}><IconeBallotCheck/> Gérer les alertes</button>


            <Modal className={"modale-width-libre"} opened={opened} onClose={handlers.close} title="Gérer les alertes">


                    <div className="tableauContainer tableau_condition">

                        {isLoading || !conditionsSelected
                            ? <div className={"en-ligne"}><IconeLoader /> Chargement...</div>
                            : (
                                <>
                                    <div>
                                        Anomalies sélectionnées
                                        <span>
                                            {conditions.map(c => <p className={"inline-tag"}>- "{c.nom}" de la cellule "{c.helper_cellule?.nom}"</p>)}
                                        </span>
                                        <i style={{fontSize: "var(--texte-size-petit)"}}>(Prérempli depuis {conditionsSelected?.nom})</i>
                                    </div>
                                    <table>
                                        <thead>
                                        <tr>
                                            {orga_all_users?.map(user => (
                                                <th key={user.user.id} className={hoveredUser?.id === user.user.id ? "user_hover" : ""}>
                                                    <Tooltip label={getAffichageNom(user.user) + " ("+user.user.email+")"}>
                                                        <p>{getAffichageNomShort(user.user)}</p>

                                                    </Tooltip>

                                                </th>
                                            ))}

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {/*{conditions.map(c => (*/}
                                        <tr>
                                            <>
                                                {orga_all_users?.map(user => {
                                                    return (
                                                        <td key={user.user.id} className={hoveredUser?.id === user.user.id ? "user_hover" : ""}
                                                            onMouseEnter={() => setHoveredUser(user.user)}
                                                            onMouseLeave={() => setHoveredUser(null)}>
                                                            <div className={"ligne_checkbox"}>
                                                                {alertes_types.map(alerte_type => (
                                                                    <Checkbox
                                                                        key={user.user.id + alerte_type}
                                                                        size="xs"
                                                                        label={alerte_type}
                                                                        disabled={
                                                                            (alerte_type === "SMS" && !user.user.tel)
                                                                            || (alerte_type === "TEL" && !user.user.tel)
                                                                            || (alerte_type === "PUSH" && !user.user.tel)
                                                                            || (alerte_type === "EMAIL" && user.user.statut === "Non validé")
                                                                        }
                                                                        checked={user.canaux.filter(c => c === alerte_type ).length > 0}
                                                                        onChange={e => {
                                                                            setOrga_all_users(orga_all_users.map(o => {
                                                                                if (o.user.id === user.user.id)
                                                                                {
                                                                                    o.canaux = o.canaux.filter(c => c !== alerte_type)
                                                                                    if (e.target.checked) o.canaux.push(alerte_type)
                                                                                }
                                                                                return o;
                                                                            }))
                                                                        }}
                                                                    />
                                                                ))}
                                                            </div>

                                                        </td>
                                                    )
                                                })}

                                            </>


                                        </tr>
                                        {/*))}*/}
                                        </tbody>
                                    </table>
                                </>

                            )
                        }



                    </div>



                <div className={"en-ligne justify_end"}>


                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} disabled={conditions.length === 0} icone={"check"}>Appliquer pour toutes les anomalies sélectionnées</Loading_button>
                </div>

            </Modal>
        </>

    )
}