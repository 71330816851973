import React, {useEffect, useState} from "react";

import ajax from "../../../../services/AxiosInterceptor";
import {Tooltip, Code, Modal, NumberInput} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {AxiosResponse} from "axios";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {Organisation} from "../../../../types/organisation";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {addOrganisations} from "../../../../store/organisationSlice";
import {Regle_paiement, regles_par_defaut} from "../../../../types/regle_paiement";
import {useDisclosure} from "@mantine/hooks";
import {IconePenToSquare} from "../../../../components/icone/solid_react/pen-to-square";
import {IconeFloppyDisk} from "../../../../components/icone/solid_react/floppy-disk";
import {Loading_button} from "../../../../components/loading_button/loading_button";

type modal_edit_data = {regle:Regle_paiement, id_orga: number}
export function Regles_paiement_admin() {

    const donnees = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const dispatch = useDispatch()
    const [modale_detail_is_open, modale_detail_handlers] = useDisclosure(false);
    const [modaleDetail, setModaleDetail] = useState<modal_edit_data|null>(null);

    const [is_loading, setIs_loading] = useState(false);

    const [regles_defaut, setRegles_defaut] = useState<Regle_paiement|null>(null);

    const organisations_type_donnee:typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {key: "nom", nom: "Nom"},
        {key: "etat", nom: "Etat", type: "select",
            desc: "Statut de l'organisation",
            select_data: [
                {"value": "Active", "label" : "Active => état normal"},
                {"value": "New", "label" : "New => Organisation nouvelle qui n'a pas encore eu de prélèvement."},
                {"value": "Desac_admin", "label" : "Organisation désactivé par un administrateur"},
                {"value": "Desac_paiement", "label" : "Organisation désactivé pour défaut de paiement"},
            ]},
        {key: "prochainPrelevement", nom: "Prochain prélèvement", type: "date"},
        {key: "orga_parent", nom:"Parent", type: "select", select_searchable: true,
            desc: "Organisation parent.",
            edit_display_value: (orga:number|null) => {return (donnees.find(o => o.id == orga)?.nom ?? orga+"")},
            select_data: donnees.map(o => {
                return {"value": o.id+"", "label" : o.nom}
            })
        },
        {key: "balance", nom: "Balance", suffix: "€", noedit: true},
        {key: "desactivation_texte",
            wrapper: (texte:string) => (
                <Tooltip label={texte} onClick={() => alert(texte)}>
                    <p className={"inline-tag"}><IconeEye /></p>
                </Tooltip>),
            spanClassname: "no-wrap flex_align_center",
            desc: "Texte affiché aux clients lorsque l'organisation est désactivée",
            type: "textarea"
        },
        // {action_editer: true},
        {key: "regles_paiements", nom: "Règles", noedit: true,
            wrapper: (texte:Regle_paiement, full_row:Organisation) => (
                <button onClick={() => ouvrirModaleDetail({regle: texte, id_orga: full_row.id})}>
                    <IconePenToSquare />
                </button>
            )},
    ]

    useEffect(() => {
        ajax.get("/be/regles_paiements").then(response => {
            setRegles_defaut(response.data.regles)
        })
    }, []);
    function ouvrirModaleDetail(data:modal_edit_data)
    {
        // @ts-ignore
        if(data.regle == ""){
            data.regle = regles_defaut ?? regles_par_defaut
        }
        setModaleDetail(data);
        modale_detail_handlers.open();
    }
    function setDonnees(organisations:Organisation[])
    {
        dispatch(addOrganisations({message: "maj", organisations: organisations}))
    }



    function handleEnregistrerRegle() {

        if (!modaleDetail?.regle || (!modaleDetail?.id_orga && modaleDetail?.id_orga !== 0))
        {
            alert( "Erreur, règles est : " + JSON.stringify(modaleDetail?.regle))
            return;
        }
        setIs_loading(true);

        // alert(JSON.stringify(modaleDetail.regle)); return;


        if (modaleDetail.id_orga === 0)
        {

            ajax.put("/be/regles_paiements", modaleDetail.regle).then(res => {
                notifications.show({
                    // id: 'enregistre',
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Validé!",
                    message: 'Enregistré',
                    icon: <IconeCheck/>,
                    // className: 'my-notification-class',
                    loading: false,
                });


                setIs_loading(false);
                modale_detail_handlers.close();


                setRegles_defaut(modaleDetail.regle)

            }).catch(error => {

            });
        }
        else{

            ajax.put("/organisations/" + modaleDetail.id_orga, {
                "regles_paiements": modaleDetail.regle
            }).then(res => {
                notifications.show({
                    // id: 'enregistre',
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Validé!",
                    message: 'Enregistré',
                    icon: <IconeCheck/>,
                    // className: 'my-notification-class',
                    loading: false,
                });


                setIs_loading(false);
                modale_detail_handlers.close();


                let edit_orga:Organisation[] = donnees.map(e => {
                    let _e = {...e}
                    if (_e.id == modaleDetail.id_orga)
                    {
                        _e.regles_paiements = modaleDetail.regle
                        return _e
                    }
                    return _e
                })
                setDonnees(edit_orga)

            }).catch(error => {

            });
        }


    }


    return (
        <>
            <div className={"en-ligne spacebetween"} style={{margin: "0.5rem 0"}}>
                <h2>Règles de paiements des organisations</h2>
                {/*<button onClick={() => ouvrirModaleDetail({regle: texte, id_orga: 0})}><IconePenToSquare /> Règles par défaut</button>*/}
                <Loading_button is_loading={regles_defaut === null} onClick={() => {
                    if (regles_defaut) ouvrirModaleDetail({regle: regles_defaut, id_orga: 0})
                }}><IconePenToSquare /> Règles par défaut</Loading_button>
            </div>

            <Tableau_helper
                typeDonnee={organisations_type_donnee}
                donnees={donnees}
                // handleEnregistrer={handleEnregistrer}
                // formatNew={{
                //     id : -1,
                //     nom : "",
                //     informations : "",
                // }}
            />

            <Modal opened={modale_detail_is_open} onClose={modale_detail_handlers.close} title="Détails">
                {/*<NumberInput*/}
                {/*    label={"Promotion globale"}*/}
                {/*    value={modaleDetail?.regle["globale"]}*/}
                {/*    onChange={ e => {*/}
                {/*        if (modaleDetail?.regle) {*/}
                {/*            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))*/}
                {/*            edit.regle["globale"] = Number(e);*/}
                {/*            setModaleDetail(edit)*/}
                {/*        }*/}
                {/*    }}*/}
                {/*/>*/}
                <NumberInput
                    label={"1ere cellule"}
                    value={modaleDetail?.regle["premiereCellule"]}
                    onChange={ e => {
                        if (modaleDetail?.regle) {
                            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))
                            edit.regle["premiereCellule"] = Number(e);
                            setModaleDetail(edit)
                        }
                    }}
                />
                <NumberInput
                    label={"2ème cellule"}
                    value={modaleDetail?.regle["deuxiemeCellule"]}
                    onChange={ e => {
                        if (modaleDetail?.regle) {
                            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))
                            edit.regle["deuxiemeCellule"] = Number(e);
                            setModaleDetail(edit)
                        }
                    }}
                />
                <NumberInput
                    label={"Cellule suivantes"}
                    value={modaleDetail?.regle["autre_cellules"]}
                    onChange={ e => {
                        if (modaleDetail?.regle) {
                            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))
                            edit.regle["autre_cellules"] = Number(e);
                            setModaleDetail(edit)
                        }
                    }}
                />
                <NumberInput
                    label={"De 0 à 4 enregistreurs"}
                    value={modaleDetail?.regle["plage_capteur1a4"]}
                    onChange={ e => {
                        if (modaleDetail?.regle) {
                            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))
                            edit.regle["plage_capteur1a4"] = Number(e);
                            setModaleDetail(edit)
                        }
                    }}
                />
                <NumberInput
                    label={"De 5 à 9 enregistreurs"}
                    value={modaleDetail?.regle["plage_capteur5a9"]}
                    onChange={ e => {
                        if (modaleDetail?.regle) {
                            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))
                            edit.regle.plage_capteur5a9 = Number(e);
                            setModaleDetail(edit)
                        }
                    }}
                />
                <NumberInput
                    label={"De 10 à 14 enregistreurs"}
                    value={modaleDetail?.regle["plage_capteur10a14"]}
                    onChange={ e => {
                        if (modaleDetail?.regle) {
                            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))
                            edit.regle["plage_capteur10a14"] = Number(e);
                            setModaleDetail(edit)
                        }
                    }}
                />
                <NumberInput
                    label={"De 15 à 29 enregistreurs"}
                    value={modaleDetail?.regle["plage_capteur15a29"]}
                    onChange={ e => {
                        if (modaleDetail?.regle) {
                            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))
                            edit.regle["plage_capteur15a29"] = Number(e);
                            setModaleDetail(edit)
                        }
                    }}
                />
                <NumberInput
                    label={"A partir de 30 enregistreurs"}
                    value={modaleDetail?.regle["plage_capteur30etPlus"]}
                    onChange={ e => {
                        if (modaleDetail?.regle) {
                            let edit:modal_edit_data = JSON.parse(JSON.stringify(modaleDetail))
                            edit.regle["plage_capteur30etPlus"] = Number(e);
                            setModaleDetail(edit)
                        }
                    }}
                />

                <Loading_button is_loading={is_loading} onClick={handleEnregistrerRegle}><IconeFloppyDisk /> Enregistrer</Loading_button>
            </Modal>
        </>


    );
}
