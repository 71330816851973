import "./logs_viewer.scss"
import React, {useEffect, useState} from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    SortingState,
    createColumnHelper, Row, ColumnDef
} from "@tanstack/react-table";
import {IconeLoader} from "../icone/icone";
import {Code, Modal, NativeSelect, Popover, Tooltip} from "@mantine/core";
import {Logs} from "../../types/logs";
import ajax from "../../services/AxiosInterceptor";
import {Tableau} from "../tableau/Tableau";
import {afficherDateTime, DateTZ} from "../../services/GestionDates";
import {useDisclosure} from "@mantine/hooks";
import {DateInput, DatePicker, DatePickerInput} from "@mantine/dates";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Cellule} from "../../types/cellule";
import {Organisation} from "../../types/organisation";
import {texte_est_inclus} from "../../services/GestionTextes";
import {getAffichageNom} from "../../services/UserService";
import {IconeCircle} from "../icone/solid_react/circle";
import {IconeCircleStar} from "../icone/solid_react/circle-star";
import {IconeCircleX} from "../icone/solid_react/circle-x";
import {IconeCircleExclamation} from "../icone/solid_react/circle-exclamation";
import {IconeCircleInfo} from "../icone/solid_react/circle-info";
import {IconeUserSlash} from "../icone/solid_react/user-slash";


export function Logs_viewer({ api_query_url, nbe_jour_load, mode_admin = false }: { api_query_url: string, nbe_jour_load:number, mode_admin?: boolean }) {

    const [logs, setLogs] = useState<Logs[]|null>(null);
    const [statuts, setStatuts] = useState<{[key: string]: boolean}>({});

    const [filteredlogs, setFilteredlogs] = useState<Logs[]|null>(null);

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState({})

    let semaineAgo = new DateTZ();
    semaineAgo.setDate(semaineAgo.getDate() - nbe_jour_load);
    const [dates, setDates] = useState<[Date | null, Date | null]>([semaineAgo, new DateTZ()]);

    const columnHelper = createColumnHelper<Logs>()

    const [modale_detail_is_open, modale_detail_handlers] = useDisclosure(false);
    const [modaleDetail, setModaleDetail] = useState("Aucun détail.");

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    function getOrganisationNameByID(id:number)
    {
        let txt = id + "";
        organisations.map(orga => {
            if (orga.id === id)
            {
                txt = orga.nom
            }
        })
        return txt;
    }

    function genererTypeIDLink(row:Logs){
        if (row.type === "Cellule" && row.cellule){
            return <Link to={"/Cellule/" + row.cellule.id}>{row.cellule.nom}</Link>
        }
        if (row.type === "Capteur"){
            return <Link to={"/Capteur/" + row.id_type}>{row.id_type}</Link>
        }
        return <>{row.id_type}</>
    }

    let columns : ColumnDef<Logs, any>[] = [
        // columnHelper.accessor(row => row.id, {
        //     id: 'ID',
        //     header: () => 'ID',
        //     cell: info =>
        //         info.getValue() != null && (
        //             <span>
        //             <strong className={"th_mobile"}>ID : </strong>
        //                 {info.getValue()}
        //         </span>
        //         ),
        // }),
        columnHelper.accessor('statut', {
            id: 'Statut',
            header: () => '',
            cell: info => {
                let icone = <p>{info.getValue()}</p>;
                let couleur = "#00000020";
                let texte = info.getValue();
                if (info.getValue() === "min") icone = <IconeCircle/>
                if (info.getValue() === "maj") icone = <IconeCircleStar/>
                if (info.getValue() === "err") icone = <IconeCircleX/>
                if (info.getValue() === "wrn") icone = <IconeCircleExclamation/>
                if (info.getValue() === "prms") icone = <IconeUserSlash/>
                if (info.getValue() === "cstm") icone = <IconeCircleX/>
                if (info.getValue() === "maj") couleur = "var(--valid-color)";
                if (info.getValue() === "cstm") couleur = "var(--warning-color)";
                if (info.getValue() === "prms") couleur = "var(--warning-color)";
                if (info.getValue() === "err") couleur = "var(--error-color)";
                if (info.getValue() === "wrn") couleur = "var(--error-color)";
                if (info.getValue() === "min") texte = "Log mineur";
                if (info.getValue() === "maj") texte = "Log majeur";
                if (info.getValue() === "err") texte = "Erreur API";
                if (info.getValue() === "wrn") texte = "Erreur majeure";
                if (info.getValue() === "prms") texte = "Erreur de permission";
                if (info.getValue() === "cstm") texte = "Erreur mineure";
                return (<>
                        <strong className={"th_mobile"}>Statut : </strong>
                        <Tooltip label={"Statut : " + texte}>
                            <p style={{color: couleur}}>{icone}</p>
                        </Tooltip>

                    </>
                )},
            enableColumnFilter: false
        }),

    ];
    if (mode_admin)
    {
        columns = columns.concat([
            columnHelper.accessor(row => row, {
                id: "Organisation",
                header: () => "Organisation",
                cell: info =>
                    info.getValue() != null && (
                        <span>
                            <strong className={"th_mobile"}>Organisation : </strong>
                            <Link to={"/Organisation/" + info.getValue().id_organisation}>{getOrganisationNameByID(info.getValue().id_organisation)}</Link>
                        </span>
                    ),
                filterFn: (    row: Row<Logs>,
                               columnId: string,
                               filterValue) => {
                    let recherche = getOrganisationNameByID(row.getValue<Logs>(columnId).id_organisation) + "   " + row.getValue<Logs>(columnId).id_organisation;
                    return texte_est_inclus(recherche, filterValue)
                },
            }),
            columnHelper.accessor(row => row.type, {
                id: "Type",
                header: () => "Type",
                cell: info =>
                    info.getValue() != null && (
                        <span>
                    <strong className={"th_mobile"}>Type : </strong>
                            {info.getValue()}
                </span>
                    ),
            }),
            columnHelper.accessor(row => row, {
                id: "Type ID",
                header: () => "Type ID",
                cell: info =>
                    info.getValue() != null && (
                        <span>
                            <strong className={"th_mobile"}>Type ID : </strong>
                            {genererTypeIDLink(info.getValue())}
                        </span>
                    ),

                sortingFn: (rowA: Row<Logs>,
                            rowB: Row<Logs>,
                            columnId: string) => {
                    let dateA = rowA.getValue<Logs>(columnId).id_type
                    let dateB = rowB.getValue<Logs>(columnId).id_type
                    if (dateA < dateB) return -1
                    else if (dateA == dateB) return 0
                    else return 1
                },
                filterFn: (    row: Row<Logs>,
                               columnId: string,
                               filterValue) => {
                    let recherche = "";
                    if (row.getValue<Logs>(columnId).id_type) recherche += row.getValue<Logs>(columnId).id_type + " "
                    if (row.getValue<Logs>(columnId).cellule) recherche += row.getValue<Logs>(columnId).cellule?.nom
                    return texte_est_inclus(recherche, filterValue)
                },
            }),
        ]);
    }


    columns = columns.concat([
        columnHelper.accessor(row => row, {
            id: 'Date',
            header: () => 'Date',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Date : </strong>
                        {afficherDateTime(new DateTZ(info.getValue().date))}
                </span>
                ),
            sortingFn: (rowA: Row<Logs>,
                        rowB: Row<Logs>,
                        columnId: string) => {
                let dateA = new DateTZ(rowA.getValue<Logs>(columnId).date)
                let dateB = new DateTZ(rowB.getValue<Logs>(columnId).date)
                if (dateA < dateB) return -1
                else if (dateA == dateB) return 0
                else return 1
            },
            filterFn: (    row: Row<Logs>,
                           columnId: string,
                           filterValue) => {
                if (row.getValue<Logs>(columnId).date)
                {
                    return afficherDateTime(new DateTZ(row.getValue<Logs>(columnId).date)).includes(filterValue)
                }
                return false;
            },
        }),
        columnHelper.accessor(row => row.titre, {
            id: "Titre",
            header: () => "Titre",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Titre : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row, {
            id: "Utilisateur",
            header: () => "Utilisateur",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Utilisateur : </strong>
                        {info.getValue().id_utilisateur && info.getValue().utilisateur
                            ? (
                                <Tooltip label={info.getValue().utilisateur.email}>
                                        <div>{getAffichageNom(info.getValue().utilisateur)}</div>
                                </Tooltip>

                            )
                            : (info.getValue().id_utilisateur ? "Utilisateur #" + info.getValue().id_utilisateur : "Système")}
                </span>
                ),
            sortingFn: (rowA: Row<Logs>,
                        rowB: Row<Logs>,
                        columnId: string) => {
                let dateA = rowA.getValue<Logs>(columnId).id_utilisateur
                let dateB = rowB.getValue<Logs>(columnId).id_utilisateur
                if (dateA < dateB) return -1
                else if (dateA == dateB) return 0
                else return 1
            },
            filterFn: (    row: Row<Logs>,
                           columnId: string,
                           filterValue) => {
                let recherche = "";
                if (row.getValue<Logs>(columnId).id_utilisateur) recherche += row.getValue<Logs>(columnId).id_utilisateur + " "
                if (row.getValue<Logs>(columnId).utilisateur) recherche += row.getValue<Logs>(columnId).utilisateur?.nom_affichage + " " + row.getValue<Logs>(columnId).utilisateur?.nom_famille + " " + row.getValue<Logs>(columnId).utilisateur?.email
                return texte_est_inclus(recherche, filterValue)
            },

        }),
        columnHelper.accessor(row => row.details, {
            id: "Détails",
            header: () => "Détails",
            cell: info =>
                info.getValue() != null && (
                    <span style={{fontSize: "var(--texte-size-petit)"}}>
                        <strong className={"th_mobile"}>Détails : </strong>
                        <a href="#" onClick={() => ouvrirModaleDetail(info.getValue())}>
                            {info.getValue().slice(0, 10)} {info.getValue().length > 9 && "..."}
                        </a>

                    </span>
                ),
        }),
    ]);

    function ouvrirModaleDetail(detail:string)
    {
        setModaleDetail(detail);
        modale_detail_handlers.open();
    }

    useEffect(() => {
        queryLogs();
    }, [dates])

    function queryLogs()
    {
        if (dates[0] && dates[1])
        {
            let date1 = dates[0];
            date1.setHours(0,0,0);
            let date2 = dates[1];
            date2.setHours(23,59,59);
            setLogs(null)
            ajax.get(api_query_url + "?debut=" + date1.toISOString() + "&fin=" + date2.toISOString()).then(response => {
                let _logs:Logs[] = response.data.logs;
                setLogs(_logs);
                let _statuts:{[key: string]: boolean} = {};
                _logs.map(log => {
                    _statuts[log.statut] = true;
                })
                setStatuts(_statuts);
            }).catch(e => {
            })
        }
    }

    useEffect(() => {
        if (logs)
        {
            setFilteredlogs(logs.filter(log => statuts[log.statut]))
        }

    }, [statuts])

    const table = useReactTable<Logs>({
        data: filteredlogs ? filteredlogs : [],
        columns: columns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })


    return (
        <>

            <div className={"en-ligne en-tete-page"} style={{justifyContent: "space-between"}}>

                <div className={"flex-row en-ligne"}>



                        <Popover width={200} position="bottom" withArrow shadow="md">
                            <Popover.Target>
                                <button className={"sec"}><IconeCircleInfo/> Choisir les statuts à afficher</button>
                            </Popover.Target>
                            <Popover.Dropdown>
                                {Object.keys(statuts).map(statut => {
                                    return (
                                        <div key={statut} className="colonnes_selector">
                                            <label>
                                                <input
                                                    {...{
                                                        type: 'checkbox',
                                                        checked: statuts[statut],
                                                        onChange: (e) => {
                                                            let _s = {...statuts};
                                                            _s[statut] = !_s[statut]
                                                            setStatuts(_s);
                                                        },
                                                    }}
                                                />{' '}
                                                {statut === "min" && "Logs mineurs"}
                                                {statut === "maj" && "Logs majeurs"}
                                                {statut === "err" && "Erreurs API"}
                                                {statut === "wrn" && "Erreurs majeures"}
                                                {statut === "prms" && "Erreurs de permission"}
                                                {statut === "cstm" && "Erreurs mineures"}
                                            </label>
                                        </div>
                                    )
                                })}
                            </Popover.Dropdown>
                        </Popover>

                </div>

                <div className={"flex-row en-ligne"}>
                    <p>Charger entre : </p>
                    <DatePickerInput
                        type="range"
                        value={dates}
                        onChange={setDates}
                        locale="fr"
                    />
                </div>


                <div className={"flex-row en-ligne"}>
                    <div>{table.getRowModel().rows.length} lignes affichés</div>
                    <NativeSelect
                        className={"select_nbe_lignes match_height"}
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                        data={["5", "10", "20", "50", "100", "500"]}
                    />
                </div>

            </div>

            {logs === null ? (
                <>
                    <br/>
                    <div className={"en-ligne"} style={{justifyContent: "center"}}><IconeLoader />Chargement...</div>
                </>
            ) : (
                <Tableau table={table} donnees={logs}/>
            )}


            <Modal opened={modale_detail_is_open} onClose={modale_detail_handlers.close} title="Détails">
                <Code block>{modaleDetail}</Code>
            </Modal>

        </>

    )
}