import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Organisation} from "../types/organisation";
import {Zone} from "../types/zone";
import {All_permissions} from "../types/permissions";
import {Capteur} from "../types/capteur";
import {Hub} from "../types/hub";
import {Cellule} from "../types/cellule";

export const cellulesSlice = createSlice({
    name: "cellules",
    initialState:[] as Cellule[],
    reducers: {
        addCellule: (state, action:PayloadAction<Cellule>) => {

            state = state.filter(cell => cell.id !== action.payload.id);

            state.push(action.payload);
            return state;
        },
    }
})

export const {addCellule} = cellulesSlice.actions;