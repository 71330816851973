import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {Organisation} from "../types/organisation";
import {useSelector} from "react-redux";
import env_config from "../env_config";
import React from "react";

export type pasApasData = {
    tour: React.ReactElement|null
    etat: string
}

let initial_state:pasApasData = {
    tour: null,
    etat: ""
}

export const pasApasSlice = createSlice({
    name: "interface",
    initialState:initial_state as pasApasData,
    reducers: {
        setPasApas: (state, action:PayloadAction<pasApasData>) => {
            return action.payload
        },
        setPasApasTour: (state, action:PayloadAction<React.ReactElement|null>) => {
            state.tour = action.payload
            return state;
        },
        setPasApasEtat: (state, action:PayloadAction<string>) => {
            state.etat = action.payload
            return state;
        },
    }
})

export const {setPasApasTour, setPasApasEtat, setPasApas} = pasApasSlice.actions;
