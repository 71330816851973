import {Cellule} from "../../types/cellule";
import React, {ReactNode, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../services/AxiosInterceptor";
import {Modal, TextInput} from "@mantine/core";
import {Loading_button} from "../../components/loading_button/loading_button";
import {Organisation} from "../../types/organisation";
import {notifications} from "@mantine/notifications";
import env_config from "../../env_config";
import {IconeCheck} from "../icone/solid_react/check";

export function Modale_creer_zone({
                                      organisation,
                                      setOrganisation
                                  }: { organisation: Organisation|null, setOrganisation: React.Dispatch<Organisation | null> }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);
    const [nom, set_nom] = useState("");

    // if (forcer) handlers.open();
    useEffect(() => {
        if (organisation) handlers.open();
        if (!organisation) handlers.close();
    }, [organisation])

    function handleEnregistrer() {
        if (organisation){
            set_save_btn_loading(true);
            ajax.post('/organisations/' + organisation?.id + "/zones", {
                "nom": nom
            }).then(response => {
                let _orga = JSON.parse(JSON.stringify(organisation));
                if (!_orga.zones) _orga.zones = [];
                _orga.zones.push(response.data.zone);

                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Zone créee.",
                    message: 'Vous pouvez ajouter des cellules.',
                    icon: <IconeCheck/>,
                });

                handleClose(_orga)
                set_save_btn_loading(false);
            }).catch(error => {
                set_save_btn_loading(false);
            })
        }
    }

    function handleClose(org:Organisation){
        setOrganisation(org);

        handlers.close();
    }

    if (!organisation) return <></>

    return (
        <>
            <Modal opened={opened} onClose={() => handleClose(organisation)} title="Créer une zone" zIndex={100000}>
                <div className={"form"}>
                    <TextInput type="text" value={nom} onChange={event => set_nom(event.target.value)}
                               description={"Les zones servent à regrouper les cellules. Nous conseillons de créer une zone par emplacement physique commun, par exemple \"Cuisine\" ou \"Chambre froide\"."}
                               placeholder={"Nom de la zone"}/>
                    <Loading_button is_loading={save_btn_loading}
                                    onClick={handleEnregistrer}>Enregistrer</Loading_button>
                </div>
            </Modal>
        </>

    )
}