import {configureStore} from "@reduxjs/toolkit";
import {userSlice} from "./userSlice";
import {authSlice} from "./authSlice";
import {organisationSlice} from "./organisationSlice";
import {capteurModaleSlice} from "./capteur_modale_Slice";
import {interfaceSlice} from "./interfaceSlice";
import {cellulesSlice} from "./cellulesSlice";
import {pasApasSlice} from "./pasApasSlice";


export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        organisation: organisationSlice.reducer,
        cellule: cellulesSlice.reducer,
        capteurModale: capteurModaleSlice.reducer,
        auth: authSlice.reducer,
        interface: interfaceSlice.reducer,
        pasApas: pasApasSlice.reducer,
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch