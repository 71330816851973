import {setPasApas} from "../../store/pasApasSlice";
import {store} from "../../store/store";
import {ACTIONS, CallBackProps, EVENTS, ORIGIN, STATUS, Step, Styles} from "react-joyride";
import {PartialDeep} from "type-fest";
import React from "react";
import {setMobile_sidebar_show, setNavMenuOpenedState} from "../../store/interfaceSlice";

export class Pas_a_pas_services {

    static delay = 900;
    static reset()
    {
        store.dispatch(setPasApas({
            tour: null, etat: ""
        }))
    }

    static handleJoyrideCallback = (data: CallBackProps, setTourRun:(b:boolean) => void, setTourStepIndex:(b:number) => void) => {
        const {action, index, origin, status, type} = data;

        // alert("ACTION : " + action + "\nSTATUT : " + status + "\norigin : " + origin + "\nindex : " + index)
        // alert(JSON.stringify(data.controlled));

        if (action === ACTIONS.CLOSE) {
            // do something
            setTourRun(false);

            if (origin === ORIGIN.BUTTON_CLOSE) {
                Pas_a_pas_services.reset();
            }
        }

        // @ts-ignore
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour

            setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

        } else { // @ts-ignore
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                // You need to set our running state to false, so we can restart if we click start again.
                setTourRun(false);
                if (action === ACTIONS.NEXT && status === STATUS.FINISHED)
                {
                    Pas_a_pas_services.reset();
                }
            }
        }

        // console.groupCollapsed(type);
        // console.log(data); //eslint-disable-line no-console
        // console.groupEnd();
    };

    static locale = {
        back: 'Retour',
        close: 'Arrêter le guide',
        last: 'Arrêter le guide',
        next: 'Suivant',
        open: 'Ouvrir le guide',
        skip: 'Passer'
    }

    static styles:any = {
        options: {
            // arrowColor: '#e3ffeb',
            // backgroundColor: '#e3ffeb',
            // overlayColor: 'rgba(79, 26, 0, 0.4)',
            primaryColor: 'var(--main-color)',
            zIndex: 100000
        },
        tooltipContainer: {
            lineHeight: 1.4,
            textAlign: 'left',
        },
    }

    static checkIfNavIsOpen = () => {
        if (window.innerWidth > 1200)
        {
            return false;
        }
        return store.getState().interface.nav_menu_opened;
    }

    static is_mobile = () => {
        return window.innerWidth < 1200
    }

    static checkIfMenuIsOpen = () => {
        if (window.innerWidth > 1200)
        {
            return false;
        }
        return store.getState().interface.mobile_sidebar_show;
        // if (store.getState().pasApas.etat === "menu_opened")
        // {
        //     setTourStepIndex(step_index+1);
        // }
        // if (store.getState().pasApas.etat === "nav_opened")
        // {
        //     setTourStepIndex(step_index+1);
        // }
    }

    static step_ouvrir_nav = (tour:Step[], index:number):number => {
        if (window.innerWidth <= 1200
            && !store.getState().interface.nav_menu_opened)
        {
            let step:Step = {
                target: "#burger-nav",
                content:
                    <>
                        <p>Ouvrez le menu de navigation</p>
                    </>,
                placement: 'bottom-end',
                spotlightClicks: false,
                hideFooter: true,
                disableBeacon: true
            }
            tour.push(step)
        }
        return index
    }
    static step_ouvrir_menu = (tour:Step[], index:number):number => {
        if (window.innerWidth <= 1200
            && !store.getState().interface.mobile_sidebar_show)
        {
            let step:Step = {
                target: ".mobile_trigger_notifications",
                content:
                    <>
                        <p>Cliquez ici pour accéder aux options de la page</p>
                    </>,
                placement: 'bottom-start',
                spotlightClicks: false,
                hideFooter: true,
                disableBeacon: true
            }
            tour.push(step)
        }
        // if (window.innerWidth <= 1200
        //     && store.getState().interface.mobile_sidebar_show)
        // {
        //     console.log("++++++++1111111111111111")
        //     return index+1;
        // }
        // console.log("00000000000")
        return index
    }

    static closeNav()
    {
        store.dispatch(setNavMenuOpenedState(false))
    }

    static closeMenu()
    {
        store.dispatch(setMobile_sidebar_show(false))
    }

    static gestion_etat_all()
    {
        if (store.getState().pasApas.etat === "menu_orga_nav_open") return false
        return "keep"
    }

}