import './Mot_de_passe_oublie.scss';
import React, {useEffect, useState} from "react";
import {Checkbox, PasswordInput, TextInput} from "@mantine/core";
import {IconeLoader} from "../../../../components/icone/icone";
import {Link, useParams} from "react-router-dom";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import ajax from "../../../../services/AxiosInterceptor";
import {IconeAt} from "../../../../components/icone/solid_react/at";
import {IconePhone} from "../../../../components/icone/solid_react/phone";
import {IconeMobile} from "../../../../components/icone/solid_react/mobile";
import {IconeMobileScreen} from "../../../../components/icone/solid_react/mobile-screen";
import env_config from "../../../../env_config";

export function Mot_de_passe_oublie() {

    const [email, set_email] = useState("");

    const [btn_loading, set_btn_loading] = useState(false);

    function handleValidation(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email)))
        {
            alert("L'email renseigné n'est pas valide.")
            return;
        }

        set_btn_loading(true)

        ajax.post("/ask_resetpass/" + email, {
            "email" : email,
        }).then(response => {
            window.location.href = "/login?msg=resetpassask";

            set_btn_loading(false)
        }).catch(error => {
            set_btn_loading(false)
        })

    }

    return (
        <div className={"app inscription-token"}>
            <div className={"inscription_form"}>
                <img src={"/logo_freshtime.svg"} alt=""/>


                    <form action="Auth/Inscription/Token#" onSubmit={handleValidation}>
                        <div className={"fullwidth"}>
                            <h3>Mot de passe oublié</h3>

                            <br/>
                            <p style={{fontSize: "var(--texte-size-petit)"}}>Indiquez votre adresse email ci-dessous pour recevoir un lien de ré-initialisation de votre mot de passe. </p>
                        </div>

                        <label className={"fullwidth"}>
                            Adresse email *
                            <TextInput
                                type="email"
                                name="email"
                                placeholder="email@email.fr"
                                leftSection={<IconeAt />}
                                required
                                value={email}
                                onChange={e => set_email(e.target.value)}
                            />
                        </label>


                        <Loading_button is_loading={btn_loading} onClick={() => {
                        }} className={"big"}>Envoyer l'email</Loading_button>

                    </form>



            </div>
        </div>

    );
}
