import './initial_requests.scss'
import React, {ReactNode, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Auth} from "../../types/auth";
import ajax from "../AxiosInterceptor";
import {addUser} from "../../store/userSlice";
import {addOrganisations, setRegles_paiement_defaut} from "../../store/organisationSlice";
import {IconeLoader} from "../../components/icone/icone";
import {loadAuthState} from "../localstorage";
import {logIn, logOut} from "../../store/authSlice";
import {createBrowserRouter, Router, RouterProvider} from "react-router-dom";
import {Erreur_404} from "../../pages/404";
import ErrorBoundary from "../ErrorBoundary";
import {loadInterfaceFromLocalStorage, setInterfaceState} from "../../store/interfaceSlice";
import {IconeXmark} from "../../components/icone/solid_react/xmark";
import {Bandeau} from "../../components/bandeau/bandeau";
import {pasApasData} from "../../store/pasApasSlice";
import {Inscription_token} from "../../pages/Auth/Inscription/Token/Inscription_token";
import {Mon_compte} from "../../pages/Users/Mon_compte/Mon_compte";
import {Mesures} from "../../pages/Users/Mesures/Mesures";
import {Notifications_tableau} from "../../pages/Users/Notifications/Notifications_tableau";
import {Paiements} from "../../pages/Users/Paiements/Paiements";
import {Login} from "../../pages/Auth/Login/Login";
import {Prestataire} from "../../pages/Admin/Prestataire/Prestataire";
import {Capteur_admin} from "../../pages/Admin/Capteur_admin/Capteur_admin";
import {Bureau_etudes} from "../../pages/Admin/Bureau_etudes/Bureau_etudes";
import {Inscription} from "../../pages/Auth/Inscription/Inscription";
import {Contacts} from "../../pages/Users/Contacts/Contacts";
import {Orga_admin} from "../../pages/Admin/Orga_admin/Orga_admin";
import {Rapports_pdf} from "../../pages/API/Rapports_pdf/Rapports_pdf";
import {Accueil} from "../../pages/Users/Accueil/Accueil";
import {Logs_cellule_viewer} from "../../components/logs_viewer/logs_cellule_viewer";
import {En_direct} from "../../pages/Users/En_direct/En_direct";
import {Hubs} from "../../pages/Users/Hubs/Hubs";
import {Anomalies} from "../../pages/Users/Anomalies/Anomalies";
import {Mot_de_passe_oublie} from "../../pages/Auth/Inscription/Mot_de_passe_oublie/Mot_de_passe_oublie";
import {Mot_de_passe_reset} from "../../pages/Auth/Inscription/Mot_de_passe_oublie/Mot_de_passe_reset";
import {Estimation_cout_freshtime} from "../../pages/Auth/Estimation_cout_freshtime/Estimation_cout_freshtime";
export function Initial_requests() {
    const [icone, setIcone] = useState(<IconeLoader />)
    const [chargement_texte, setCT] = useState("Connexion au serveur...")
    const auth = useSelector<RootState, Auth>(state => state.auth)
    const dispatch = useDispatch();
    const router_custom:{path:string, element:React.JSX.Element, doitEtreLogguer:boolean}[] = [
        {
            path: "/login",
            element: <Login /> ,
            doitEtreLogguer: false,
        },
        {
            path: "/logout",
            element: <Login />,
            doitEtreLogguer: false,
        },
        {
            path: "/inscription/token/:token",
            element: <Inscription_token />,
            doitEtreLogguer: false,
        },
        {
            path: "/inscription",
            element: <Inscription />,
            doitEtreLogguer: false,
        },
        {
            path: "/estimation-prix",
            element: <Estimation_cout_freshtime />,
            doitEtreLogguer: false,
        },
        {
            path: "/reset_pass",
            element: <Mot_de_passe_oublie />,
            doitEtreLogguer: false,
        },
        {
            path: "/reset_pass/:token",
            element: <Mot_de_passe_reset />,
            doitEtreLogguer: false,
        },
        {
            path: "/",
            element: <Accueil />,
            doitEtreLogguer: true,
        },
        {
            path: "/cellule/:celluleId",
            element: <Mesures />,
            doitEtreLogguer: true,
        },
        {
            path: "/cellule/:celluleId/historique",
            element: <Logs_cellule_viewer />,
            doitEtreLogguer: true,
        },
        {
            path: "/organisation/:orgaId",
            element: <Orga_admin />,
            doitEtreLogguer: true,
        },
        {
            path: "/capteur/:capteurId",
            element: <Capteur_admin />,
            doitEtreLogguer: true,
        },
        {
            path: "/notifications",
            element: <Notifications_tableau />,
            doitEtreLogguer: true,
        },
        {
            path: "/hubs",
            element: <Hubs />,
            doitEtreLogguer: true,
        },
        {
            path: "/organisation/:orgaId/anomalies",
            element: <Anomalies />,
            doitEtreLogguer: true,
        },
        {
            path: "/bureau",
            element: <Bureau_etudes />,
            doitEtreLogguer: true,
        },
        {
            path: "/prestataire",
            element: <Prestataire />,
            doitEtreLogguer: true,
        },
        {
            path: "/mon-compte",
            element: <Mon_compte />,
            doitEtreLogguer: true,
        },
        {
            path: "/gestion",
            element: <Paiements />,
            doitEtreLogguer: true,
        },
        {
            path: "/en-direct",
            element: <En_direct />,
            doitEtreLogguer: true,
        },
        {
            path: "/contacts",
            element: <Contacts />,
            doitEtreLogguer: true,
        },
        {
            path: "/rapport/:ids/:debut/:fin/:timezone",
            element: <Rapports_pdf />,
            doitEtreLogguer: true,
        },
        {
            path: "*",
            element: <Erreur_404 />,
            doitEtreLogguer: true,
        },
    ]
    const router = createBrowserRouter(router_custom.map(r => {
        return {
            path: r.path,
            element: <InsideRouterWrapper>{r.element}</InsideRouterWrapper>,
        }
    }));

    function chemin_hors_login()
    {
        // if(window.location.pathname === "/login") return true;
        // if(window.location.pathname.includes("/inscription")) return true;
        // if(window.location.pathname.includes("/reset_pass")) return true;
        return !!router_custom.find(r => window.location.pathname.includes(r.path) && !r.doitEtreLogguer);


    }

    const [routes, setRoutes] = useState(false);

    function queryUserSelf(){
        setCT("Connexion au serveur...")
        ajax.get('/user/getSelf')
            .then(response => {
                dispatch(addUser(response.data));
                queryOrganisations();
            })
            .catch(error => {
                setCT("Erreur de connexion. Merci de ré-essayer ultérieurement.")
                setIcone(<IconeXmark />);
            });
    }
    function queryOrganisations(){
        setCT("Chargement des organisations...")
        ajax.get('/organisations')
            .then(response => {
                dispatch(addOrganisations(response.data));
                dispatch(setRegles_paiement_defaut(response.data.regles_paiement_defaut));
                setCT("OK")
                setLoaderAsRoutes();
            })
            .catch(error => {
                setCT("Erreur : " + error.message)
                console.log(error);
            });
    }

    useEffect(() => {
        if(chemin_hors_login()) return;
        let local_auth = loadAuthState();
        if (local_auth === undefined || local_auth.token == "")
        {
            window.location.href = "/login";
            return;
        }
        dispatch(logIn(local_auth))

        dispatch(setInterfaceState(loadInterfaceFromLocalStorage()));

    }, [])

    useEffect(() => {
        if(chemin_hors_login()) return;
        if(auth.token === "")
        {
            return;
        }
        queryUserSelf();
    }, [auth])

    function setLoaderAsRoutes()
    {
        setRoutes(true)
    }


    if(chemin_hors_login()) return ( <RouterProvider router={router} /> );


    return (
        <>
            {routes ? (
                <>
                    <RouterProvider router={router} />
                    <Bandeau />
                </>
            ) : (
                <div className={"fs_loading"}>
                    {icone}

                    {chargement_texte}
                </div>
            )}
        </>

    );
}

function InsideRouterWrapper({children}:{children: ReactNode})
{

    const pasApas_tour = useSelector<RootState, pasApasData>(state => state.pasApas)

    return (
        <>
            <ErrorBoundary>
                {children}
                {pasApas_tour.tour}
            </ErrorBoundary>
            
        </>
    )
}