import React, {ReactElement, useEffect, useRef, useState} from 'react';
import './carte_mesure.scss'
import {IconeLoader} from "../icone/icone";
import {IconeTag} from "../icone/solid_react/tag";

export function Carte_mesure_loading(
    {
        titre,
        handleClick
    }: {
        titre: string,
        handleClick?: () => any,
    }) {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const titreRef = useRef<HTMLParagraphElement>(null);


    useEffect(() => {
        const titreElement = titreRef.current;
        if (titreElement) {
            //alert("SW : " + titreElement.scrollWidth)
            //alert("CW : " + titreElement.clientWidth)
            setIsOverflowing(titreElement.scrollWidth > titreElement.clientWidth);
        }
    }, []);
    let titre_jsx = (<>{titre}</>);

    return (
        <div className={"carte_mesure grey loading_anim"} onClick={handleClick}>
            <div className={"titre"}>
                <IconeTag />
                <div className={"titre_container"}>
                    <p className={"titre_texte" + (isOverflowing ? ' slide' : '')} ref={titreRef}>{titre_jsx}</p>
                </div>
            </div>

            <p className={"valeur"}><IconeLoader /></p>

            <div className={"conditions"}>_</div>

        </div>
    );
}