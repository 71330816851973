import './Login.scss';
import {ajax} from '../../../services/AxiosInterceptor'
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";
import {logIn, logOut} from "../../../store/authSlice";
import {saveAuthState} from "../../../services/localstorage";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {retry} from "@reduxjs/toolkit/query";
import {Checkbox, PasswordInput, Switch, TextInput} from "@mantine/core";
import {deleteUser} from "../../../store/userSlice";
import {clearOrganisations} from "../../../store/organisationSlice";
import env_config from "../../../env_config";

export function Login() {
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [cookies, setCookies] = useState(false);
    const [cookiesErreur, setCookiesErreur] = useState(false);
    const [rememberme, setRememberme] = useState(false);
    // const [btn_texte, set_BT] = useState((<button className={"big"}>Connexion</button>));
    const [notice, setNotice] = useState((<></>));

    const [btn_connexion_loading, set_btn_loading] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(deleteUser());
        dispatch(logOut())
        dispatch(clearOrganisations())


        if(window.location.pathname === "/logout") {
            // alert("logout :'(");
            localStorage.clear(); // Vide tout le storage, peut poser des soucis avec l'interface save en mémoire
        }
    }, [])


    const handleConnexion = (event:React.MouseEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!cookies){
            setCookiesErreur(true);
            return;
        }
        set_btn_loading(true)
        ajax.post('/login', {
            "username": email,
            "password": pass,
            "rememberme": rememberme
        })
            .then(response => {
                console.log("login");
                dispatch(logIn(response.data));
                saveAuthState(response.data);
                navigate("/");
            })
            .catch(error => {
                console.log(error);

                setPass("");
                if (error.code && error.code === "ERR_NETWORK")
                {
                    setNotice(<div className={"notice"}>Problème de connexion au service en ligne. Merci de ré-essayer ultérieurement.</div>)
                }
                else{
                    setNotice(<div className={"notice"}>Adresse email ou mot de passe incorrect.</div>)
                }

                set_btn_loading(false)
            });
    }
    function afficherNoticeDeconnexion()
    {
        return (
            <div className={"notice"}>Vous avez été déconnecté. Merci de vous reconnecter</div>
        )
    }
    function afficherNoticeInscription()
    {
        return (
            <div className={"notice green"}>Votre compte est validé. Vous pouvez désormais vous connecter : </div>
        )
    }
    function afficherNoticeResetPassAsk()
    {
        return (
            <div className={"notice green"}>Un email pour ré-initialiser votre mot de passe à été envoyé sur votre adresse email.</div>
        )
    }
    function afficherNoticeResetPass()
    {
        return (
            <div className={"notice green"}>Votre mot de passe à bien été ré-initialisé! Connectez vous dès maintenant avec : </div>
        )
    }

    return (
        <div className={"app login"}>
            <div className={"login_form"}>
                <img src={"/logo_freshtime.svg"} alt=""/>
                <form action="Users/Login#" onSubmit={handleConnexion}>

                    {searchParams.get('msg') == "deconnexion" ? afficherNoticeDeconnexion() : ""}
                    {searchParams.get('msg') == "inscription" ? afficherNoticeInscription() : ""}
                    {searchParams.get('msg') == "resetpassask" ? afficherNoticeResetPassAsk() : ""}
                    {searchParams.get('msg') == "resetpass" ? afficherNoticeResetPass() : ""}
                    {notice}

                    <label htmlFor="email">
                        Adresse e-mail
                        <TextInput type="text" name={"email"} id={"email"} required
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                        />
                    </label>
                    <label htmlFor="pass">
                        Mot de passe
                        <PasswordInput name={"pass"} id={"pass"} required
                               value={pass}
                               onChange={(e) => setPass(e.target.value)}
                        />
                    </label>
                    <label htmlFor="cookies" className={"checkbox"} style={{width: "100%"}}>
                        <Switch name={"cookies"} id={"cookies"}
                                checked={cookies}
                                label={<label>J'accepte les <a href={env_config.url_cookies} target="_blank">cookies essentiels au fonctionnement de la plateforme</a></label>}
                                onChange={(e) => setCookies(e.target.checked)}
                                error={cookiesErreur ? "Vous devez accepter les cookies essentiels pour pouvoir vous connecter." : undefined}
                        />
                    </label>
                    <label htmlFor="remember-me" className={"checkbox"}>
                        <Switch name={"remember-me"} id={"remember-me"}
                               checked={rememberme}
                                label={"Rester connecté"}
                               onChange={(e) => setRememberme(e.target.checked)}
                        />
                    </label>
                    <Loading_button className={"big"} is_loading={btn_connexion_loading} onClick={() => { return }}>Connexion</Loading_button>

                    <p style={{textAlign:"right", width: "100%"}}><a href="/reset_pass">Mot de passe perdu?</a></p>

                </form>
                <button className={"big btn_inscri"} onClick={() => navigate("/inscription")}>
                    Nouveau client : <br />S'inscrire pour ajouter des appareils

                </button>
            </div>
        </div>

    );
}
