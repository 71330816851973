import React, {useEffect, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from "react-joyride";
import {Organisation} from "../../types/organisation";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {pasApasData} from "../../store/pasApasSlice";

export function Anomalie_en_masse_tuto() {

	const [tourIntro, setTourIntro] = useState<any>([]);
	const [tourStepIndex, setTourStepIndex] = useState<number>(0);
	const [tourRun, setTourRun] = useState<boolean>(false);

	const pasApas = useSelector<RootState, pasApasData>(state => state.pasApas)


	useEffect(() => {
		let tour:any = [
			{
				target: 'body',
				content:
					<div className={"flex-col"} style={{gap: "0.5rem"}}>
						<strong>Bienvenue sur la page des anomalies. </strong>
						<p>Cette page liste toutes les anomalies de votre organisations, que ce soit les seuils MIN et MAX de vos cellules mais aussi les anomalies automatiques comme la détection de déconnexion de vos appareils.</p>
						<p>Voici un petit tour rapide des fonctionnalités.</p>
					</div>,
				placement: 'center',
				spotlightClicks: false,
			},
			{
				target: ".tableauContainer",
				content:
					<div className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Le tableau liste vos anomalies.</p>
						<p>Pour pouvoir les modifier ou en gérer les alertes, vous devez d'abord cocher les lignes de celles qui que vous souhaitez modifier.</p>
					</div>,
				placement: 'center',
				spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: "#btn_gerer_alertes",
				content:
					<>
						<p>Ici, vous pouvez changer les alertes des utilisateurs de l'organisation, pour les anomalies que vous avez sélectionnées.</p>
					</>,
				placement: 'bottom',
				spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: "#btn_modifier_anomalies",
				content:
					<>
						<p>Ici, vous pouvez modifier ou supprimer toutes les anomalies sélectionnées.</p>
					</>,
				placement: 'bottom',
				spotlightClicks: true,
			},
			{
				target: "#btn_voir_actives",
				content:
					<div  className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Dans le tableau, la colonne Statut vous indique si l'anomalie est actuellement détectée ou non.</p>
						<p>Ce bouton vous permet de trier le tableau pour lister uniquement les anomalies actuellement détectées.</p>
					</div>,
				placement: 'top',
				spotlightClicks: true,
				// hideFooter: true,
			},
		];

		setTourIntro(tour)

		setTourRun(true)

	}, []);

	// useEffect(() => {
	// 	if (organisation.zones?.length === 2 && custom_step === 1)
	// 	{
	// 		setCustom_step(2)
	// 		setTourStepIndex(tourStepIndex+1)
	// 		setTourIntro([ ...tourIntro,
	// 			{
	// 				target: ".nouvelle-zone input",
	// 				content:
	// 					<>
	// 						<p>Vous pouvez nommer votre zone.</p>
	// 						{/*<p style={{fontWeight: "bold"}}>Voici un tour rapide (moins de 1 minutes) pour vous présenter les fonctionnalités de cette page</p>*/}
	// 					</>,
	// 				placement: 'bottom',
	// 				spotlightClicks: true,
	// 			},
	// 		])
	// 	}
	//
	// }, [organisation]);


	const handleJoyrideCallback = (data: CallBackProps) => {
		const { action, index, origin, status, type } = data;

		if (action === ACTIONS.CLOSE) {
			// do something
			setTourRun(false);
		}

		// @ts-ignore
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour

			setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

		} else { // @ts-ignore
			if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
				// You need to set our running state to false, so we can restart if we click start again.
				setTourRun(false);
			}
		}

		// console.groupCollapsed(type);
		// console.log(data); //eslint-disable-line no-console
		// console.groupEnd();
	};

	return (
		<Joyride
			steps={tourIntro}
			stepIndex={tourStepIndex}
			callback={handleJoyrideCallback}
			run={tourRun}
			styles={{
				options: {
					// arrowColor: '#e3ffeb',
					// backgroundColor: '#e3ffeb',
					// overlayColor: 'rgba(79, 26, 0, 0.4)',
					primaryColor: 'var(--main-color)',
					zIndex: 100000
				},
				tooltipContainer: {
					lineHeight: 1.4,
					textAlign: 'left',
				},
			}}
			locale={{ back: 'Retour', close: 'Fermer', last: 'Fermer', next: 'Suivant', open: 'Ouvrir l\'aide', skip: 'Passer' }}
			continuous
			// showProgress
		/>
	)
}