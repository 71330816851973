import React, {useEffect, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from "react-joyride";
import {Organisation} from "../../../types/organisation";

export function Orgadmin_intro_tour({organisation}:{organisation: Organisation}) {

	const [tourIntro, setTourIntro] = useState<any>([]);
	const [tourStepIndex, setTourStepIndex] = useState<number>(0);
	const [tourRun, setTourRun] = useState<boolean>(false);

	const [custom_step, setCustom_step] = useState(1);

	useEffect(() => {
		let tour:any = [
			{
				target: 'body',
				content:
					<>
						<p>Voici la page de votre organisation. </p>
						<p>Ici, vous pourrez retrouver vos appareils, vos cellules et configurer d'autres utilisateurs si vous le souhaitez.</p>
					</>,
				placement: 'center',
				spotlightClicks: false,
			},
			// {
			// 	target: 'body',
			// 	content:
			// 		<>
			// 			<p>Les cellules virtuelles sont rangée dans des "Zones", qui servent à les distinguer.</p>
			// 			<p>Généralement, une zone correspond à un endroit physique, comme "Cuisine" ou "Chambre froide"</p>
			// 		</>,
			// 	placement: 'center',
			// 	spotlightClicks: false,
			// },
			// {
			// 	target: "#btn_ajouter_zone",
			// 	content:
			// 		<>
			// 			<p>Vous pouvez créer une zone maintenant ou le faire plus tard.</p>
			// 		</>,
			// 	placement: 'bottom',
			// 	spotlightClicks: false,
			// 	// hideFooter: true,
			// },
			{
				target: '.mesures',
				content:
					<>
						<p>Vos appareils sont ici.</p>
					</>,
				placement: 'bottom',
				spotlightClicks: true,
			},
			{
				target: '.zone:nth-child(2)',
				content:
					<>
						<p>Vos cellules apparaitrons ici, et sur l'écran d'accueil.</p>
					</>,
				placement: 'bottom',
				spotlightClicks: true,
			},
			{
				target: '.carte_mesure_creer_cellule',
				content:
					<>
						<p>Cliquez ici pour créer votre première cellule.</p>
					</>,
				placement: 'bottom',
				spotlightClicks: true,
			},
		];

		setTourIntro(tour)

		if (organisation.zones != null)
		{
			let zone_par_defaut = organisation.zones.find(z => z.nom === organisation.nom);

			if (organisation.zones.length < 3 && zone_par_defaut?.cellules?.length === 0)
			{
				setTourRun(true)
			}
		}

	}, []);

	// useEffect(() => {
	// 	if (organisation.zones?.length === 2 && custom_step === 1)
	// 	{
	// 		setCustom_step(2)
	// 		setTourStepIndex(tourStepIndex+1)
	// 		setTourIntro([ ...tourIntro,
	// 			{
	// 				target: ".nouvelle-zone input",
	// 				content:
	// 					<>
	// 						<p>Vous pouvez nommer votre zone.</p>
	// 						{/*<p style={{fontWeight: "bold"}}>Voici un tour rapide (moins de 1 minutes) pour vous présenter les fonctionnalités de cette page</p>*/}
	// 					</>,
	// 				placement: 'bottom',
	// 				spotlightClicks: true,
	// 			},
	// 		])
	// 	}
	//
	// }, [organisation]);


	const handleJoyrideCallback = (data: CallBackProps) => {
		const { action, index, origin, status, type } = data;

		if (action === ACTIONS.CLOSE) {
			// do something
			setTourRun(false);
		}

		// @ts-ignore
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour

			setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

		} else { // @ts-ignore
			if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
				// You need to set our running state to false, so we can restart if we click start again.
				setTourRun(false);
			}
		}

		// console.groupCollapsed(type);
		// console.log(data); //eslint-disable-line no-console
		// console.groupEnd();
	};

	return (
		<Joyride
			steps={tourIntro}
			stepIndex={tourStepIndex}
			callback={handleJoyrideCallback}
			run={tourRun}
			styles={{
				options: {
					// arrowColor: '#e3ffeb',
					// backgroundColor: '#e3ffeb',
					// overlayColor: 'rgba(79, 26, 0, 0.4)',
					primaryColor: 'var(--main-color)',
					zIndex: 100000
				},
				tooltipContainer: {
					lineHeight: 1.4,
					textAlign: 'left',
				},
			}}
			locale={{ back: 'Retour', close: 'Fermer', last: 'Fermer', next: 'Suivant', open: 'Ouvrir l\'aide', skip: 'Passer' }}
			continuous
			// showProgress
		/>
	)
}