import {Icone} from "../icone";
import React from "react";

export function Icone_signal({rssi, snr}: { rssi?: number|null, snr?: number|null }) {

    let bat_couleur = "grey";
    let bat_icone = "signal-slash";
    let rssi_niveau = -1;
    let snr_niveau = -1;
    if (rssi) {
        if (rssi < -120) rssi_niveau = 0
        if (rssi >= -120) rssi_niveau = 1
        if (rssi >= -95) rssi_niveau = 2
        if (rssi >= -90) rssi_niveau = 3
        if (rssi >= -80) rssi_niveau = 4
        if (rssi >= -70) rssi_niveau = 5
    }
    if (snr) {
        if (snr < -13) snr_niveau = 0
        if (snr >= -13) snr_niveau = 1
        if (snr >= -10) snr_niveau = 2
        if (snr >= -8) snr_niveau = 3
        if (snr >= -7) snr_niveau = 4
        if (snr >= 0) snr_niveau = 5
    }
    if (rssi_niveau === 5 || snr_niveau === 5) {
        bat_couleur = "green";
        bat_icone = "signal"
    }
    if (rssi_niveau === 4 || snr_niveau === 4) {
        bat_couleur = "green";
        bat_icone = "signal-strong"
    }
    if (rssi_niveau === 3 || snr_niveau === 3) {
        bat_couleur = "green";
        bat_icone = "signal-good"
    }
    if (rssi_niveau === 2 || snr_niveau === 2) {
        bat_couleur = "orange";
        bat_icone = "signal-fair"
    }
    if (rssi_niveau === 1 || snr_niveau === 1) {
        bat_couleur = "red";
        bat_icone = "signal-weak"
    }
    if (rssi_niveau === 0 || snr_niveau === 0) {
        bat_couleur = "red";
        bat_icone = "signal-slash"
    }
    return (
        <Icone className={bat_couleur} nom={bat_icone}/>
    )
}