import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useMemo, useState} from "react";

import './Hubs.scss'
import {Tableau} from "../../../components/tableau/Tableau";
import ajax from "../../../services/AxiosInterceptor";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {afficherDateTime, DateTZ, getCurrentDate} from "../../../services/GestionDates";
import {Menu, NativeSelect, rem, Tooltip} from "@mantine/core";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {IconeLoader} from "../../../components/icone/icone";
import {Hub} from "../../../types/hub";
import {Organisation} from "../../../types/organisation";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import env_config from "../../../env_config";
import {useDispatch, useSelector} from "react-redux";
import {setMobile_sidebar_icon} from "../../../store/interfaceSlice";
import {Modale_editer_hub} from "./modale_editer_hub";
import {RootState} from "../../../store/store";
import {User} from "../../../types/user";
import {Modale_voir_capteur_relations_hub} from "./modale_voir_capteur_relations_hub";
import {texte_est_inclus} from "../../../services/GestionTextes";
import {permissionIsGranted} from "../../../services/permissionsService";
import {IconeMicrochip} from "../../../components/icone/solid_react/microchip";
import {IconeBell} from "../../../components/icone/solid_react/bell";
import {IconePenToSquare} from "../../../components/icone/solid_react/pen-to-square";
import {Modale_hub_reseau} from "./modale_hub_reseau";
import {IconeWifi} from "../../../components/icone/solid_react/wifi";
import {IconeWifiSlash} from "../../../components/icone/solid_react/wifi-slash";
import {IconeEthernet} from "../../../components/icone/solid_react/ethernet";
import {IconePersonDotsFromLine} from "../../../components/icone/solid_react/person-dots-from-line";
import {IconeCaretDown} from "../../../components/icone/solid_react/caret-down";


export function Hubs() {

    const [donnees, setDonnees] = useState<Hub[] | null>(() => null);

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState({})


    const [is_loading, setIs_loading] = useState(false);
    const navigate = useNavigate();

    const columnHelper = createColumnHelper<Hub>()

    const [relation_hub, setRelation_hub] = useState<Hub|null>(null);
    const [edited_hub, setEdited_hub] = useState<Hub|null>(null);
    const [edited_hub_wifi, setEdited_hub_wifi] = useState<Hub|null>(null);


    const user = useSelector<RootState, User>(state => state.user)

    let columns = useMemo(() => {
        let _tm = [
            // Display Column
            // columnHelper.display({
            //     id: 'actions',
            //     header: () => <span>ID</span>,
            //     cell: props => <p>{props.row.id}</p>,
            // }),
            // columnHelper.accessor('id', {
            //     header: () => <span>ID</span>,
            //     cell: info => <span>{info.getValue()}</span>,
            //     enableColumnFilter: false
            // }),
            columnHelper.accessor(row => row.organisation, {
                id: 'Organisation',
                header: () => 'Organisation',
                filterFn: (    row: Row<Hub>,
                               columnId: string,
                               filterValue) => {
                    if (row.getValue(columnId))
                    {
                        let orga:Organisation = row.getValue(columnId);
                        return texte_est_inclus(orga.nom, filterValue)
                    }
                    return false;
                },
                cell: info =>
                    info.getValue()?.nom != null ? (
                        <span>
                        <strong className={"th_mobile"}>Organisation : </strong>
                        <Link to={"/organisation/" + info.getValue()?.id}>{info.getValue()?.nom}</Link>
                    </span>
                    ) : (
                        <span>
                        <strong className={"th_mobile"}>Organisation : </strong>
                        <i className={"th_mobile"}>Aucune</i>
                    </span>
                    ),
                enableColumnFilter: true
            }),
            columnHelper.accessor(row => row.uuid, {
                id: 'UUID',
                header: () => 'UUID',
                cell: info =>
                    info.getValue() != null && (
                        <span>
                    <strong className={"th_mobile"}>UUID : </strong>
                            {info.getValue()}
                </span>
                    ),
            }),
            columnHelper.accessor(row => row.nom, {
                id: 'Nom',
                header: () => 'Nom',
                cell: info =>
                    info.getValue() != null && (
                        <span>
                    <strong className={"th_mobile"}>Nom : </strong>
                            {info.getValue()}
                </span>
                    ),
            }),
            columnHelper.accessor(row => row.modele, {
                id: 'Modèle',
                header: () => 'Modèle',
                cell: info =>
                    info.getValue() != null && (
                        <span>
                    <strong className={"th_mobile"}>Modèle : </strong>
                            {info.getValue()}
                </span>
                    ),
            }),
            // columnHelper.accessor(row => row.ref, {
            //     id: 'Réf',
            //     header: () => 'Réf',
            //     cell: info =>
            //         info.getValue() != null && (
            //             <span>
            //         <strong className={"th_mobile"}>Réf : </strong>
            //                 {info.getValue()}
            //     </span>
            //         ),
            // }),
            // columnHelper.accessor(row => row.signal_gsm, {
            //     id: 'Signal',
            //     header: () => 'Signal',
            //     cell: info =>
            //         info.getValue() != null && (
            //         <span>
            //             <strong className={"th_mobile"}>Signal : </strong>
            //             {info.getValue()}
            //         </span>
            //         ),
            // }),
            columnHelper.accessor(row => row.version_logicielle, {
                id: 'Version logicielle',
                header: () => 'Version logicielle',
                cell: info =>
                    info.getValue() != null && (
                        <span>
                    <strong className={"th_mobile"}>Version logicielle : </strong>
                            {info.getValue()}
                </span>
                    ),
            }),
            columnHelper.accessor(row => row, {
                id: "Dernière remontée",
                header: () => 'Dernière remontée',
                cell: info =>
                    <span
                        //     style={new DateTZ(Date.parse(info.getValue().derniere_connexion)) <= new DateTZ(getCurrentDate().getTime() - env_config.duree_affichage_deconnexion_gateway)
                        // ? {backgroundColor: "var(--error-color)", color: 'white'} : {}}
                    >
                    <strong className={"th_mobile"}>Dernière remontée : </strong>
                        <Tooltip label={"Dernière mesure envoyé par un capteur : " + afficherDateTime(new DateTZ(info.getValue().derniere_connexion))}>
                            <p>{afficherDateTime(new DateTZ(info.getValue().heartbeat))}</p>
                        </Tooltip>

                </span>,
                enableColumnFilter: true,
                sortingFn: (rowA: Row<Hub>,
                            rowB: Row<Hub>,
                            columnId: string) => {
                    let dateA = new DateTZ(rowA.getValue<Hub>(columnId).heartbeat)
                    let dateB = new DateTZ(rowB.getValue<Hub>(columnId).heartbeat)
                    if (dateA < dateB) return -1
                    else if (dateA == dateB) return 0
                    else return 1
                },
                filterFn: (    row: Row<Hub>,
                               columnId: string,
                               filterValue) => {
                    if (row.getValue<Hub>(columnId).heartbeat)
                    {
                        return afficherDateTime(new DateTZ(row.getValue<Hub>(columnId).heartbeat)).includes(filterValue)
                    }
                    return false;
                },
            }),
            columnHelper.accessor(row => row, {
                id: "Statut",
                header: () => 'Statut',
                cell: info =>
                    <span className={"flex_align_center"}>
                    <strong className={"th_mobile"}>Statut : </strong>
                        <div className={"flex-col"} style={{gap: "0.2rem"}}>
                            {new DateTZ(Date.parse(info.getValue().heartbeat)) <= new DateTZ(getCurrentDate().getTime() - env_config.duree_affichage_deconnexion_gateway)
                                ? <i className={"inline-tag grey"} style={{width: "2rem"}}>OFF</i>
                                : (
                                    <>
                                        {info.getValue().modele === "UG65" ? (
                                            <>
                                                {info.getValue().heartbeat_etat?.rj45 === 1
                                                    ?   <i className={"inline-tag green no-wrap"} style={{width: "auto"}} onClick={() => {alert("Connecté au réseau via RJ45")}}>
                                                        <IconeEthernet /> RJ45
                                                    </i>
                                                    : <i className={"inline-tag grey"} style={{width: "2rem"}}><IconeEthernet /></i>
                                                }
                                                {info.getValue().heartbeat_etat?.wifi === 1
                                                    ?   <i className={"inline-tag green no-wrap"} style={{width: "auto"}} onClick={() => {alert("Connecté au réseau wifi " + info.getValue().heartbeat_etat?.ssid)}}>
                                                        <IconeWifi /> {info.getValue().heartbeat_etat?.ssid.slice(0,4)}...
                                                    </i>
                                                    : <i className={"inline-tag grey"} style={{width: "2rem"}}><IconeWifiSlash /></i>
                                                }
                                            </>
                                        ) : <i className={"inline-tag green"} style={{width: "2rem", margin: "auto"}}>ON</i>}

                                    </>
                                )}

                            {info.getValue().heartbeat_etat?.erreur && info.getValue().heartbeat_etat?.erreur !== "" && (
                                <i className={"inline-tag red"} style={{width: "auto"}}>{info.getValue().heartbeat_etat?.erreur}</i>
                            )}

                        </div>




                </span>,
                enableColumnFilter: true,
                sortingFn: (rowA: Row<Hub>,
                            rowB: Row<Hub>,
                            columnId: string) => {
                    let dateA = new DateTZ(rowA.getValue<Hub>(columnId).heartbeat)
                    let dateB = new DateTZ(rowB.getValue<Hub>(columnId).heartbeat)
                    if (dateA < dateB) return -1
                    else if (dateA == dateB) return 0
                    else return 1
                },
                filterFn: (    row: Row<Hub>,
                               columnId: string,
                               filterValue) => {
                    if (new DateTZ(Date.parse(row.getValue<Hub>(columnId).heartbeat)) > new DateTZ(getCurrentDate().getTime() - env_config.duree_affichage_deconnexion_gateway))
                    {
                        return "ON".toLowerCase().includes(filterValue.toLowerCase())
                    }
                    return "OFF".toLowerCase().includes(filterValue.toLowerCase())
                },
            }),
            // columnHelper.accessor(row => row, {
            //     id: 'Capteurs',
            //     header: () => '',
            //     cell: info =>
            //         info.getValue() != null && (
            //             <span>
            //             <Tooltip label={"Voir les enregistreurs utilisant ce hub"}>
            //                 <button onClick={() => setRelation_hub(info.getValue())}><IconeMicrochip/></button>
            //             </Tooltip>
            //
            //         </span>
            //         ),
            //     enableColumnFilter: false
            // }),
            // columnHelper.accessor(row => row, {
            //     id: 'Alertes',
            //     header: () => '',
            //     cell: info => (
            //         info.getValue().organisation != null && permissionIsGranted(info.getValue().organisation?.id, "Gestion_alertes_toutes") && (
            //             <span>
            //             <Tooltip label={"Configurer les alertes de déconnexion"}>
            //                 <Link className={"btn"} to={"/organisation/" + info.getValue().organisation?.id + "/anomalies?cellule=" + info.getValue().uuid}><IconeBell/></Link>
            //             </Tooltip>
            //
            //         </span>
            //         )),
            //     enableColumnFilter: false
            // }),
            columnHelper.accessor(row => row.notes, {
                id: 'Notes',
                header: () => 'Notes',
                cell: info =>
                    info.getValue() != null && (
                        <span>
                    <strong className={"th_mobile"}>Notes : </strong>
                            {info.getValue().length < 60 ? info.getValue() : info.getValue().slice(0, 57) + "..."}

                </span>
                    ),
            })
        ];


        if (user.roles.includes("ROLE_ADMIN"))
        {
            let admin_col = [
                columnHelper.accessor(row => row.notes_admin, {
                    id: 'Notes_admin',
                    header: () => 'Notes Admin',
                    cell: info => {
                        let valeur = info.getValue();
                        return valeur != null && valeur != undefined && (
                            <span>
                                <strong className={"th_mobile"}>Notes admin : </strong>
                                {valeur.length < 60 ? info.getValue() : valeur.slice(0, 57) + "..."}
                            </span>
                        )
                    },
                })
            ];

            _tm = _tm.concat(admin_col);
        }

        let edit_col = [
            // columnHelper.accessor(row => row, {
            //     id: 'wifi',
            //     header: () => '',
            //     cell: info =>
            //         info.getValue() != null
            //         && info.getValue().modele === "UG65"
            //         && permissionIsGranted(info.row.original.organisation?.id, "Administrer_appareils")
            //         && (
            //             <span>
            //         <button onClick={() => setEdited_hub_wifi(info.getValue())}><IconeWifi/></button>
            //
            //     </span>
            //         ),
            //     enableColumnFilter: false
            // }),
            // columnHelper.accessor(row => row, {
            //     id: 'Edit',
            //     header: () => '',
            //     cell: info =>
            //         info.getValue() != null && permissionIsGranted(info.row.original.organisation?.id, "Administrer_appareils") && (
            //             <span>
            //         <button onClick={() => setEdited_hub(info.getValue())}><IconePenToSquare/></button>
            //
            //     </span>
            //         ),
            //     enableColumnFilter: false
            // }),
            columnHelper.accessor(row => row, {
                id: 'Options',
                header: () => '',
                cell: info =>
                    info.getValue() != null && permissionIsGranted(info.row.original.organisation?.id, "Administrer_appareils") && (
                        <Menu shadow="md" width={200}>
                            <Menu.Target>
                                <button><IconeCaretDown/></button>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Label>Options disponibles</Menu.Label>
                                {permissionIsGranted(info.getValue().organisation?.id, "Gestion_alertes_toutes") && (
                                    <Menu.Item leftSection={<IconeMicrochip />} onClick={() => setRelation_hub(info.getValue())}>
                                        Voir les enregistreurs utilisant ce hub
                                    </Menu.Item>
                                )}
                                {info.getValue().organisation && permissionIsGranted(info.getValue().organisation?.id, "Gestion_alertes_toutes") && (
                                    <Menu.Item leftSection={<IconeBell />}>
                                        <Link style={{color: "black"}} to={"/organisation/" + info.getValue().organisation?.id + "/anomalies?cellule=" + info.getValue().uuid}>Configurer les alertes de déconnexion</Link>

                                    </Menu.Item>
                                )}
                                {info.getValue().modele === "UG65"
                                    && permissionIsGranted(info.row.original.organisation?.id, "Administrer_appareils") && (
                                        <Menu.Item leftSection={<IconeWifi />} onClick={() => setEdited_hub_wifi(info.getValue())}>
                                            Configurer réseau & wifi
                                        </Menu.Item>
                                )}
                                {permissionIsGranted(info.row.original.organisation?.id, "Administrer_appareils") && (
                                    <Menu.Item leftSection={<IconePenToSquare />} onClick={() => setEdited_hub(info.getValue())}>
                                        Modifier le hub
                                    </Menu.Item>
                                )}

                            </Menu.Dropdown>
                        </Menu>

                    ),
                enableColumnFilter: false
            }),
        ];

        _tm = _tm.concat(edit_col);

        return _tm
    }, [])


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const url_param_uuid = queryParams.get('uuid'); //dateheure

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    useEffect(() => {
        queryLastInfos();
        const interval_clear = setInterval(() => {
            queryLastInfos();
        }, env_config.refresh_rate);

        if (url_param_uuid){
            table.setColumnFilters([{"id":"UUID","value":url_param_uuid}])
        }


        return () => {
            clearInterval(interval_clear);
        }
    }, [])

    function queryLastInfos()
    {
        setIs_loading(true)
        ajax.get("/utilisateur/hubs").then(response => {
            setDonnees(response.data.hubs);
            setIs_loading(false);
        }).catch(e => {setIs_loading(false)})
    }

    const table = useReactTable<Hub>({
        data: donnees ? donnees : [],
        columns: columns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })


    return (
        <div className={"app"}>
            <Navbar/>

            <div className={"content content-fullwidth en-tete-pages"}>


                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Vos hubs</h1>
                        <p>
                            Voici la liste de vos hubs (gateways)
                        </p>
                    </div>

                    <div className={"mini-sidebar"}>
                        <Bandeau_user />
                    </div>


                </div>
                <div className={"en-ligne en-tete-page"}>

                    {is_loading ? (<div className={"en-ligne"}><IconeLoader /> Chargement...</div>) : (<div> </div>)}


                    <div className={"flex-row"}>
                        <div>{table.getRowModel().rows.length} lignes affichés</div>
                        <NativeSelect
                            className={"select_nbe_lignes match_height"}
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                            }}
                            data={["5", "10", "20", "50", "100"]}
                        />
                    </div>
                </div>


                <Tableau table={table} donnees={donnees}/>

                <Modale_editer_hub hub={edited_hub} setEditedHub={setEdited_hub} hubs={donnees} setHubs={setDonnees} />
                <Modale_hub_reseau hub={edited_hub_wifi} setEditedHub={setEdited_hub_wifi} hubs={donnees} setHubs={setDonnees} />
                <Modale_voir_capteur_relations_hub hub={relation_hub} setRelationHub={setRelation_hub} />
            </div>
        </div>

    );
}
