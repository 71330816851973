import React, {useEffect, useRef, useState} from 'react';
import './accueil_organisation.scss'
import {Organisation} from "../../../../types/organisation";
import {IconeLoader} from "../../../../components/icone/icone";
import {Collapse} from "@mantine/core";
import {Zone} from "../../../../types/zone";
import {Carte_mesure} from "../../../../components/carte_mesure/carte_mesure";
import {gps_sonde} from "../../../../types/sonde";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import ajax from "../../../../services/AxiosInterceptor";
import {addZonesToOrganisation} from "../../../../store/organisationSlice";
import {useNavigate} from "react-router-dom";
import env_config from "../../../../env_config";
import {dashboardCollapse, interfaceData, setAcceuilCollapseState} from "../../../../store/interfaceSlice";
import {Carte_mesure_loading} from "../../../../components/carte_mesure/carte_mesure_loading";
import {User} from "../../../../types/user";
import {Loading_div} from "../../../../components/loading_div/loading_div";
import {texte_est_inclus} from "../../../../services/GestionTextes";
import {IconeXmark} from "../../../../components/icone/solid_react/xmark";
import {IconeToggleOff} from "../../../../components/icone/solid_react/toggle-off";
import {IconeCaretRight} from "../../../../components/icone/solid_react/caret-right";
import {IconeGear} from "../../../../components/icone/solid_react/gear";
import {IconeTruckMoving} from "../../../../components/icone/solid_react/truck-moving";
import {permissionIsGranted} from "../../../../services/permissionsService";


export function Accueil_organisation({organisation, recherche}: { organisation: Organisation, recherche: string }) {
    const organisations_lastmaj = useSelector<RootState, { lastMiseAJour: number; list: Organisation[]; }>(state => state.organisation)
    const dispatch = useDispatch();
    const user = useSelector<RootState, User>(state => state.user)

    const [loader, setLoader] = useState(<h2><IconeLoader />{organisation.nom}</h2>)
    const ref = useRef<HTMLDivElement>(null);

    const interfaceState = useSelector<RootState, dashboardCollapse[]>(state => state.interface.dashboardCollapse.filter(s => s.collapse_id === "accueil_orga_" + organisation.id))
    let open = interfaceState.length > 0 ? interfaceState[0].open : (organisations_lastmaj.list.length <= 3) //Si > 2 orgas, on les ferme par défaut.
    function orga_est_desactivee() {
        return organisation.desactivation_texte !== null && (!user.roles.includes("ROLE_ADMIN") && !user.roles.includes("ROLE_PRESTATAIRE"))
    }


    useEffect(() => {

        if (orga_est_desactivee()) return;
        if (!open) return;
        if (!texte_est_inclus(field_recherche, recherche)) return;
        queryLastInfos();
        const interval_clear = setInterval(() => {
            queryLastInfos();
        }, env_config.refresh_rate);

        return () => {
            clearInterval(interval_clear);
            // console.log("CLEAR " + organisation.id)
        }
    }, [organisations_lastmaj.lastMiseAJour, open, recherche])

    function queryLastInfos() {
        ajax.get('/organisations/' + organisation.id + '/lastInfos')
            .then(response => {

                console.log("Refresh des mesures de l'organisation " + organisation.id + "/" + organisation.nom + " OK")
                dispatch(addZonesToOrganisation({
                    id_orga: organisation.id,
                    zones: response.data.zones,
                    capteurs: response.data.capteurs,
                    hubs: response.data.hubs
                }))
            })
            .catch(error => {
                console.log(error);
                setLoader(
                    <>
                        <h2><IconeXmark/>{organisation.nom}</h2>
                        <p className={"notice"}>{error?.response?.data?.erreur ? error.response.data.erreur : error.message}</p>
                    </>
                )
            });
    }

    let field_recherche = organisation.nom;
    organisation.zones?.map(z => {
        field_recherche += " " + z.nom
        z.cellules_minimum?.map(c => field_recherche += " " + c.nom)
    })
    if (!texte_est_inclus(field_recherche, recherche)) return <></>

    return (
        <>
            {orga_est_desactivee()
                ? (
                    <div className={"accueil_organisation"}>

                        <div className={"en-tete-orga"}>
                            <h2><IconeXmark/>{organisation.nom}</h2>
                            {orga_est_desactivee() && (
                                <span className={"inline-tag"} style={{color: "var(--warning-color)"}}>
                                    <IconeToggleOff/> Organisation désactivée
                                </span>
                            )}
                        </div>
                        <p className={"notice"}>{organisation.desactivation_texte}</p>

                    </div>
                )
                : (
                    <div className={"accueil_organisation"} ref={ref}>

                        <Accueil_organisation_loaded organisation={organisation} key={organisation.id} recherche={recherche} open={open}/>

                    </div>
                )
            }

        </>

    );
}

export function Accueil_organisation_loaded({
                                                organisation,
                                                recherche, open
                                            }: { organisation: Organisation, recherche: string, open:boolean }) {
    const dispatch = useDispatch();


    const user = useSelector<RootState, User>(state => state.user)

    function toggleCollapse() {
        dispatch(setAcceuilCollapseState({
            collapse_id: "accueil_orga_" + organisation.id,
            open: !open
        }))
    }

    const navigate = useNavigate();


    function afficher_btn_admin_orga() {
        if (organisation.permissions?.includes("Administrer_organisation")
            || ( user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_PRESTATAIRE") ))
        {
            return (<button id={"btn_administrer_orga_" + organisation.id} className={"sec"} onClick={() => navigate("/organisation/" + organisation.id)}><IconeGear/> Configurer l'organisation</button>)
        }
        return (<></>)
    }

    return (
        <>

            <div className={"en-tete-orga"}>
                <h2 onClick={toggleCollapse}>
                    <IconeCaretRight className={open ? "rotate" : ""}/>
                    {organisation.nom}
                </h2>
                {afficher_btn_admin_orga()}
                {(organisation.etat != "New" && organisation.etat != "Active") && (
                    <span className={"inline-tag"} style={{color: "var(--warning-color)"}}>
                        <IconeToggleOff/> Organisation désactivée
                    </span>
                )}
            </div>

            {organisation.desactivation_texte !== null && (
                <p className={"notice"}>{organisation.desactivation_texte}</p>
            )}
            {organisation.etat === "Desac_paiement" && (
                <p className={"notice"}>Votre organisation est désactivée temporairement suite à un défaut de paiement.
                    <br />Les mesures pour lequelles vous avez payés sont accessible mais les nouvelles mesures ne le seront pas.
                    <br />Vous trouverez plus d'infos sur la page "Paiement" dans le menu de gauche.</p>
            )}
            {!organisation.zones && (
                <Loading_div />
            )}

            {(organisation.etat !== "Desac_paiement" && organisation.desactivation_texte === null) && (
                <Collapse in={open === null ? false : open}>
                    {!permissionIsGranted(organisation.id, "Consulter_donnees") ? (
                        <p className={"notice grey"}>Votre compte est lié à l'organisation mais vous n'avez pas la permission d'en consulter les données.
                            Contacter le gestionnaire de votre organisation si vous pensez que c'est une erreur.</p>
                    ) : (
                        <div className={"zones"}>
                            {organisation.zones?.map(zone => {
                                if (zone.nom === "base.config.appareils") return null;

                                let field_recherche = organisation.nom + " " + zone.nom;
                                if (zone.cellules) zone.cellules?.map(c => field_recherche += " " + c.nom + " " + c.extension?.capteur.uuid)
                                else zone.cellules_minimum?.map(c => field_recherche += " " + c.nom)
                                if (!texte_est_inclus(field_recherche, recherche)) return <></>

                                return (<Accueil_organisation_zone zone={zone} organisation={organisation} key={zone.id}
                                                                   recherche={recherche}/>)
                            })}
                        </div>
                    )}

                </Collapse>
            )}

        </>
    );
}

export function Accueil_organisation_zone({
                                              zone,
                                              organisation,
                                              recherche
                                          }: { zone: Zone, organisation: Organisation, recherche: string }) {
    const dispatch = useDispatch();
    const interfaceState = useSelector<RootState, dashboardCollapse[]>(state => state.interface.dashboardCollapse.filter(s => s.collapse_id === "accueil_zone_" + zone.id))
    let open = interfaceState.length > 0 ? interfaceState[0].open : true

    function toggleCollapse() {
        dispatch(setAcceuilCollapseState({
            collapse_id: "accueil_zone_" + zone.id,
            open: !open
        }))
    }

    const navigate = useNavigate();


    let h3zone = (<>{zone.nom}</>)
    if (zone.immatriculation) h3zone = (h3zone = (<> <IconeTruckMoving/> {zone.nom} - {zone.immatriculation}</>))


    if (zone.cellules?.filter(c => c.extension).length === 0 || zone.cellules_minimum?.filter(c => !c.desactivee).length === 0) return <></>

    return (
        <div className={"zone"} key={zone.id}>
            <h3 onClick={toggleCollapse}><IconeCaretRight className={open ? "rotate" : ""}/> {h3zone}</h3>

            <Collapse in={open}>
                <div className={"mesures"}>
                    {!zone.cellules && zone.cellules_minimum?.map(cellule => {
                        if (!cellule.desactivee) { // Désactivée

                            let field_recherche = organisation.nom + " " + zone.nom + " " + cellule.nom
                            if (!texte_est_inclus(field_recherche, recherche)) return <></>

                            return (
                                <Carte_mesure_loading titre={cellule.nom} key={cellule.id} handleClick={() => navigate("/cellule/" + cellule.id)}/>
                            );
                        }
                    })
                    }

                    {zone.cellules?.map(cellule => {
                        if (cellule.extension) {

                            let field_recherche = organisation.nom + " " + zone.nom + " " + cellule.nom + " " + cellule.extension.capteur.uuid
                            if (!texte_est_inclus(field_recherche, recherche)) return <></>

                            return (
                                <Carte_mesure titre={cellule.nom}
                                              sonde={cellule.sonde_type}
                                              texte={cellule.last_mesures?.mesure}
                                    // key={cellule.id} handleClick={() => dispatch(setCapteurModale({"capteur": cellule, "organisation": organisation}))}
                                              key={cellule.id} handleClick={() => navigate("/cellule/" + cellule.id)}
                                              conditions={cellule.statut_actuel?.statut.can_use_alertes ? cellule.conditions : []}
                                              afficherTypeSiPasIcone={true}
                                              afficherEtatExtension={true}
                                              extension={cellule.extension}
                                              restartTitreAnim={open ? "oui" : "non"}
                                              afficherCondition={true}
                                />
                            );
                        }
                    })
                    }
                    {zone.immatriculation ? (
                        <Carte_mesure titre={"Traceur GPS"} sonde={gps_sonde} texte={"Ouvrir"}
                                      handleClick={() => alert("Ouvrir Traccar")}/>
                    ) : ""}
                    {!zone.immatriculation && zone.cellules?.length === 0 && (
                        <div className={"inline-tag"}>Aucune cellule n'est configurée dans cette zone.</div>
                    )}

                </div>

            </Collapse>
        </div>
    );

}

    export function useIsVisible(ref:React.RefObject<HTMLDivElement>, onlyOnce?:boolean) {
        const [isIntersecting, setIntersecting] = useState(false);
        const [compteur, setCompteur] = useState(0);

        useEffect(() => {
            const observer = new IntersectionObserver(([entry]) => {
                if (onlyOnce){
                    if (!isIntersecting && entry.isIntersecting && compteur === 0)
                    {
                        setIntersecting(entry.isIntersecting)
                        setCompteur(compteur+1)
                    }
                }
                else
                {
                    setIntersecting(entry.isIntersecting)
                }
            }

            );


            if (ref.current)
            observer.observe(ref.current);
            return () => {
                observer.disconnect();
            };
        }, [ref]);

        return isIntersecting;
    }

