import axios from 'axios';
import {store} from "../store/store";
import { notifications } from '@mantine/notifications';
import env_config from "../env_config";

// const API_URL = 'https://localhost:8000';
// const API_URL = 'https://api.freshtime.fr';

export const ajax = axios.create({
    baseURL: env_config.api_url
});

ajax.interceptors.request.use(
    config => {
        const token = store.getState().auth.token;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

ajax.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const originalRequest = error.config;

        console.log(error);

        if (!window.location.href.includes('/login')) {
            notifications.show({
                // id: 'notif_erreur',
                withCloseButton: true,
                // onClose: () => console.log('unmounted'),
                // onClick: () => alert(error + "\n" + (error.response ? JSON.stringify(error.response.data, null, 3) : "Erreur de connexion")),
                autoClose: env_config.notifications_duree_affichage,
                title: "Une erreur est survenue...",
                message: (error.response?.data.erreur ? error.response.data.erreur : 'Connexion à l\'API échouée.') ,
                color: 'red',
                // icon: <Icone,
                // className: 'my-notification-class',
                style: { cursor: "pointer" },
                loading: false,
                closeButtonProps: { 'aria-label': 'Hide notification' }
            });
        }

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            //store.dispatch(logOut()); // assuming you have implemented a logout action that clears the token
            if(window.location.pathname !== "/login") window.location.href = "/login?msg=deconnexion";
            // if(window.location.pathname === "/login") window.location.href = "/login?msg=erreur";
            // else window.location.href = "/login?msg=deconnexion";
            // window.location.href = "/login?msg=deconnexion";
            return ajax(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default ajax;