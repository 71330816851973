import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Prestataire.scss'
import {Tabs} from "@mantine/core";
import {Utilisateurs_liste} from "./Utilisateurs_liste/Utilisateurs_liste";
import {useDispatch} from "react-redux";
import {setMobile_sidebar_icon} from "../../../store/interfaceSlice";
import {Capteurs_liste_admin} from "./Capteurs_liste_admin/Capteurs_liste_admin";
import {Logs_viewer_bureau_etude} from "../Bureau_etudes/logs_viewer_bureau_etude";
import {IconeUsers} from "../../../components/icone/solid_react/users";
import {IconeMicrochip} from "../../../components/icone/solid_react/microchip";
import {IconeList} from "../../../components/icone/solid_react/list";



export function Prestataire() {

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    const [activeTab, setActiveTab] = useState<string | null>('users');

    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content content-fullwidth page_be"}>
                <Tabs className={"Tabs"} value={activeTab} onChange={setActiveTab}>
                    <Tabs.List>
                        <Tabs.Tab value="users" leftSection={<IconeUsers />}>Utilisateurs</Tabs.Tab>
                        <Tabs.Tab value="capteurs" leftSection={<IconeMicrochip />}>Enregistreurs</Tabs.Tab>
                        <Tabs.Tab value="logs" leftSection={<IconeList />}>Logs</Tabs.Tab>
                        {/*<Tabs.Tab value="page5" icon={<Icone nom={"gear"} />}>Page 5</Tabs.Tab>*/}
                    </Tabs.List>

                    <Tabs.Panel value="users" pt="xs">
                        {activeTab === "users" && (
                            <Utilisateurs_liste />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="capteurs" pt="xs">
                        {activeTab === "capteurs" && (
                            <Capteurs_liste_admin />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="logs" pt="xs">
                        {activeTab === "logs" && (
                            <Logs_viewer_bureau_etude />
                        )}
                    </Tabs.Panel>

                    {/*<Tabs.Panel value="page5" pt="xs">*/}
                    {/*    Page 5...*/}
                    {/*</Tabs.Panel>*/}
                </Tabs>
            </div>
        </div>

    );
}
