import React, {useEffect, useState} from "react";

import './Outils_admin.scss'
import {NativeSelect, Tabs, TextInput} from "@mantine/core";
import ajax from "../../../../services/AxiosInterceptor";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeCheck} from "../../../../components/icone/solid_react/check";

export function Ajouter_une_orga() {


    const [loading_btn, setLoading_btn] = useState(false);

    const [orga_nom, setOrga_nom] = useState("");


    function handleAjouterOrganisation(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading_btn(true)
        ajax.post("/organisations", {
            "nom": orga_nom,
            "coordonnees": [],
            "est_parent": 0
        }).then(response => {
            setLoading_btn(false)
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'L\'organisation à été créer.',
                icon: <IconeCheck/>,
            });
        }).catch(error => {
            setLoading_btn(false)
        });
    }


    return (

        <form className={'form_admin'} onSubmit={event => handleAjouterOrganisation(event)}>

            <div className={"fullwidth"}>
                <TextInput
                    label={"Nom de l'organisation"}
                    value={orga_nom}
                    onChange={e => {
                        setOrga_nom(e.target.value)
                    }}
                    required
                />
            </div>
            <div className={"fullwidth"}>
                <Loading_button is_loading={loading_btn} onClick={() => {}} className={"big"}>Créer l'organisation</Loading_button>
            </div>
            <p className={"inline-tag"}>Note : Les organisations sont chargés à l'ouverture du site, il faut le
                rafraichir (F5) pour que l'organisation apparaisse.</p>
        </form>


    );
}
