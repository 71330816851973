import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {Cellule} from "../../../types/cellule";
import React, {useEffect, useState} from "react";
import {Logs_viewer} from "../../../components/logs_viewer/logs_viewer";
import {NumberInput, Tabs, TextInput} from "@mantine/core";
import {Ajouter_un_hub} from "./Outils_admin/Ajouter_un_hub";
import {Ajouter_une_orga} from "./Outils_admin/Ajouter_une_orga";
import {IconeDatabase} from "../../../components/icone/solid_react/database";
import {IconeFileBinary} from "../../../components/icone/solid_react/file-binary";
import {IconeWavePulse} from "../../../components/icone/solid_react/wave-pulse";
import ajax from "../../../services/AxiosInterceptor";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {IconeInfo} from "../../../components/icone/solid_react/info";
import {DateTZ} from "../../../services/GestionDates";

export const Logs_viewer_bureau_etude = () => {
    const { celluleId } = useParams();
    const cellules = useSelector<RootState, Cellule[]>(state => state.cellule)
    let cellule = cellules.filter(c => c.id + "" === celluleId)[0];

    const [activeTab, setActiveTab] = useState<string | null>('logs_bdd');
    return (
        <>
            <Tabs defaultValue="logs_bdd" className={"Tabs"} value={activeTab} onChange={setActiveTab}>
                <Tabs.List>
                    <Tabs.Tab value="logs_bdd" leftSection={<IconeDatabase />}>Logs en base de donnée</Tabs.Tab>
                    <Tabs.Tab value="logs_fichiers" leftSection={<IconeFileBinary />}>Logs en fichiers</Tabs.Tab>
                    <Tabs.Tab value="logs_activite_capteur" leftSection={<IconeWavePulse />}>Activité enregistreurs</Tabs.Tab>
                    <Tabs.Tab value="logs_activite_gestion" leftSection={<IconeWavePulse />}>Activité script gestion</Tabs.Tab>
                    <Tabs.Tab value="logs_activite_conditions" leftSection={<IconeWavePulse />}>Activité script anomalies</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="logs_bdd" pt="xs">
                    {activeTab === "logs_bdd" && (
                        <Logs_viewer api_query_url={"/logs"} mode_admin={true} nbe_jour_load={7} />
                    )}
                </Tabs.Panel>

                <Tabs.Panel value="logs_fichiers" pt="xs">
                    {activeTab === "logs_fichiers" && (
                        <Logs_fichiers />
                    )}
                </Tabs.Panel>

                <Tabs.Panel value="logs_activite_capteur" pt="xs">
                    {activeTab === "logs_activite_capteur" && (
                        <Logs_activite_capteurs activite={"activite_capteurs"} />
                    )}
                </Tabs.Panel>

                <Tabs.Panel value="logs_activite_gestion" pt="xs">
                    {activeTab === "logs_activite_gestion" && (
                        <Logs_activite_capteurs activite={"activite_gestion"} />
                    )}
                </Tabs.Panel>

                <Tabs.Panel value="logs_activite_conditions" pt="xs">
                    {activeTab === "logs_activite_conditions" && (
                        <Logs_activite_capteurs activite={"activite_conditions"} />
                    )}
                </Tabs.Panel>
            </Tabs>

            </>
    );
};

type logs_fichiers = {
    logs_non_ecrit_en_bdd?:string[]
    blucapteur_erreurs?: string[]
    receveur_capteur_erreurs?: string[]
    gateway_heartbeat_erreurs?: string[]
    condition_checker_erreurs?: string[]
}

export const Logs_fichiers = () => {

    const [activeTab, setActiveTab] = useState<string | null>('logs_non_ecrit_en_bdd');

    const [donnees, setDonnees] = useState<logs_fichiers|null>(null);

    useEffect(() => {
        ajax.get("/be/logs_fichiers").then(response => {
            setDonnees(response.data);
        }).catch(e => {
        })
    }, []);

    return (
        <>
            {!donnees ? (
                <Loading_div />
            ) : (
                <Tabs defaultValue="logs_non_ecrit_en_bdd" className={"Tabs"} value={activeTab} onChange={setActiveTab}>
                    <Tabs.List>
                        <Tabs.Tab value="logs_non_ecrit_en_bdd" leftSection={<IconeFileBinary />}>logs_non_ecrit_en_bdd.txt</Tabs.Tab>
                        <Tabs.Tab value="blucapteur_erreurs" leftSection={<IconeFileBinary />}>blucapteur_erreurs</Tabs.Tab>
                        <Tabs.Tab value="receveur_capteur_erreurs" leftSection={<IconeFileBinary />}>receveur_capteur_erreurs</Tabs.Tab>
                        <Tabs.Tab value="condition_checker_erreurs" leftSection={<IconeFileBinary />}>condition_checker_erreurs</Tabs.Tab>
                        <Tabs.Tab value="gateway_heartbeat_erreurs" leftSection={<IconeFileBinary />}>gateway_heartbeat_erreurs</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="logs_non_ecrit_en_bdd" pt="xs">
                        {activeTab === "logs_non_ecrit_en_bdd" && (
                            <div>
                                <p>/api/Freshtime_API/logs/logs_non_ecrit_en_bdd.txt</p>
                                <code style={{width: "unset"}}>
                                    {donnees.logs_non_ecrit_en_bdd && donnees?.logs_non_ecrit_en_bdd?.map(p => <p>{"> " + p}</p>)}
                                    {!donnees.logs_non_ecrit_en_bdd && "Fichier n'existe pas."}
                                </code>
                            </div>
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="blucapteur_erreurs" pt="xs">
                        {activeTab === "blucapteur_erreurs" && (
                            <div>
                                <p>/api/scripts/blucapteur_erreurs</p>
                                <code style={{width: "unset"}}>
                                    {donnees.blucapteur_erreurs && donnees?.blucapteur_erreurs?.map(p => <p>{"> " + p}</p>)}
                                    {!donnees.blucapteur_erreurs && "Fichier n'existe pas."}
                                </code>
                            </div>
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="receveur_capteur_erreurs" pt="xs">
                        {activeTab === "receveur_capteur_erreurs" && (
                            <div>
                                <p>/api/scripts/receveur_capteur_erreurs</p>
                                <code style={{width: "unset"}}>
                                    {donnees.receveur_capteur_erreurs && donnees?.receveur_capteur_erreurs?.map(p => <p>{"> " + p}</p>)}
                                    {!donnees.receveur_capteur_erreurs && "Fichier n'existe pas."}
                                </code>
                            </div>
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="gateway_heartbeat_erreurs" pt="xs">
                        {activeTab === "gateway_heartbeat_erreurs" && (
                            <div>
                                <p>/api/scripts/Gateway_heartbeat/gateway_heartbeat_erreurs</p>
                                <code style={{width: "unset"}}>
                                    {donnees.gateway_heartbeat_erreurs && donnees?.gateway_heartbeat_erreurs.map(p => <p>{"> " + p}</p>)}
                                    {!donnees.gateway_heartbeat_erreurs && "Fichier n'existe pas."}
                                </code>
                            </div>
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="condition_checker_erreurs" pt="xs">
                        {activeTab === "condition_checker_erreurs" && (
                            <div>
                                <p>/api/scripts/Condition_checker/condition_checker_erreurs</p>
                                <code style={{width: "unset"}}>
                                    {donnees.condition_checker_erreurs && donnees?.condition_checker_erreurs.map(p => <p>{"> " + p}</p>)}
                                    {!donnees.condition_checker_erreurs && "Fichier n'existe pas."}
                                </code>
                            </div>
                        )}
                    </Tabs.Panel>
                </Tabs>
            )}


        </>
    );
};

export const Logs_activite_capteurs = ({activite}:{activite:string}) => {

    const [donnees, setDonnees] = useState<{output:string[]}|null>(null);

    const [longueur, setLongueur] = useState<string|number>(20000);
    const [grep, setGrep] = useState("");
    const [lignes_avant, setLignes_avant] = useState<string|number>('');
    const [lignes_apres, setLignes_apres] = useState<string|number>('');
    const [loading, setLoading] = useState(false);
    function load()
    {
        setLoading(true)
        ajax.get("/be/"+activite+"/" + longueur + "/" + (grep === "" ? "vide" : grep) + "/" + (lignes_avant === "" ? 0 : lignes_avant) + "/" + (lignes_apres === "" ? 0 : lignes_apres)).then(response => {
            setDonnees(response.data);
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        })
    }

    let timezone = new DateTZ();

    return (
        <>
            <div className={"en-ligne"} style={{justifyContent: "center"}}>
                <p>Longueur du log à charger</p>
                <NumberInput
                    step={10000}
                    value={longueur}
                    onChange={e => {
                        setLongueur(e ?? 20000)
                    }}
                />
                <TextInput
                    placeholder={"Recherche dans le log..."}
                    value={grep}
                    onChange={e => setGrep(e.target.value)}
                />
                {grep !== "" && (
                    <>
                        <NumberInput
                            placeholder={"Lignes avant"}
                            step={1}
                            value={lignes_avant}
                            w={"7rem"}
                            onChange={e => {
                                setLignes_avant(e)
                            }}
                        />
                        <NumberInput
                            placeholder={"Lignes après"}
                            step={1}
                            value={lignes_apres}
                            w={"7rem"}
                            onChange={e => {
                                setLignes_apres(e)
                            }}
                        />
                    </>
                )}

                <Loading_button is_loading={loading} onClick={load} className={"big"} style={{margin: "0"}}>Charger</Loading_button>
            </div>

            {!donnees ? (
                <>
                    {loading && (
                        <Loading_div />
                    )}
                </>

            ) : (
                <>
                    <div className={"en-ligne"} style={{justifyContent: "center"}}>
                        <IconeInfo /><p>Les heures affichées sont UTC, actuellement {timezone.getApplyiedTimezoneOffset()/60} heures (10h ici c'est {10 + timezone.getTimezoneOffset()/60}h UTC)</p>
                    </div>
                    {activite == "activite_capteurs" && (
                        <div className={"en-ligne"} style={{justifyContent: "center"}}>
                            <IconeInfo /><p>[AA] = Actualisation normale, [AB] = Remontée automatique des mesures qui était hors ligne, [AC] = Contact sec, [AD] = Procédure de restoration déclenché par un admin.</p>
                        </div>
                    )}


                    <code style={{width: "unset"}}>
                        {donnees.output && donnees?.output?.map(p => <p>{"> " + p}</p>)}
                        {!donnees.output && "Fichier n'existe pas."}
                    </code>
                </>

            )}


        </>
    );
};