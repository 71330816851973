import {Auth} from "../types/auth";

export const loadAuthState = () => {
    try {
        const serializedState = localStorage.getItem('auth');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveAuthState = (state:Auth) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('auth', serializedState);
    } catch {
        // ignore write errors
    }
};
export const deleteAuthState = () => {
    try {
        localStorage.removeItem('auth');
    } catch {
        // ignore write errors
    }
};