import React, {CSSProperties, ReactNode, useEffect, useRef, useState} from 'react';
import './loading_button.scss'
import {Icone, IconeLoader} from "../icone/icone";

type Loading_button_props = {
    className?: string,
    icone?: string,
    is_loading: boolean,
    children: ReactNode,
    onClick: () => void,
    id?: string,
    disabled?: boolean
    style?:CSSProperties
};
export function Loading_button({ className, is_loading, children, onClick, icone, disabled, style, id }: Loading_button_props) {
    const [buttonWidth, setButtonWidth] = useState<number | null>(null);
    const [buttonHeight, setButtonHeight] = useState<number | null>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {

        if (buttonRef.current) {

            buttonRef.current.classList.remove('loading');
            // buttonRef.current.style.width = `max-content`;
            // buttonRef.current.style.height = `max-content`;
            if (buttonWidth === null) {

                const initialWidth = buttonRef.current.getBoundingClientRect().width;
                setButtonWidth(initialWidth);
                const initialHeight = buttonRef.current.getBoundingClientRect().height;
                setButtonHeight(initialHeight);



            } else if (is_loading) {
                buttonRef.current.style.width = `${buttonWidth}px`;
                buttonRef.current.style.height = `${buttonHeight}px`;
                buttonRef.current.style.padding = `0`;
                buttonRef.current.classList.add('loading');
            }
            else{
                buttonRef.current.style.removeProperty("width");
                buttonRef.current.style.removeProperty("height");
                buttonRef.current.style.removeProperty("padding");
            }
        }
    }, [is_loading, buttonWidth]);


    const handleClick = (e:any) => {
        // e.preventDefault();

        if (buttonRef.current) {
            const initialWidth = buttonRef.current.getBoundingClientRect().width;
            setButtonWidth(initialWidth);
            const initialHeight = buttonRef.current.getBoundingClientRect().height;
            setButtonHeight(initialHeight);
        }



        onClick();
    };

    return (
        <button id={id} ref={buttonRef} className={className} style={style} onClick={handleClick} disabled={is_loading || disabled} >
            {!is_loading && icone ? (<Icone nom={icone} />) : ""}
            {is_loading ? (<IconeLoader />) : ""}
            {is_loading ? "" : children}
            {/*{buttonHeight}*/}
        </button>
    );
}