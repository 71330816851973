import React from 'react';
import './bandeau_user.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {User} from "../../../types/user";
import {Menu} from "@mantine/core";
import {Link} from "react-router-dom";
import {IconeUser} from "../../icone/solid_react/user";
import {IconeCaretDown} from "../../icone/solid_react/caret-down";
import {IconeRightFromBracket} from "../../icone/solid_react/right-from-bracket";

export function Bandeau_user() {
    const user = useSelector<RootState, User>(state => state.user)

    const dispatch = useDispatch();

    return (
        <div className={"bandeau_user"}>
            Bonjour,
            <Menu position="bottom-end">
                <Menu.Target>
                    <button className={"sec"}><IconeUser/> {user.nom_affichage} <IconeCaretDown/></button>
                </Menu.Target>

                <Menu.Dropdown>
                    {/*<Menu.Label>Application</Menu.Label>*/}
                    <Menu.Item leftSection={<IconeUser/>}><Link to="/mon-compte">Mon compte</Link></Menu.Item>
                    <Menu.Item color="red" leftSection={<IconeRightFromBracket/>}>
                        <Link to="/logout">Déconnexion</Link>
                    </Menu.Item>

                    {/*<Menu.Divider />*/}

                    {/*<Menu.Label>Danger zone</Menu.Label>*/}
                    {/*<Menu.Item icon={<Icone nom={"gear"} />}>Transfer my data</Menu.Item>*/}
                </Menu.Dropdown>
            </Menu>

        </div>
    );
}

