import {Cellule} from "../../../../types/cellule";
import React, {useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../../services/AxiosInterceptor";
import {Modal, TextInput} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeTag} from "../../../../components/icone/solid_react/tag";
import {IconeReceipt} from "../../../../components/icone/solid_react/receipt";
import {Codes_promo} from "../../../../types/codes_promo";
import {afficherDate, DateTZ} from "../../../../services/GestionDates";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";

export function Modale_appliquer_code_promo({
                                                cellule,
                                                triggerRefresh
                                            }: { cellule: Cellule, triggerRefresh: () => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);
    const [code_promo_input, set_code_promo_input] = useState("");

    const [erreur, setErreur] = useState("");

    const [code_promo, setCode_promo] = useState<Codes_promo|null>(null);

    // if (forcer) handlers.open();

    function handleVerifier() {
        setErreur("")
        set_save_btn_loading(true);
        ajax.get("/cellules/" + cellule.id + "/codes_promo/" + code_promo_input).then(response => {
            setCode_promo(response.data.code_promo)

            set_save_btn_loading(false);
        }).catch(error => {
            setErreur(error.response?.data.erreur ? error.response.data.erreur : 'Une erreur est survenue.')
            set_save_btn_loading(false);
            setCode_promo(null)
        })

    }

    function handleEnregistrer() {
        setErreur("")
        set_save_btn_loading(true);
        ajax.post("/cellules/" + cellule.id + "/codes_promo/" + code_promo_input).then(response => {
            triggerRefresh();
            handlers.close();
            set_save_btn_loading(false);
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                title: "Code promotionnel appliqué !",
                message: "" ,
                color: 'green',
                loading: false,
                closeButtonProps: { 'aria-label': 'Hide notification' }
            });
        }).catch(error => {
            setErreur(error.response?.data.erreur ? error.response.data.erreur : 'Une erreur est survenue.')
            set_save_btn_loading(false);
            setCode_promo(null)
        })

    }

    function calc_date_fin(duree:number)
    {
        let date = new DateTZ();
        date.setDate(date.getDate() + duree)
        return afficherDate(date);
    }

    return (
        <>
            <button className={"sec"} onClick={handlers.open}><IconeReceipt/> Appliquer un code promotionnel</button>
            <Modal opened={opened} onClose={handlers.close} title="Appliquer un code promotionnel">
                <div className={"form"}>
                    <TextInput type="text"
                               value={code_promo_input}
                               onChange={event => set_code_promo_input(event.target.value)}
                               placeholder={"Code promo"}/>
                    <Loading_button is_loading={save_btn_loading && !code_promo}
                                    onClick={handleVerifier}>Vérifier la disponibilité</Loading_button>

                    {erreur !== "" && <p className={"notice red"}>{erreur}</p>}

                    {code_promo && (
                        <>
                            <p>Code promotionnel disponible !</p>
                            <p>L'abonnement suivant sera appliqué à votre cellule, sans frais : {code_promo.statut_cellule.nom}</p>
                            <p>L'abonnement sera appliqué de aujourd'hui jusqu'au {calc_date_fin(code_promo.duree_statut)}</p>

                            {(code_promo.usage_unique || code_promo.un_par_orga) &&
                                <p>Ce code promotionnel est à usage unique.</p>
                            }

                            <Loading_button is_loading={save_btn_loading}
                                            onClick={handleEnregistrer}><IconeCheck /> Appliquer ce code</Loading_button>
                        </>
                    )}
                </div>

            </Modal>
        </>

    )
}