import React, {useEffect, useState} from "react";

import {Tableau} from "../../../../components/tableau/Tableau";
import ajax from "../../../../services/AxiosInterceptor";
import {
    ColumnDef,
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {afficherDateTime, DateTZ} from "../../../../services/GestionDates";
import {Accordion, Autocomplete, Modal, NativeSelect, Select, Switch, TextInput, Tooltip} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom";
import {Hub} from "../../../../types/hub";
import {Organisation} from "../../../../types/organisation";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {notifications} from "@mantine/notifications";
import {Notifications} from "../../../../types/notifications";
import env_config from "../../../../env_config";
import {
    texte_date_compare_tableau,
    texte_est_inclus,
    texte_simple_compare_tableau
} from "../../../../services/GestionTextes";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {AxiosResponse} from "axios";
import {Cellule, Cellule_light_with_organisation} from "../../../../types/cellule";
import {Historique_statut} from "../../../../types/historique_statut";
import {Statut_cellule} from "../../../../types/statut_cellule";
import {useDisclosure} from "@mantine/hooks";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeEuroSign} from "../../../../components/icone/solid_react/euro-sign";

export function Historique_statut_modale({cellule}:{cellule: Cellule}) {

    const [donnees, setDonnees] = useState<Historique_statut[] | null>(() => null);

    const [status, set_statuts] = useState<Statut_cellule[] | null>(null);
    const [columnVisibility, setColumnVisibility] = React.useState({})

    const navigate = useNavigate();

    const columnHelper = createColumnHelper<Historique_statut>()

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const [opened, { open, close }] = useDisclosure(false);

    const Historique_statut_Tableau_admin:typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {key: "statut",
            edit_custom_input: (value:Statut_cellule, onchange:(value:Statut_cellule) => void) => (
                <Select
                    data={status ? [
                        {
                            group: 'Affichable à l\'achat',
                            items: status.filter(s => s.is_affichable_achat).map(s => s.nom + " ("+s.prix_achat+"€/mois)")
                        },
                        {
                            group: 'Admin seulement',
                            items: status.filter(s => !s.is_affichable_achat).map(s => s.nom + " ("+s.prix_achat+"€/mois)")
                        },
                    ] : []}
                    label={"Statut"}
                    // @ts-ignore
                    value={value.nom + " ("+value.prix_achat+"€/mois)"}
                    onChange={e => {
                        onchange((status && status.find(s => s.nom + " ("+s.prix_achat+"€/mois)" === e)) ?? value)
                    }}
                    style={{marginTop: '0.5rem'}}
                />
            ),
            spanClassname: "no-wrap flex_align_center",
            wrapper: (texte:Statut_cellule) => <Tooltip label={texte.prix_achat + "€/mois"}><p className={"inline-tag"}>{texte.nom}</p></Tooltip>
        },
        {key: "debut", type: "date"},
        {key: "fin", type: "date"},
        {key: "est_actuel", type:"boolean",
            desc: "Si coché cet abonnement sera l'historique actif de la cellule. Ce sera cet historique qui sera avancé les prochaines périodes. " +
                "Si un autre historique est actuellement actif, il sera modifié pour laisser sa place à celui-ci."},
        {key: "paiement_valide", type:"boolean",
            desc: "Si coché cet abonnement NE sera PAS traité par l'algorithme des prélevements."},
        {key: "est_promo", type:"boolean",
            desc: "Si coché, cet abonnement sera considérer comme promotionnel : un email sera envoyé pour indiqué son arret"},
        {key: "paye_jusqua", type: "date", desc: "Indique la date jusqu'a laquelle le paiement à été fait. " +
                "C'est soit la même date que le début (abonnement pas payé), soit la même date que la fin (abonnement déjà payé)." +
                "Si cette date est autre, alors un remboursement ou un rattrapage sera calculé au prochain prélèvement " +
                "(sauf si \"paiement_valide\" est coché)"},
        {key: "engage_jusqua", type: "date", desc: "Indique la date jusqu'a laquelle la cellule ne peut pas être configurée avec un abonnement moins cher." +
            "Mettre aujourd'hui ou plus vieux pour désactiver."},
        {action_editer: true, cacherDansTableSi: (row) => (row.lock_admin)},
    ]
    function handleEnregistrerStatut(statut:Historique_statut|null, setIsLoading:(isLoading:boolean) => void) {

        if (!statut)
        {
            alert( "Erreur, abonnement est : " + JSON.stringify(statut))
            return;
        }
        setIsLoading(true);
        let ajax_response = (response:AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'L\'abonnement est enregistré',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setIsLoading(false);

            if (statut.id < 0){
                let new_statut:Historique_statut = response.data.historiquestatut;
                if (donnees) setDonnees([...donnees, new_statut])
            }else{
                if (donnees) setDonnees(donnees?.map(donnee => {
                    if (donnee.id === statut.id){
                        return statut
                    }
                    return donnee;
                }))
            }
        }
        if (statut?.id < 0)
        {
            ajax.post("/cellules/"+cellule.id+"/historiquestatuts", statut).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        } else{
            ajax.put("/historiquestatuts/" + statut.id, statut).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        }

    }

    useEffect(() => {
        if (opened)
        {
            ajax.get("/cellules/" + cellule.id + "/historiquestatuts")
                .then(response => {
                    setDonnees(response.data.historiquestatuts.reverse())
                    if (!cellule.extension?.capteur.uuid) return;
                    ajax.get('/cellule/'+cellule.id+'/statutscellule/' + cellule.extension.capteur.uuid)
                        .then(response => {
                            let statuts_dispo:Statut_cellule[] = response.data.statutcellules;
                            set_statuts(statuts_dispo)
                        }).catch(error => {
                        console.log(error);
                    });
                }).catch(e => {})
        }
    }, [opened])


    const formatNew:Historique_statut = {
        id : -1,
        cellule_id: cellule.id,
        statut: cellule.statut_actuel ? cellule.statut_actuel.statut : ((status && status[0]) ?? {
            can_use_alertes: false,
            can_use_fonctions_avancees: false,
            can_use_graphiques: false,
            can_superposer_cellules: false,
            cellules: [],
            duree_archivage: 0,
            duree_statut_si_perime: 0,
            duree_engagement: 0,
            id: 0,
            is_affichable_achat: false,
            is_degrade: false,
            lock_admin: false,
            nom: "",
            note_admin: "",
            only_derniere_mesure: false,
            slug: "",
            prix_achat: "" ,
            statut_si_perime_id: 0,
            offre_abonnement: null,
            offre_abonnement_id: null,
            categorie_abonnement: "",
            unavailable_because_engagement: false,
            organisations_a_afficher: null
        }),
        debut: new DateTZ().toISOString(),
        fin: new DateTZ().toISOString(),
        est_actuel: false,
        est_promo: false,
        paiement_valide: false,
        paye_jusqua: new DateTZ().toISOString(),
        engage_jusqua: new DateTZ().toISOString()
    }


    return (
        <>
            <button className={"sec"} onClick={open}><IconeEuroSign /> Historique abonnements</button>
            <Modal opened={opened} onClose={close} title="Historiques des abonnements de la cellule">
                <div className={"en-tete-pages"}>

                    <Tableau_helper
                        typeDonnee={Historique_statut_Tableau_admin}
                        donnees={donnees}
                        handleEnregistrer={handleEnregistrerStatut}
                        formatNew={formatNew}
                    />

                </div>
            </Modal>
        </>



    );
}
