import React, { Component, ErrorInfo, ReactNode } from 'react';
import {Code} from "@mantine/core";

interface ErrorBoundaryProps {
	children: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
	errorMessage: string
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false, errorMessage: '' };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(error, errorInfo);
		this.setState({ hasError: true, errorMessage: error.toString() });
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, errorMessage: error.toString() };
	}

	render() {
		if (this.state.hasError) {
			// Handle the error here, e.g., display a custom message or redirect
			return (
					<div className={"page-erreur"}>
						<h1>Oups...</h1>
						<h3>Une erreur à eu lieu. </h3>
						<br />
						<br />
						<span className={"en-ligne"}>
							<p>Vous pouvez cliquer sur le bouton suivant pour ré-initialiser l'app :</p>
							<a href={"/logout"} className={"btn big"} style={{width: "fit-content", margin: "0"}}>Déconnexion</a>
						</span>

						<br />
						<br />
						<p>Détail de l'erreur : </p>
						<Code>{this.state.errorMessage}</Code>
					</div>
				)

		}

		return this.props.children;
	}
}

export default ErrorBoundary;
