import React, {useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Modal, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {UserLight} from "../../../types/user";
import {useNavigate} from "react-router-dom";
import {IconeUserSlash} from "../../../components/icone/solid_react/user-slash";

export function Modale_supprimer_compte({
                                                user
                                            }: { user: UserLight }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);
    const [confirmation, set_confirmation] = useState("");


    const navigate = useNavigate();
    function handleSupprimerSonCompte() {

        if (window.confirm("Vous êtes sur le point de supprimer votre compte utilisateur. \n" +
            "Vous ne pourrez plus utiliser la plateforme Freshtime et toutes vos informations seront supprimée.\n" +
            "Etes vous sûr de votre choix ?")) {
            set_save_btn_loading(true)
            ajax.delete("/users/" + user.id).then(response => {
                set_save_btn_loading(false)
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Compte supprimé",
                    message: 'Votre compte à bien été supprimé. Merci d\'avoir utilisé Freshtime.',
                    icon: <IconeCheck/>,
                });
                navigate("/logout");
                handlers.close();
                set_save_btn_loading(false);

            }).catch(error => {
                handlers.close();
                set_save_btn_loading(false);
            });
        }

    }


    return (
        <>
            <button className={"sec rouge"} onClick={handlers.open}><IconeUserSlash/> Supprimer mon compte Freshtime</button>
            <Modal opened={opened} onClose={handlers.close} title="Supprimer mon compte Freshtime">
                <div className={"form"}>
                    <p>Vous êtes sur le point de supprimer votre compte utilisateur. Ceci est une action irreversible.</p>
                    <p> Vous ne pourrez plus utiliser la plateforme Freshtime et toutes vos informations seront supprimée définitivement.</p>
                    <p>Pour confirmer votre volonté de supprimer votre compte, merci de recopier la phrase "<i>Je veux supprimer mon compte</i>" ci dessous : </p>
                    <TextInput type="text"
                               value={confirmation}
                               onChange={event => set_confirmation(event.target.value)}
                    />
                    <Loading_button is_loading={save_btn_loading} disabled={confirmation.toLowerCase().trim() !== "je veux supprimer mon compte"}
                                    onClick={handleSupprimerSonCompte} className={"sec rouge"}>
                        <IconeUserSlash/> Supprimer mon compte Freshtime
                    </Loading_button>

                </div>

            </Modal>
        </>

    )
}