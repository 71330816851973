import React, {useEffect, useState} from "react";

import './Utilisateurs_liste.scss'
import {Tableau} from "../../../../components/tableau/Tableau";
import ajax from "../../../../services/AxiosInterceptor";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {afficherDateTime} from "../../../../services/GestionDates";
import {
    Accordion,
    Autocomplete,
    Checkbox,
    Modal,
    NativeSelect,
    Select,
    Textarea,
    TextInput,
    Tooltip
} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom";
import {Hub} from "../../../../types/hub";
import {Organisation, Organisation_light} from "../../../../types/organisation";
import {User} from "../../../../types/user";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {texte_est_inclus, texte_simple_compare_tableau} from "../../../../services/GestionTextes";
import {getAffichageNom, getAffichageNomShort} from "../../../../services/UserService";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeClock} from "../../../../components/icone/solid_react/clock";
import {IconeTrashCan} from "../../../../components/icone/solid_react/trash-can";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {IconePenToSquare} from "../../../../components/icone/solid_react/pen-to-square";
import {useDisclosure} from "@mantine/hooks";
import {IconeMailbox} from "../../../../components/icone/solid_react/mailbox";
import {IconeEnvelope} from "../../../../components/icone/solid_react/envelope";
import {IconeTurnDownLeft} from "../../../../components/icone/solid_react/turn-down-left";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";


export function Utilisateurs_liste() {

    const [donnees, setDonnees] = useState<User[] | null>(() => null);

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState({})


    const [btn_loading, setBtn_loading] = useState(false);

    const columnHelper = createColumnHelper<User>()

    const [modaleLoadingData, setModaleLoadingData] = useState(false);


    const [modale_cellules_recherche, setModale_cellules_recherche] = React.useState<string>("")
    const [cellules_modale, setCellules_modale] = React.useState<Organisation[] | null>(null)


    function handleSupprimerUser(user: User) {
        if (window.confirm("Vous êtes sur le point de supprimer l'utilisateur " + getAffichageNom(user) + "(" + user.email + ")")) {
            setBtn_loading(true)
            ajax.delete("/users/" + user.id).then(response => {
                setBtn_loading(false)
                if (donnees) setDonnees(donnees?.filter(d => d.id !== user.id))
            }).catch(error => {
                setBtn_loading(false)
            });
        }
    }

    function loadAlertesDeUnUser(user_id: number) {
        setModaleLoadingData(true)
        ajax.get("/utilisateurs/" + user_id + "/alertes").then(response => {
            setCellules_modale(response.data.alertes);
            setModaleLoadingData(false)
        }).catch(error => {

        })

        // setCellules_modale("texte")
    }

    const [titre, setTitre] = useState("");
    const [sujet, setSujet] = useState("");
    const [contenu, setContenu] = useState("");
    const [bouton_texte, setBouton_texte] = useState("Plateforme en ligne Freshtime");
    const [bouton_lien, setBouton_lien] = useState("https://app.freshtime.fr");

    function envoyerEmail() {
        setBtn_loading(true)
        ajax.post("/app/envoi_emails", {
            utilisateurs: table.getSelectedRowModel().rows.map(u => u.original.id),
            contenu: contenu.replace(/\n/g, "<br />"),
            sujet: sujet,
            titre: titre,
            bouton_texte: bouton_texte,
            bouton_lien: bouton_lien,
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Emails envoyés!",
                message: '',
                icon: <IconeCheck/>,
            });

            setBtn_loading(false)
        }).catch(err => {
            setBtn_loading(false)
        })
    }

    const [modal_editer_categorie_abonnement_openned, handlers_modal_editer_categorie_abonnement] = useDisclosure(false);

    const columns = [
        columnHelper.accessor(row => row, {
            id: 'Selection',
            header: ({table}) => (
                <span className={"aligne_left"}>
                    {'\u00A0'}
                    <Checkbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </span>

            ),
            cell: ({row}) => (
                <div className="px-1">
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),

            enableColumnFilter: false,
            enableSorting: false
        }),
        columnHelper.accessor(row => row.id, {
            id: 'ID',
            header: () => 'ID',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>ID : </strong>
                        {info.getValue()}
                </span>
                )
        }),
        columnHelper.accessor('statut', {
            id: 'Statut',
            header: () => '',
            cell: info => {
                let icone = <p>{info.getValue()}</p>;
                if (info.getValue() === "Actif") icone = <IconeCheck/>
                if (info.getValue() === "Non validé") icone = <IconeClock/>
                return (<>
                        <strong className={"th_mobile"}>Statut : </strong>
                        <Tooltip label={"Statut : " + info.getValue()}>
                            <p>{icone}</p>
                        </Tooltip>

                    </>
                )
            },
            enableColumnFilter: false,
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.email, {
            id: 'Email',
            header: () => 'Email',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Email : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.roles, {
            id: 'Roles',
            header: () => 'Roles',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Roles : </strong>
                        {info.getValue().filter(r => r !== "ROLE_USER").map(r => <span className={"inline-tag"}>{r}</span>)}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.nom_affichage, {
            id: "Prenom",
            header: () => "Prénom",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Prénom : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.nom_famille, {
            id: "Nom",
            header: () => "Nom",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Nom : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.tel, {
            id: 'Tél',
            header: () => 'Tél',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Tél : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => JSON.stringify(row.organisations), {
            id: 'Orga',
            header: () => 'Organisations',
            cell: info =>
                info.getValue() != null && (
                    <>
                        <strong className={"th_mobile"}>Organisations : </strong>
                        {JSON.parse(info.getValue())?.map((o:Organisation_light) => <p className={"inline-tag"}>{o?.nom}</p>)}
                    </>

                ),
        }),
        columnHelper.accessor(row => row.timezone, {
            id: 'fuseau_horaire',
            header: () => 'Fuseau horaire',
            cell: info =>
                info.getValue() != null ? (
                    <span>
                    <strong className={"th_mobile"}>Fuseau horaire : </strong>
                        {info.getValue()}
                </span>
                ) : (
                    <span>
                    <strong className={"th_mobile"}>Fuseau horaire : </strong>
                        Automatique
                </span>
                ),
        }),
        columnHelper.accessor(row => row, {
            id: 'Alertes',
            header: () => 'Alertes',
            cell: props => props.getValue() ? (
                <Loading_button
                    is_loading={btn_loading}
                    onClick={() => loadAlertesDeUnUser(props.getValue().id)}
                ><IconeEye/> Alertes</Loading_button>
            ) : (<></>),
            enableColumnFilter: false
        }),
        columnHelper.accessor(row => row, {
            id: 'Ajouter l\'enregistreur à l\'organisation selectionnée',
            header: () => 'Supprimer',
            cell: props => props.getValue() ? (
                <Loading_button
                    is_loading={btn_loading}
                    onClick={() => handleSupprimerUser(props.getValue())}
                ><IconeTrashCan/> Supprimer</Loading_button>
            ) : (<></>),
            enableColumnFilter: false
        }),
    ]


    useEffect(() => {
        ajax.get("/users").then(response => setDonnees(response.data.users)).catch(e => {
        })
    }, [])

    const table = useReactTable<User>({
        data: donnees ? donnees : [],
        columns: columns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })

    return (
        <div className={"en-tete-pages"}>

            <div className={"en-ligne en-tete-page"}>

                <div className={"titre"}>
                    <h1>Tous les utilisateurs de la plateforme</h1>
                </div>
                <div className={"flex-row"}>
                    <button disabled={table.getSelectedRowModel().rows.length === 0}
                            onClick={handlers_modal_editer_categorie_abonnement.toggle}>
                        <IconeEnvelope/>Envoyer un email
                    </button>

                    <div>{table.getRowModel().rows.length} lignes affichés</div>
                    <NativeSelect
                        className={"select_nbe_lignes match_height"}
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                        data={["5", "10", "20", "50", "100"]}
                    />
                </div>

            </div>

            <Tableau table={table} donnees={donnees}/>

            <Modal opened={cellules_modale !== null} onClose={() => {
                setCellules_modale(null)
            }} title="Alertes de l'utilisateur">
                <TextInput
                    placeholder={"Recherche organisation..."}
                    value={modale_cellules_recherche}
                    onChange={e => setModale_cellules_recherche(e.target.value)}
                />
                {(() => {
                    if (cellules_modale) {

                        return (
                            // eslint-disable-next-line react/jsx-no-undef
                            <Accordion chevronPosition="left" defaultValue="customization">
                                {cellules_modale.filter(o => texte_est_inclus(o.nom, modale_cellules_recherche)).map(orga => (
                                    <Accordion.Item value={orga.nom}>
                                        <Accordion.Control>{orga.nom}</Accordion.Control>
                                        <Accordion.Panel className={"accordion-flex-row"}>
                                            {orga.zones && orga.zones.map(zone =>
                                                (

                                                    <Accordion chevronPosition="left" defaultValue="customization">
                                                        <Accordion.Item
                                                            value={zone.nom === "base.config.appareils" ? "Appareils" : zone.nom}>
                                                            <Accordion.Control>{zone.nom === "base.config.appareils" ? "Appareils" : zone.nom}</Accordion.Control>
                                                            <Accordion.Panel className={"accordion-flex-row"}>
                                                                {zone.cellules && zone.cellules.map(cellule =>
                                                                    (
                                                                        <>
                                                                            {/*<p style={{width: "100%"}}>{cellule.nom}</p>*/}
                                                                            {cellule.conditions && cellule.conditions.map((condition: any) => { // :any => :Alerte avec :Condition dedans
                                                                                return (
                                                                                    <>
                                                                                        <Link
                                                                                            to={"/cellule/" + cellule.id}
                                                                                            className={"inline-tag"}>{cellule.nom} : {condition.condition.nom} {"->"} [{condition.canaux.toString()}]</Link>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </>


                                                                    ))
                                                                }
                                                            </Accordion.Panel>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                ))
                                            }
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )

                    }
                })()}
            </Modal>


            {donnees && (
                <Modal opened={modal_editer_categorie_abonnement_openned}
                       onClose={handlers_modal_editer_categorie_abonnement.close}
                       title="Envoyer un email aux utilisateurs">

                    <p className={"en-ligne"}>
                        {table.getSelectedRowModel().rows.length} utilisateurs sélectionnés
                        : {table.getSelectedRowModel().rows.map((r, index) => {
                        if (index > 5) return (<></>)
                        return (<span className={"inline-tag"}>{getAffichageNomShort(r.original)}</span>)
                    })}
                        {table.getSelectedRowModel().rows.length > 5 && <span
                            className={"inline-tag"}>... ({table.getSelectedRowModel().rows.length - 6} autres)</span>}
                    </p>

                    <TextInput
                        label="Sujet"
                        description={"Sujet de l'email"}
                        value={sujet}
                        onChange={e => setSujet(e.target.value)}
                    />
                    <TextInput
                        label="Titre"
                        description={"Titre présent dans l'email"}
                        value={titre}
                        onChange={e => setTitre(e.target.value)}
                    />
                    <Textarea
                        label="Contenu de l'email"
                        description={"Contenu de l'email en HTML"}
                        value={contenu}
                        onChange={e => setContenu(e.target.value)}
                        autosize
                    />
                    <div className={"en-ligne"}>
                        {/*<a href="#" onClick={() => setContenu(contenu + "<br />")} ><IconeTurnDownLeft /></a>*/}
                        {/*<p>•</p>*/}
                        <a href="#" onClick={() => setContenu(contenu + "<strong></strong>")} >Gras</a>
                        <p>•</p>
                        <a href="#" onClick={() => setContenu(contenu + "<i></i>")} >Italique</a>
                    </div>
                    <TextInput
                        label="Texte du bouton"
                        value={bouton_texte}
                        onChange={e => setBouton_texte(e.target.value)}
                    />
                    <TextInput
                        label="Lien du bouton"
                        description={"Adresse URL sur laquelle le bouton envoi"}
                        value={bouton_lien}
                        onChange={e => setBouton_lien(e.target.value)}
                    />

                    <p>&nbsp;</p>
                    <button onClick={envoyerEmail}>
                        Valider
                    </button>

                </Modal>
            )}

        </div>

    );
}

