import {Cellule} from "../../../../types/cellule";
import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../../services/AxiosInterceptor";
import {Accordion, Box, Modal, Checkbox, NumberInput, Select, Textarea, Group, Tooltip} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {User} from "../../../../types/user";
import {Condition} from "../../../../types/condition";
import {Alerte} from "../../../../types/alerte";
import {Modale_alertes_users_cellules} from "../../../../components/Modales/modale_alertes_users/modale_alertes_users_cellules";
import {IconeBell} from "../../../../components/icone/solid_react/bell";
import {IconeMessageSms} from "../../../../components/icone/solid_react/message-sms";
import {IconeAt} from "../../../../components/icone/solid_react/at";
import {IconePhoneVolume} from "../../../../components/icone/solid_react/phone-volume";
import {useDispatch} from "react-redux";
import {setPasApasEtat} from "../../../../store/pasApasSlice";
import {IconeCircleInfo} from "../../../../components/icone/solid_react/circle-info";
import {IconeInfo} from "../../../../components/icone/solid_react/info";

export function Modale_alertes({
                                   cellule,
                                   setCellule, user, forcer, setIsModalOpen
                               }: { cellule: Cellule, setCellule: React.Dispatch<Cellule | null> , user:User, forcer: boolean, setIsModalOpen: (open:boolean) => void}) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);

    const [hide_modale, set_hide_modale] = useState(false);

    const [conditions, set_conditions] = useState<Condition[]>([]);

    useEffect(() => {
        let default_conditions:Condition[] = JSON.parse(JSON.stringify(cellule.conditions));
        let compteur = -1;
        default_conditions.map(condition => {
            let alerte_pour_user_existe = false;
            condition.alertes.map(alerte => {
                if (alerte.utilisateur.id === user.id) {
                    alerte_pour_user_existe = true
                }
            })
            if (!alerte_pour_user_existe) {
                condition.alertes.push({
                    canaux: [],
                    condition_id: condition.id,
                    id: compteur,
                    texte_a_envoyer: condition.texte_a_afficher,
                    utilisateur: user
                })
                compteur--;
            }
        })
        set_conditions(default_conditions)
    }, [cellule])

    useEffect(() => {
        if (forcer)
        {
            handlers.open();
            setIsModalOpen(true)
        }
    }, [forcer]);

    const dispatch = useDispatch()

    useEffect(() => {
        if (opened) dispatch(setPasApasEtat("modale_alerte_user_cellule_opened"))
    }, [opened]);

    function handleEnregistrer() {
        let alertes:Alerte[] = [];
        conditions.map(condition => condition.alertes.map(alerte => alertes.push(alerte)))
        // console.log(alertes)
        set_save_btn_loading(true);
        ajax.put("/cellules/" + cellule.id + "/alertes", alertes).then(response => {
            let maj_cellule = {...cellule};
            maj_cellule.conditions = conditions
            setCellule(maj_cellule)
            handlers.close();
            setIsModalOpen(false)
            set_save_btn_loading(false);
        }).catch(error => {
            set_save_btn_loading(false);
        })

    }

    function isAnalyseCondenseur(condition: Condition)
    {
        return (["Condenseur chaud", "Température critique condenseur"].includes(condition.nom))
    }

    function getDefault()
    {
        if (conditions.length > 0)
        {
            let anomalie_analyse_condenseur = conditions.find(c => c.nom === "Condenseur chaud");
            if (anomalie_analyse_condenseur) return anomalie_analyse_condenseur.id + "";
            return conditions[0].id + ""
        }
        return null
    }

    function majCelluleDepuisModaleEditEnMasse(celluleEnregistree:Cellule, shouldClose:boolean)
    {
        if (celluleEnregistree.id === cellule.id)
        {
            setCellule(celluleEnregistree);
        }

        if (shouldClose) {
            handlers.close();
            setIsModalOpen(false)
        }
    }

    return (
        <>
            {conditions.length > 0 ? (
                <button className={"sec"} onClick={() => {handlers.open();setIsModalOpen(true)}}><IconeBell/>Alertes</button>
            ) : (
                <Tooltip label="Vous devez indiquer des anomalies." position="bottom" >
                    <div>
                        <button className={"sec"} disabled><IconeBell/>Alertes</button>
                    </div>

                </Tooltip>

            )}



            <Modal opened={opened} onClose={() => {handlers.close();setIsModalOpen(false)}} title="Configurer vos alertes"
                   className={hide_modale ? "modal_hide" : ""}>


                <Accordion defaultValue={getDefault()}>

                    <div className={"form"}>
                        {conditions.map(condition => {
                            let titre = "";
                            if (condition.slug !== null && cellule.extension?.sonde) {
                                if (condition.slug === 0) titre = titre + " - inférieur à " + condition.valeur_a_comparer + cellule.extension.sonde.affichage_suffix + " pendant " + condition.delais + "min";
                                if (condition.slug === 1) titre = titre + " - supérieur à " + condition.valeur_a_comparer + cellule.extension.sonde.affichage_suffix + " pendant " + condition.delais + "min";
                                if (condition.slug === 2) titre = titre + " - etat Anormal";
                                if (condition.slug === 3) titre = titre + " - etat Normal";
                                if (condition.slug === 11) titre = " (- de " + condition.valeur_a_comparer + "%)";
                            }

                            let alerte: string[] = [];
                            condition.alertes.map(_alerte => {
                                if (_alerte.utilisateur.id === user.id) {
                                    alerte = _alerte.canaux;
                                }
                            })

                            return (

                                <Accordion.Item value={"" + condition.id} key={condition.id}>
                                    <Box style={{display: 'flex', alignItems: 'center'}}>
                                        <Accordion.Control>
                                            <div className={"en-ligne no-wrap"}>
                                                {isAnalyseCondenseur(condition) && (
                                                    <p className={"inline-tag red en-ligne no-wrap wrap-text"}><IconeInfo/></p>
                                                )}
                                                {condition.nom}
                                                <i style={{fontWeight: "lighter", height: "unset"}}>
                                                    {titre}
                                                </i>
                                                <div style={{color: "var(--main-light-color)"}} className={"en-ligne no-wrap"}>
                                                    {alerte.includes('SMS') && <IconeMessageSms />}
                                                    {alerte.includes('EMAIL') && <IconeAt />}
                                                    {alerte.includes('APPEL') && <IconePhoneVolume />}
                                                    {alerte.includes('PUSH') && <IconeBell />}
                                                </div>

                                            </div>
                                        </Accordion.Control>
                                    </Box>
                                    <Accordion.Panel>

                                        {isAnalyseCondenseur(condition) && (
                                            <p className={"inline-tag red en-ligne no-wrap wrap-text"}>
                                                <IconeCircleInfo/> Important : nous vous conseillons d'indiquez vos canaux d'alertes pour les deux anomalies de surchauffe condenseur pour être alerté au plus vite.
                                                {condition.nom === "Condenseur chaud" && " (1/2)"}
                                                {condition.nom === "Température critique condenseur" && " (2/2)"}
                                            </p>
                                        )}
                                            <Checkbox.Group
                                                value={alerte}
                                                label={"Sur quels canaux voulez vous recevoir une alerte ?"}
                                                onChange={value => {
                                                    set_conditions(conditions.map(_condition => {
                                                        if (_condition.id === condition.id) {
                                                            _condition.alertes = _condition.alertes.map(alerte => {
                                                                if (alerte.utilisateur.id === user.id) {
                                                                    alerte.canaux = value
                                                                }
                                                                return alerte
                                                            })
                                                        }
                                                        return _condition
                                                    }))
                                                }}


                                            >
                                                <Group mt="xs">
                                                    <Checkbox value="SMS" label="SMS"/>
                                                    <Checkbox value="PUSH" label="Push"/>
                                                    <Checkbox value="APPEL" label="Appel"/>
                                                    <Checkbox value="EMAIL" label="Email"/>
                                                </Group>
                                            </Checkbox.Group>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            )
                        })}

                        <div className={"en-ligne"}>
                            <Modale_alertes_users_cellules organisation_id={cellule.organisation.id} cellule_id={cellule.id} parent_set_hide={set_hide_modale} parent_edit_cellule={majCelluleDepuisModaleEditEnMasse} btn_sec={true} />
                            <Loading_button is_loading={save_btn_loading}
                                            onClick={handleEnregistrer}>Enregistrer</Loading_button>
                        </div>

                    </div>
                </Accordion>

            </Modal>
        </>

    )
}