import {Navbar} from "../components/navbar/navbar";
import {useDispatch} from "react-redux";
import {setMobile_sidebar_icon} from "../store/interfaceSlice";
import {useNavigate} from "react-router-dom";

export function Erreur_404() {

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))
    const navigate = useNavigate();



    return (
        <div className={"app"}>
            <Navbar />
            <div className={"content content-fullwidth flex-col-center"} style={{minHeight: "calc(100svh - 4rem)", gap: "1.5rem"}}>
                <h1 style={{fontSize: "4rem", textAlign: "center"}}>Erreur 404</h1>
                <p style={{textAlign: "center"}}>Cette page n'existe pas.</p>
                <button className={"noMargin"} onClick={() => {
                    navigate('/')
                }}>Revenir à l'acceuil</button>
            </div>
        </div>

    );
}
